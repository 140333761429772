.welcome {
  padding-top: 10px;
  padding-bottom: 10px;

  position: relative;
  text-align: center;

  &__title {
    font-size: 30px;
    font-family: $Roboto;
  }
  &__subtitle {
    font-size: 21px;
    margin-top: 20px;
  }
  &__man,
  &__money {
    display: none;
  }
  &__label {
    margin-top: 15px;
    margin-bottom: 15px;

    font-size: 15px;
    line-height: 22px;
  }
  &__dots {
    display: block;
    margin: 20px auto 10px;
  }
  &__button-enter,
  &__button-registr {
    width: 245px;
    background-repeat: no-repeat;
    background-position: 85% 50%;
  }
  &__button-enter {
    background-image: url('#{$image}/png/key.png');
    background-position: 90% 50%;
  }
  &__button-registr {
    background-image: url('#{$image}/png/user.png');
  }
}
@include min-screen($break-medium-devices){
  .welcome {
    padding-top: 48px;
    padding-bottom: 48px;

    &__title {
      font-size: 48px;
    }
    &__subtitle {
      font-size: 30px;
    }
    &__label {
      font-size: 17px;
      margin-top: 28px;
      margin-bottom: 28px;
    }
    &__dots {
      margin-top: 27px;
    }
  }
}
@include min-screen($break-large-devices){
  .welcome {
    padding-top: 68px;
    padding-bottom: 68px;

    &__man {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;

      width: 178px;
      height: 437px;

      display: block;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .welcome {
    &__man {
      left: 88px;
    }
    &__money {
      position: absolute;
      right: 25px;
      bottom: 0;
      z-index: 2;

      width: 351px;
      height: 237px;

      display: block;
    }
  }
}