// Font weight
$light:                                     300;
$regular:                                   400;
$medium:                                    500;
$bold:                                      700;
$black:                                     900;


// Font family
$Roboto:                                   "Roboto", sans-serif;
$Georgia:                                  "Georgia", serif;
$DINPro:                                   "DIN Pro", sans-serif;


$font-family:                               $Roboto;
$font-size:                                 14px;
$line-height:                               1.4285em;
$font-weight:                               $regular;
$font-style:                                normal;
$font-color:                                #3d4343;
$link-color:                                #468ee5;
$link-hover-color:                          #ff5722;
$major-color:                               #2184be;
$minor-color:                               #fcaf26;
$muted-color:                               #7f8183;


// State colors
$accept-color:                              #47a73e;
$error-color:                               #ff7f75;


// Default
$radius:                                    2px;
$border:                                    #f4f4f4;
$drop-shadow:                               transparentize($border, 0.7);
$trns-duration:                             300ms;
$trns-t-func:                               ease-in-out;
$transition:                                $trns-duration $trns-t-func;


// Path
$image:                                     "../images";
$fonts:                                     "../fonts";


// Text variant
$text-muted:                                #000;
$text-primary:                              #337ab7;
$text-success:                              #4ab947;
$text-info:                                 #31708f;
$text-warning:                              red;
$text-danger:                               #a94442;


// Z-index
$z-under:                                   5;                              // положение для кнопок закрытия
$z-badget:                                  6;                              // положение для кнопок закрытия
$z-tooltip:                                 6;                              // положение для подсказок;
$z-hint:                                    6;                              // положение для подсказок;
$z-hint-static:                             999;                              // положение для подсказок;
$z-sidebars:                                7;
$z-header:                                  20;                             // положение для шапки сайта;
$z-dropdown:                                50;                             // положение для выпадашек;
$z-popup:                                   100;


// Breakpoints
$break-small-devices:                      480px;                          // Small devices (landscape phones, 576px and up)
$break-medium-devices:                     768px;                          // Medium devices (tablets, 768px and up)
$break-large-devices:                      992px;                          // Large devices (desktops, 992px and up)
$break-extra-large-devices:                1200px;                         // Extra large devices (large desktops, 1200px and up)


/**/
$header-height:                             84px;
$header-height-tablet:                      104px;


/* Настройки чекбоксов */
$checkbox_icon-size:                        16px;
$checkbox_font:                             (12px, 1);


/* Настройки радиобоксов */
$radio_icon-size:                           10px;
$radio_font:                                (14px, 1);


/* Настройки календаря */
$calendar_input-height:                     20px;
$calendar_dropdown-radius:                  $radius;
$calendar_dropdown-drop:                    0 0 20px rgba(0,0,0,.2);
$calendar_dropdown-width:                   300px;
$calendar_dropdown-width-multi:             614px;
$calendar_weekend:                          #f76300;
$calendar_today:                            #f2f2f2;
$calendar_active:                           #ffd488;
$calendar_active-range:                     #ffe9c3;
$calendar_radius:                           4px;


/* Размеры */
$space-xxs:                                  5px;
$space-xs:                                   10px;
$space-sm:                                   15px;
$space-md:                                   30px;
$space-lg:                                   50px;


/* Настройки элементов формы */
$form-border:                               #dadada;
$form-radius:                               5px;
$form-border-focus:                         #9c9c9c;
$form-border-error:                         #ec4f76;
$form-font:                                 (14px, 1.25, $font-color, $regular);
$form-placeholder-text-color:               #dadada;
$form_backg:                                #fff;
$form_element-height:                       37px;

$form-input_height:                         $form_element-height;
$form-input_padding:                        4px 10px;

$form-textarea_height:                      100px;
$form-textarea_padding:                     10px 10px;

$form-help_font:                            (13px, 1.5, #fff);
$form-help_padding:                         9px 16px;
$form-help_backg:                           #e02a57;

// State colors
$accept-color:                              #73b746;
$error-color:                               #ff7f75;
$light-gray:                                #f2f2f2;
$light-green:                               #56b74e;
$white:                                     #ffffff;
$disabled:                                  #b7b5b5;

$select_tag-backg:                          #ffd488;
$select_tag-active-backg:                   #fcaf26;
$select_tag-black-backg:                    #000000;
$select_tag-blue-backg:                     #00afff;
$select_tag-green-backg:                    #7cb446;
$select_tag-orange-backg:                   #fcaf26;
$select_tag-dark-orange-backg:              #f36f36;
$select_tag-yellow-backg:                   #fff066;
$select_tag-red-backg:                      #ec5b15;
$select_tag-gray-backg:                     #d6d6d6;
$select_tag-yellow-light-backg:             #feee70;
$select_tag-error-backg:                    #ea5c26;
$select_tag-visit-backg:                    #7365e0;
$select_tag-online-request-backg:           #2fc193;
$select_tag-pdf-file:                       #f91728;
$select_tag-xls-file:                       #208e40;
$select_tag-docx-file:                      #4175db;
$select_tag-rar-file:                       #b067b6;
$select_tag-issued:                         #5cb94e;

/* Настройки селектов */
$select_height:                             $form_element-height;
$select_padding:                            9px 30px 9px 10px;
$select_font:                               (14px, 1.25, $font-color);
$select_active-brd:                         #9c9c9c;
$select_hover-option:                       $light-gray;
$select_tag-backg:                          #ffd488;
$select_tag-active-backg:                   #fcaf26;
$select_tag-black-backg:                    #000000;
$select_tag-blue-backg:                     #00afff;
$select_tag-green-backg:                    #7cb446;
$select_tag-orange-backg:                   #fcaf26;
$select_tag-yellow-backg:                   #fff066;
$select_tag-red-backg:                      #ec5b15;
$select_tag-gray-backg:                     #d6d6d6;
$select_tag-yellow-light-backg:             #feee70;
$select_tag-error-backg:                    #ea5c26;
$select_tag-muted-backg:                    $light-gray;
$select_tag-visit-backg:                    #7365e0;
$select_tag-online-request-backg:           #2fc193;
$select_tag-pdf-file:                       #f91728;
$select_tag-xls-file:                       #208e40;
$select_tag-docx-file:                      #4175db;
$select_tag-rar-file:                       #b067b6;
$select_tag-issued:                         #5cb94e;


// Кнопки
$button_radius:                             $radius;
$button_backg-loading:                      #fdd792;
$button_height:                             46px;

$button_backg_disabled_hover:               #f2f2f2;

$button-major_height:                       $button_height;
$button-major_font:                         (15px, $button_height - 8, #fff);
$button-major_padding:                      4px 32px;
$button-major_backg:                        #1ea8e0;
$button-major_back-hover:                   #2184be;
$button-major_backg-active:                 #2184be;
$button-major_backg-disabled:               $light-gray;

$button-minor_height:                       $button_height;
$button-minor_font:                         (15px, $button_height - 8, $font-color);
$button-minor_padding:                      4px 32px;
$button-minor_backg:                        #fff;
$button-minor_back-hover:                   $light-gray;
$button-minor_backg-active:                 #d7d7d7;
$button-minor_backg-disabled:               $light-gray;

$button-minor-group_height:                 40px;
$button-minor-group_font:                   (14px, 40px, $font-color);
$button-minor-group_padding:                4px 18px;
$button-minor-group_backg:                  #fff;
$button-minor-group_backg-active:           #ffd488;

/* Теги */
$tags_radius:                               4px;
$tags_padding:                              5px 10px;


/** Список операций */
$list_operation_main_padding_left:          60px;
$list_operation_main_color:                 #d4d4d4;
$list_operation_vertical_left:              28px;
$list_operation_width_line:                 20px;
$list_operation_height_line:                2px;
$list_operation_width_vertical_line:        2px;