.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  &__top {
    padding-top: 28px;
    padding-bottom: 12px;
    background: #f7f6f7;
    background: linear-gradient(to bottom, #f0f0f1 0%,#fefefe 100%);
  }
  &__top-text {
    margin-top: 13px;
    @include font(13px, 1.4, #000);
  }

  &__bottom {
    padding-top: 33px;
    padding-bottom: 20px;
    background: #e8f3e7;
    background: linear-gradient(to bottom, #e8f3e7 0%,#e8f4e8 100%);
  }
  &__bottom-text {
    margin-top: 20px;
    margin-bottom: 20px;
    @include font(12px, 1.5, null, null, italic);
  }

  &__menu {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  &__menu-item {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
  }
  &__menu-link {
    position: relative;
    @include font(13px, 1.6, #404241, $medium);

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 0;
      margin-left: -12px;

      display: inline-block;
      width: 3px;
      height: 3px;

      background-color: #3d4343;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &.is-active {
      color: $major-color;

      &::before {
        background-color: $major-color;;
      }
    }


  }
}
@include min-screen($break-medium-devices){
  .footer {
   
    &__bottom {
      padding-top: 27px;
      padding-bottom: 20px;
    }
    &__bottom-text {
      margin-top: 0;
    }

    &__logo {
      margin-top: 7px;
    }

    &__menu {
      margin-top: 13px;
      margin-bottom: 13px;
    }
    &__menu-item {
      padding-left: 16px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    &__menu-link {
      font-size: 14px;

      &::before {
        margin-left: -16px;
      }
    }
  }
}
@if($push-footer){
  @include min-screen($break-large-devices){
    .footer {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}