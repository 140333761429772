.content-request {
  margin-top: 90px;
  margin-bottom: 40px;
  text-align: center;

  &__icon {
    width: 40px;
  }
  &__body {
    margin-top: 125px;
    background: #f4f3f4;
    background: linear-gradient(to bottom, #f4f3f4 0%, #fff 100%);
  }

  .money-request {
    margin-top: -95px;
  }
}
@include min-screen($break-medium-devices){
  .content-request {
    margin-top: 70px;

    &__icon {
      width: 50px;
      margin-bottom: 5px;
    }
    &__body {
      margin-top: 160px;
    }

    .money-request {
      margin-top: -120px;
    }
  }
}
@include min-screen($break-large-devices){
  .content-request {
    margin-top: 95px;

    &__icon {
      width: 55px;
      margin-bottom: 1px;
    }
    &__footer {
      margin-top: 30px;
    }

    .money-request {
      margin-top: -126px;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .content-request {
    margin-top: -11px;
    overflow: hidden;

    &__icon {
      width: 55px;
      margin-bottom: 1px;
    }
    &__footer {
      margin-top: 30px;
    }

    .money-request {
      margin-top: -126px;
    }
  }
}


.content-request-info {
  margin-top: 94px;
  text-align: left;
  font-family: $DINPro;

  &__item {
    margin-top: 38px;
    overflow: hidden;
  }
  &__arrow {
    display: none;
  }
  &__preview {
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
    box-shadow: 0 10px 6px -6px rgba(0,0,0,.3);
  }
  &__preview-image {
    vertical-align: top;
    @extend .img-responsive;

  }
  &__text {
    margin-top: 20px;

    font-size: 15px;
    line-height: 1.4;
  }
  &__caption {
    padding-bottom: 20px;
  }
  &__user {
    display: none;
  }
}
@include min-screen($break-medium-devices) {
  .content-request-info {
    margin-top: 72px;

    &__item {
      margin-top: 28px;

      &:nth-child(odd) {
        text-align: right;
      }
    }
    &__text {
      display: inline-block;
      width: 60%;
      margin-top: 20px;
      font-size: 17px;
    }
    &__title {
      padding-bottom: 22px;
    }
    &__caption {
      padding-bottom: 20px;
    }
  }
}
@include min-screen($break-large-devices) {
  .content-request-info {
    width: 902px;

    margin-top: 76px;
    margin-left: auto;
    margin-right: auto;


    &__item {
      position: relative;
      margin-top: 61px;
      padding-bottom: 10px;
      overflow: hidden;

      &:nth-child(odd) {
        text-align: left;

        .content-request-info__text {
          float: left;
        }
        .content-request-info__preview {
          float: right;
        }
      }
    }
    &__arrow {
      position: absolute;
      top: 8px;
      left: 50%;

      margin-left: -35px;

      display: block;
    }
    &__text {
      display: block;
      float: right;
      width: 47%;
      margin-top: -7px;
    }
    &__caption {
      padding-bottom: 20px;
    }
  }
}
@include min-screen($break-extra-large-devices) {
  .content-request-info {
    position: relative;

    &__user {
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: 450px;

      display: block;
    }
  }
}


.money-request {
  padding: 39px 14px;

  font-weight: $regular;
  font-family: $DINPro;
  text-align: left;

  background: url('#{$image}/backgrounds/pattern.png');
  box-shadow: 0 0 50px rgba(0,0,0,.2);
  border-radius: 40px;


  &__inner {
    padding: 31px 20px;

    background: url('#{$image}/requests/content-request-calc.jpg');
    background-size: cover;
    border-radius: 40px;
  }
  &__title {
    font-size: 24px;
  }
  &__slider {
    margin-top: 18px;
  }
  &__slider-caption {
    margin-top: -5px;
  }
  &__total {
    margin-top: 10px;
  }
  &__calc {
    height: 57px;

    padding-top: 8px;

    text-align: center;

    @include font(36px, 1, #fff);
    background-color: #45b649;
    border-radius: 30px;

    small {
      font-size: 11px;
    }
  }
  &__button {
    margin-top: 6px;
  }
  &__submit {
    width: 100%;
  }

  &--double {
    .money-request__inner {
      padding-top: 29px;
      padding-bottom: 29px;
    }
    .money-request__slider {
      margin-top: 0;
      margin-bottom: 2px;
    }
    .money-request__slider-prepend {
      font-size: 24px;
      margin-bottom: 5px;

      small {
        font-size: 11px;
        margin-left: 8px;
      }
    }
    .money-request__label {
      font-size: 15px;
      margin-bottom: 12px;
    }
  }
}
@include min-screen($break-medium-devices){
  .money-request {
    padding: 63px 20px;

    &__inner {
      padding-top: 41px;
      padding-bottom: 31px;
    }
    &__title {
      float: left;
    }
    &__slider {
      margin-left: 170px;
      margin-top: -3px;
    }
    &__slider-caption {
      margin-top: -8px;
    }
    &__total {
      margin-top: 23px;
      text-align: center;
    }
    &__calc,
    &__button {
      display: inline-block;
      vertical-align: top;
    }
    &__calc {
      padding-left: 30px;
      padding-right: 65px;
    }
    &__button {
      margin-top: 0;
      margin-left: -50px;
    }
    &__submit {
      width: auto;
      padding-right: 30px;
      padding-left: 30px;
    }

    &--double {
      .money-request__holder {
        overflow: hidden;
      }
      .money-request__slider {
        width: 48%;
        float: left;
        margin-left: 0;

        &:last-child {
          float: right;
        }
      }
      .money-request__total {
        margin-top: 0;
      }
    }
  }
}
@include min-screen($break-large-devices){
  .money-request {
    &__inner {
      padding: 32px 25px;
    }
    &__title {
      margin-top: 15px;
    }
    &__slider {
      width: 228px;
      margin-left: 15px;
      margin-top: 11px;

      float: left;
    }
    &__slider-caption {
      margin-top: -6px;
      line-height: 1.2;
    }
    &__total {
      margin-top: 0;
      text-align: right;
    }
    &__submit {
      width: auto;
      padding-right: 30px;
      padding-left: 30px;
    }

    &--double {
      .money-request__inner {
        padding-top: 15px;
        padding-bottom: 20px;
        overflow: hidden;
      }
      .money-request__holder {
        width: 45%;
        float: left;
      }
      .money-request__slider {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .money-request__total {
        float: right;
      }
      .money-request__label {
        text-align: left;
      }
    }
  }
}
@include min-screen($break-extra-large-devices){
  .money-request {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 16px;
    padding-right: 16px;

    &__inner {
      padding-left: 22px;
      padding-right: 22px;
    }
    &__slider {
      width: 380px;
      margin-left: 20px;
    }
    &__slider-caption {
      margin-top: 2px;
    }

    &--double {
      .money-request__holder {
        width: 52%;
      }
      .money-request__slider {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .money-request__total {
        float: right;
      }
      .money-request__label {
        text-align: left;
      }
    }
  }
}