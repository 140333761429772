.tag {
  display: inline-block;
  padding: $tags_padding;

  vertical-align: middle;
  @include font(13px, 1, #fff);
  border-radius: $tags_radius;
  
  &.expects {
    background-color: $select_tag-dark-orange-backg;
  }

  &.new {
    background-color: $select_tag-green-backg;
  }
  &.plan {
    background-color: $select_tag-orange-backg;
  }
  &.freeze {
    background-color: $select_tag-blue-backg;
  }
  &.fault {
    background-color: $select_tag-gray-backg;
  }
  &.reminder {
    background-color: $select_tag-yellow-light-backg;
  }
  
  &.reminder {
    color: $font-color;
  }
  &.error {
    background-color: $select_tag-error-backg;
  }
  &.blacklist {
    background-color: $select_tag-black-backg;
  }
  
  &.visit {
    background-color: $select_tag-visit-backg;
  }
  &.online-request {
    background-color: $select_tag-online-request-backg;
  }
  &.pdf {
    background-color: $select_tag-pdf-file;
  }
  &.xls {
    background-color: $select_tag-xls-file;
  }
  &.docx {
    background-color: $select_tag-docx-file;
  }

  &.rar {
    background-color: $select_tag-rar-file;
  }
  &.issued {
    background-color: $select_tag-issued;
  }

  &.disabled {
    background-color: $select_tag-gray-backg;
  }
}


.tag-as-list {
  @extend .tag;

  &::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;

    margin-right: 5px;
    margin-top: -2px;

    vertical-align: middle;
    background-color: #fff;
    border-radius: 50%;
  }
}

