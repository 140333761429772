.calculator {
  color: #181819;
  font-family: $DINPro;
  overflow: hidden;
  background-color: #fff;

  &__header {
    position: relative;
    padding: 18px 0 13px;
    background-color: #fff;
  }
  &__title {
    @include font(23px, null, inherit, $regular);
  }
  &__subtitle {
    font-size: 18px;
    margin-top: 9px;
    margin-bottom: 5px;
  }
  &__cashtime {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__text-important {
    margin-top: 8px;

    text-align: right;
    font-style: italic;
    font-size: 14px;
    @extend .color-major;
  }
  &__title-hidden {
    position: absolute;
    z-index: -1;
  }

  &__field {
    padding-top: 11px;
    padding-bottom: 4px;
    font-size: 13px;

    background: #f7f7f7;
    background: linear-gradient(to bottom, #ededed 0%,#ffffff 100%);
  }
  &__field-header {
    @extend .clearfix;

    .calculator__field-title {
      float: left;
    }
    .calculator__field-pay-time {
      float: right;
    }
  }
  &__field-title {
    margin-bottom: 6px;
  }
  &__pay-time-icon {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  &__row {
    @extend .clearfix;
  }

  &__value {
    float: left;
    font-size: 11px;
    text-transform: uppercase;
  }
  &__value-count {
    font-size: 24px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;

    small {
      font-size: 11px;
      font-weight: $medium;
    }

    &--old {
      color: #a6a6a6;
      text-decoration: line-through;

      small {
        margin-left: 10px;
      }
    }
  }

  &__ind {
    font-size: 18px;
    margin-left: 4px;
    margin-right: 10px;
  }

  &__faq {
    position: relative;
    float: right;
    margin-top: 3px;

    &:hover {
      .calculator__faq-dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__faq-trigger {
    font-size: 11px;
    font-style: italic;
    @extend .color-major;
  }
  &__faq-icon {
    margin-left: 5px;
  }
  &__faq-icon,
  &__faq-label {
    display: inline-block;
    vertical-align: middle;
  }
  &__faq-dropdown {
    position: absolute;
    top: 22px;
    right: -16px;
    z-index: 10;

    width: 323px;

    background-color: #fff;
    border: 1px solid #F5B99F;
    box-shadow: 0 0 5px rgba(0,0,0,.21);
    opacity: 0;
    visibility: hidden;

    transition: opacity 0.21s linear, visibility 0.21s step-end;
  }
  &__faq-dropdown-arrow {
    position: absolute;
    top: -3px;
    right: 99px;

    width: 7px;
    height: 7px;

    background: #FFF;
    border: 1px solid #F5B99F;
    box-shadow: 0 0 5px rgba(0,0,0,.21);
    transform: rotate(45deg);
  }
  &__faq-dropdown-body {
    position: relative;
    font-family: $DINPro;
    padding: 15px 25px;
    background-color: #fff;

    p {
      padding-bottom: 0;
      padding-top: 10px;
    }
  }
  &__faq-dropdown-carret {
    position: absolute;
    top: 15px;
    right: 10px;
  }
  &__faq-dropdown-title {
    font-size: 24px;
    font-weight: $bold;
    color: $major-color;
  }
  &__faq-dropdown-caption {
    font-weight: $regular;
    font-style: italic;
  }

  &__footer {
    margin-top: 12px;
    padding-top: 14px;
    padding-bottom: 17px;
    background-color: $major-color;
  }
  &__submit {
    height: 58px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 2px 3px 2px rgba(0,0,0,.21);

    &:hover {
      background-color: #4cad41;
    }
  }
}
@include min-screen($break-large-devices){
  .calculator {
    position: absolute;
    top: 26px;
    left: 50%;

    margin-left: 60px;

    width: 405px;

    box-shadow: 0 0 20px rgba(0,0,0,.5);

    &__container {
      width: 100%;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .calculator {
    left: 50%;
    margin-left: 160px;
  }
}