@include font-face("ASLRouble", "#{$fonts}/aslrouble/rouble-webfont", normal, normal);

@font-face {
  font-family: 'DIN Pro';
  src: url('#{$fonts}/dinpro/dinpro.eot');
  src: url('#{$fonts}/dinpro/dinpro.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/dinpro/dinpro.woff') format('woff'),
       url('#{$fonts}/dinpro/dinpro.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'DIN Pro';
  src: url('#{$fonts}/dinproitalic/dinproitalic.eot');
  src: url('#{$fonts}/dinproitalic/dinproitalic.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/dinproitalic/dinproitalic.woff') format('woff'),
       url('#{$fonts}/dinproitalic/dinproitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'DIN Pro';
  src: url('#{$fonts}/dinprobold/dinprobold.eot');
  src: url('#{$fonts}/dinprobold/dinprobold.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/dinprobold/dinprobold.woff') format('woff'),
       url('#{$fonts}/dinprobold/dinprobold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DIN Pro';
  src: url('#{$fonts}/dinproblack/dinproblack.eot');
  src: url('#{$fonts}/dinproblack/dinproblack.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/dinproblack/dinproblack.woff') format('woff'),
       url('#{$fonts}/dinproblack/dinproblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'DIN Pro';
  src: url('#{$fonts}/dinpromedium/dinpromedium.eot');
  src: url('#{$fonts}/dinpromedium/dinpromedium.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/dinpromedium/dinpromedium.woff') format('woff'),
       url('#{$fonts}/dinpromedium/dinpromedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts}/opensans/opensans.eot');
  src: url('#{$fonts}/opensans/opensans.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/opensans/opensans.woff') format('woff'),
       url('#{$fonts}/opensans/opensans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('#{$fonts}/opensanslight/opensanslight.eot');
  src: url('#{$fonts}/opensanslight/opensanslight.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/opensanslight/opensanslight.woff') format('woff'),
       url('#{$fonts}/opensanslight/opensanslight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts}/robotoregular/robotoregular.eot');
  src: url('#{$fonts}/robotoregular/robotoregular.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/robotoregular/robotoregular.woff') format('woff'),
       url('#{$fonts}/robotoregular/robotoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts}/robotomedium/robotomedium.eot');
  src: url('#{$fonts}/robotomedium/robotomedium.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/robotomedium/robotomedium.woff') format('woff'),
       url('#{$fonts}/robotomedium/robotomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts}/robotobold/robotobold.eot');
  src: url('#{$fonts}/robotobold/robotobold.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/robotobold/robotobold.woff') format('woff'),
       url('#{$fonts}/robotobold/robotobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts}/robotoitalic/robotoitalic.eot');
  src: url('#{$fonts}/robotoitalic/robotoitalic.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/robotoitalic/robotoitalic.woff') format('woff'),
       url('#{$fonts}/robotoitalic/robotoitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts}/robotomediumitalic/robotomediumitalic.eot');
  src: url('#{$fonts}/robotomediumitalic/robotomediumitalic.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/robotomediumitalic/robotomediumitalic.woff') format('woff'),
       url('#{$fonts}/robotomediumitalic/robotomediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url('#{$fonts}/robotolight/robotolight.eot');
  src: url('#{$fonts}/robotolight/robotolight.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts}/robotolight/robotolight.woff') format('woff'),
       url('#{$fonts}/robotolight/robotolight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}