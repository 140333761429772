.form {
  font-family: $Roboto;

  &__field {
    position: relative;

    padding: 8px 13px;

    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #b4b4b4;
    border-radius: 24px;
  }
  &__field-inner {
    display: table;

    .form__label,
    .form__control {
      display: table-cell;
      white-space: nowrap;
    }
  }
  &__label,
  &__control {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    line-height: 1;
    color: #181819;
  }
  &__label {
    border-right: 1px solid #c7c1be;
    padding-right: 10px;
  }
  &__control {
    display: block;
    width: 100%;
    height: 20px;

    font-size: 13px;
    line-height: 1;
    border: 0;
    margin: 0;
    margin-left: 10px;
  }
  &__error {
    width: 100%;
    font-size: 11px;
    color: #f10000;
    text-align: left;
  }
  &__append {
    font-size: 11px;
    font-style: italic;
    color: $font-color;
  }
  &__fieldset {
    @extend .clearfix;
    margin-top: 8px;
    margin-bottom: 8px;
    border-top: 1px solid transparent;
  }
  &__actions {
    margin-top: 29px;
    text-align: center;

    .button {
      width: 133px;
      margin-left: 5px;
      margin-right: 5px;

      padding-left: 20px;
      padding-right: 20px;

      font-size: 14px;
      font-family: $Roboto;
      font-weight: $medium;
    }
  }
}

.form-control {
  width: 100%;
  height: $form-input_height;

  padding: $form-input_padding;

  border: 1px solid $form-border;
  resize: none;

  background-color: $form_backg;
  @include font(nth($form-font, 1), nth($form-font, 2), nth($form-font, 3), nth($form-font, 4));
  border-radius: $form-radius;
  box-sizing: border-box;

  transition: border-color $transition;


  @include placeholder {
    color: #dadada;
  }


  &:focus {
    border-color: $form-border-focus;
    outline: none;
  }

  &.counter {
    padding-right: 60px;

    & + .help-block {
      position: absolute;
      top: 50%;
      right: 13px;

      margin-top: -10px;

      @include font(13px, 1.5, #9c9c9c);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    background: #f5f5f5;
    color: #dadada;
  }

  &::placeholder {
    color: #b1b1b1;
  }
}
textarea.form-control {
  height: $form-textarea_height;
  padding: $form-textarea_padding;
}

.control-label {
  @include font(14px, 1.7);
  font-weight: 600;
  margin-bottom: $space-xs;
  display: inline-block;

  small {
    font-size: 12px;
  }
}

.ui-checkboxes {
  @extend .clearfix;

  font-size: 12px;

  &__controll {
    display: block;

    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 26px;

    position: relative;
    cursor: pointer;
    @include font(nth($checkbox_font, 1), nth($checkbox_font, 2));
  }
  &__input {
    opacity: 0;
    position: absolute;
    left: -9999px;

    &:checked + .ui-checkboxes__icon {
      border-color: $major-color;
      background-color: $major-color;

      .ui-checkboxes__carret {
        display: block;
      }
    }
  }
  &__icon {
    display: block;
    width: $checkbox_icon-size;
    height: $checkbox_icon-size;

    position: absolute;
    top: 0;
    left: 0;

    border: 1px solid #9c9c9c;
    background-color: #fff;
    border-radius: 50%;

    transition: background-color $transition;
  }
  &__carret {
    display: none;
    width: 6px;
    height: 6px;

    position: absolute;
    top: 50%;
    left: 50%;

    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &--vertical {
    .ui-checkboxes__controll {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.ui-calendar {
  position: relative;

  &__controll {
    position: relative;
  }

  &.is-open {
    .ui-calendar__dropdown {
      display: block;
    }
  }
}
.daterangepicker {
  display: none;
  width: auto;

  padding: 5px;
  margin-top: 10px;

  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: $calendar_dropdown-radius;
  box-shadow: $calendar_dropdown-drop;
  box-sizing: border-box;

  &_input {
    display: none;
  }
  .ranges {
    display: block !important;
    width: 100%;
    clear: both;
    float: none;
    text-align: center;

    &::before {
      content: "Последние";
      display: inline-block;
      vertical-align: middle;
      @include font(13px, 1.5);
      margin-right: 5px;
    }

    ul {
      width: auto;
      display: inline-block;
      vertical-align: middle;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      padding: 0;
      margin: 0;

      @include font(13px, 1.5);
      background-color: transparent;
      border: 0;
      border-radius: 0;

      @extend .link;


      &::before {
        content: "/";
        margin-left: 3px;
        margin-right: 3px;
        color: $font-color;
      }

      &:first-child::before {
        display: none;
      }
    }
  }
  .calendar {
    width: 280px;
    max-width: inherit;
    margin: 0;
    padding: 0;

    &.left {
      margin-right: 17px !important;
    }
    &.right {
      margin-left: 17px !important;
    }
    &.single {
      margin: 0 !important;
    }
  }
  .calendar-table {
    padding: 6px 0 0 !important;
    border: 0;

    th,
    td {
      height: 34px;

      text-align: center;

      @include font(13px, 2.6, #333);
      border-radius: 3px;
    }
    td {
      border-radius: $calendar_radius;

      &.weekend {
        color: $calendar_weekend;
      }
      &.in-range {
        border-radius: 0;
        background-color: $calendar_active-range;
      }
      &.today {
        color: $font-color;
        background-color: $calendar_today;
      }
      &.active {
        background-color: $calendar_active;
        color: inherit;
      }
      &.start-date {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: $calendar_radius 0 0 $calendar_radius;
      }
      &.end-date {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-radius: 0 $calendar_radius $calendar_radius 0;
      }
      &.start-date.end-date {
        border-radius: $calendar_radius;
      }
      &.off {
        color: #dadada;
      }
    }
    thead {
      tr {
        &:first-child th {
          padding-bottom: 15px;
        }

        th {
          padding-bottom: 14px;
          font-weight: $regular;

          &:nth-child(6),
          &:nth-child(7) {
            color: $calendar_weekend;
          }
        }
      }
    }
    th.month {
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
      @include font(17px, 1.6, $font-color, $regular);
    }
    th.available:hover {
      background-color: transparent;
    }
  }
  .fa {
    display: block;
    width: 24px;
    height: 24px;

    border: 1px solid #9c9c9c;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 17px 11px;
    background-position: 50% 50%;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color $transition;


    &.fa-chevron-left {
      background-image: url("#{$image}/svg/page-arrow_left.svg");
      background-position: 35% 50%;
    }
    &.fa-chevron-right {
      float: right;
      background-image: url("#{$image}/svg/page-arrow_right.svg");
    }

    &:hover {
      background-color: #f2f2f2;
    }
  }
  &__day {
    display: inline-block;

    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 4px;

    vertical-align: middle;
    text-align: center;

    @include font(13px, 2.6, #333);
    border-radius: 3px;
    transition: background-color $transition;

    &:hover {
      cursor: pointer;
      background-color: #ffe9c3;
    }

    &.active,
    &.active-range-end,
    &.active-range-start {
      background-color: #ffd488;
    }
  }

  &.single {
    .ranges {
      text-align: center;

      &::before {
        display: none;
      }
    }
  }
  &.opensleft:before {
    right: 40px;
  }
  &.opensleft:after {
    right: 41px;
  }
}
.ui-datepicker-text {
  &__label {
    position: relative;
    padding-left: 25px;
    padding-bottom: 0;
    background-image: url('#{$image}/png/datepicker.png');
    background-repeat: no-repeat;
  }
  &__input {
    width: 121px;
    height: 26px;

    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;

    border: 1px solid #d1d1d1;
  }
  &__label,
  &__input  {
    display: inline-block;
    vertical-align: middle;
  }
}


.ui-select {
  &.single,
  &.multi {
    .option {
      padding: 8px 11px;
      cursor: pointer;
      /* @include font(nth($select_font, 1), nth($select_font, 2), nth($select_font, 3)); */
      transition: background-color $transition;

      &:hover {
        background-color: $select_hover-option;
      }

      &:last-child {
        border-radius: 0 0 $form-radius $form-radius;
      }
    }

    .selectize-input,
    .selectize-dropdown {
      background-color: $white;
    }

    .selectize-input {
      @extend .form-control;

      height: auto;
      padding: $select_padding;
      background-image: none;
      box-shadow: none;
      transition: none;

      input[type="text"] {
        /*  @include font(nth($select_font, 1), nth($select_font, 2), nth($select_font, 3)); */
        @include placeholder {
          color: #dadada;
        }
      }

      &.focus {
        border-color: $select_active-brd;
      }

      &.dropdown-active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-color: #ededed;

        &:after {
          border: 0;
          transform: rotate(-180deg);
        }
      }

      &.disabled {
        background-color: #f5f5f5;

        &::before {
          background: transparent;
        }
        &::after {
          opacity: 0.2;
        }

        input[type="text"],
        input[type="text"]:disabled {
          @include placeholder {
            color: #dadada;
          }
        }
      }
    }

    .selectize-dropdown {
      width: 100%;

      border: 1px solid $select_active-brd;
      border-top: 0;
      border-radius: 0 0 $form-radius $form-radius;
      box-sizing: border-box;
      box-shadow: none;

      [data-selectable] .highlight {
        background-color: #d7d7d7;
      }
    }
  }

  &.selectize-control {
    width: 100%;
    /* min-width: 240px; */
    line-height: 0;
  }

  &.single {
    .selectize-input {


      .item {
        white-space: nowrap;
      }


      &:after {
        display: block;
        width: 11px;
        height: 6px;

        margin-top: -3px;

        position: absolute;
        top: 50%;
        right: 13px;
        left: auto;
        z-index: 2;

        background-image: url("../images/svg/carret-down.svg");
        background-repeat: no-repeat;
        border: 0;
      }

      &:before {
        content: "";
        display: block;

        width: 45px;
        height: 100%;
        margin-left: -45px;

        position: absolute;
        top: 0;
        left: 100%;
        z-index: 1;

        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,1) 100%);
      }



    }
  }

  &__caption {
    display: block;
    font-size: 13px;
    color: #9b9b9b;
  }
}



.reg-form .ui-checkboxes,
.sign-loan-form .ui-checkboxes {
  @extend .clearfix;
  display: inline-block;
  float: left;

  &__controll {
    display: block;
    float: left;
    padding: 0 0 0 19px;
    position: relative;
    cursor: pointer;
    font-size: 14px;


    &:hover {
      .ui-checkboxes__icon {
        background-color: #ffe9c3;
      }
    }
  }
  &__input {
    opacity: 0;
    position: absolute;
    left: -9999px;

    &:checked + .ui-checkboxes__icon {
      background-color: #ffe9c3;
      border-color: #9c9c9c;

      .ui-checkboxes__carret {
        display: block;
      }
    }
  }

  &__icon {
    display: block;
    width: $checkbox_icon-size;
    height: $checkbox_icon-size;

    position: absolute;
    top: 1px;
    left: 0;

    border: 1px solid #9c9c9c;
    background-color: #fff;
    border-radius: 3px;

    transition: background-color $transition;
  }
  &__carret {
    display: none;
    width: 14px;
    height: 13px;

    position: absolute;
    top: 4px;
    left: 10px;

    background-color: transparent;
    border-radius: 0;
  }

  &__label {
    line-height: 1.5;
  }
}


.reg-form {
  .is-md-indent {
    margin-top: $space-md;
  }
  .is-lg-indent {
    margin-top: $space-lg;
  }
  .is-xs-indent {
    margin-top: $space-xs;
  }
  padding-bottom: 30px;
}

/* Группировка элементов */
.form-group {
  position: relative;
  text-align: left;

  .link, span {
    padding-top: 10px;
    display: inline-block;
  }

  .ui-checkboxes {
    padding-top: 11px;

    &.disabled {
      opacity: 0.5;
      cursor: default;
      background-color: #fff;
    }
  }
  .ui-checkboxes__controll {
    float: none;
    margin-top: 22px;
    line-height: 1.3;


    &:first-child {
      margin-top: 0;
    }

    &:hover {
      cursor: default;
      background-color: #fff;
    }
  }
}
@include min-screen($break-small-devices) {
  .form-group {
    &--short {
      width: 80%;
    }
  }
}

.sign-loan-form {

  .sign-input-wrap {
    margin-bottom: 17px;
  }

  &__params-toggle {
    border-bottom: 1px dashed;
  }

  &__parameters {
    display: none;
    margin-top: 17px;
  }

  .ui-checkboxes {
    float: none;
    margin: 4px 0;
  }

}