.tab-section {
  display: none;
  border-top: 1px solid #f0f0f0;

  &--line {
    border: 0;
  }
}

.tab {
  display: none;
}

.tab-label {
  display: inline-block;
  margin-right: -5px;
  padding: 15px 18px;
  font-size: 15px;
  font-weight: $bold;
  text-align: center;
  border-right: 1px solid #f0f0f0;
  cursor: pointer;
  position: relative;

  &--line {
    border: 0;
    padding: 0;
    font-size: 13px;
    margin-right: 10px;
    font-weight: $regular;
  }

}

.tab:checked + .tab-label {
  color: $major-color;
}

#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4,
#tab5:checked ~ #content5 {
  display: block;
}
@include min-screen($break-medium-devices) {

  .tab-section {
    padding: 30px;

    &--line {
      padding: 20px 0 0 0;
    }
  }

  .tab-label {
    margin-right: -4px;
    padding: 25px 30px;
    font-size: 18px;

    &--line {
      font-size: 14px;
      margin: 0 15px 0 0;
      padding: 0;
    }
  }
}

/* .tabs-wrap {
   overflow: hidden;
}

.tabs {
  width: auto;
}


.tabs {
  a {
    margin-right: 15px;
  }
}

a.btn:hover {
  color: #fff;
} */