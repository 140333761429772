.page-loan {
  font-family: "Roboto";

  .h3 {
    font-family: "Roboto";
  }

 .calculator {
    font-family: "Roboto";
    overflow: visible;
    box-shadow: none;
    margin-left: -15px;
    margin-right: -15px;

    &__faq-dropdown {
      right: -14px;
    }

    &__container {
      padding-right: 15px;
      padding-left: 15px;
    }

    &__panel {
      display: none;
    }

    &__passport {
      display: none;
    }

    &__title {
      @include font(21px, 28px, inherit, $medium);
      margin-bottom: 15px;
    }

    &__field--md {
      display: none;
    }

    &__value-count {
      font-size: 21px;
    }

    &__value-text {
      text-transform: none;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
    }

    &__pay {
      font-size: 13px;
      padding-top: 20px;
      border-top: 1px dashed #a5a5a5;
      margin-top: 10px;
      &-title {
        margin-bottom: 10px;
      }
      &-subtitle {
        font-style: italic;
      }
      &-time {
        font-weight: $medium;
        font-style: italic;
      }
    }

    &__list {
      margin-bottom: 20px;
    }

    &__item {
      display: inline-block;
      & img {
        width: 65px;
      }
    }

    &__btnwrap {
      text-align: center;
    }

    &__conditions {
      position: relative;
      display: inline-block;
      margin-top: 20px;
      &-tooltip {
        background: #fff;
        padding: 7px 15px;
        position: absolute;
        bottom: 110%; left: 0;
        font-size: 11px;
        font-style: italic;
        box-shadow: 0 0 10px rgba(0,0,0,.5);
        display: none;
      }

      &-item {
        display: inline-block;
        width: 55px;
        height: 55px;
        border: 1px solid rgba(238, 220, 149, .3);
        background: url('../images/png/calc_icons.png');

        &-tooltip {
        background: #fff;
        padding: 10px 15px;
        position: absolute;
        bottom: 110%; left: 0;
        font-size: 11px;
        font-style: italic;
        box-shadow: 0 0 10px rgba(0,0,0,.5);
        display: none;
        transition: 0.3s linear;
        }

        &:hover {
          border-color: rgba(238, 220, 149, 1);
        }

        &:hover .calculator__conditions-tooltip {
          display: block;
        }


        &_pass {
          background-position: -2px 0;
          &:hover {
            background-position: -58px 0;
          }
        }
        &_cash {
          background-position: -2px 167px;
          &:hover {
            background-position: -58px 167px;
          }
        }
        &_people {
          background-position: -2px 111px;
          &:hover {
            background-position: -58px 111px;
          }
        }
        &_sec {
          background-position: -2px 55px;
          &:hover {
            background-position: -58px 55px;
          }
        }
      }
    }
  }
}
@include min-screen($break-medium-devices){
  .page-loan {
    .h3 {
      font-size: 30px;
    }

    .calculator-wrap {
      //background: url('../images/main-bg.jpg');
      padding-top: 10px;
      padding-bottom: 40px;
    }


    .calculator {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &__inner {
        box-shadow: 0px -5px 30px rgba(0,0,0,.2);
        z-index: 2;
        position: relative;
      }

      &__container {
        width: 96%;
        margin-left: auto;
        margin-right: auto;
      }

      &__field--md {
        display: block;
        & .calculator__container {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      &__field--xs {
        display: none;
      }

      &__field {
        padding-top: 20px;
        padding-bottom: 10px;

      }

      &__field-title {
        float: left;
      }
      &__row {
        float: right;
        width: 80%;
      }
      &__col {
        height: 50px;
        float: left;
        padding: 10px;
          &:not(:first-child) {
          border-left: 1px dashed #a5a5a5;
        }
      }

      &__field-title {
        margin-right: 10px;
      }

      &__pay {

        &-title {
          margin-bottom: 0;
          display: inline-block;
          vertical-align: middle;
          margin-right: 25px;
        }
        &-subtitle, &-time {
          display: inline-block;
          margin-top: 15px;
        }
      }

      &__list {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }

      &__item {
        display: inline-block;
        position: relative;

        & img {
          width: auto;
        }
        &:not(:last-child) {
            padding-right: 50px;
          &::after {
            content: '';
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: $major-color;
            display: inline-block;
            position: absolute;
            bottom: 50%;
            right: 25px;
            transition: translate(-50%, -50%);
          }
        }
      }

      &__btnwrap {
        float: left;
      }

      &__conditions {
        float: right;
        margin-top: 0;
      }

      &__footer {
        padding-top: 45px;
        padding-bottom: 14px;
      }
    }
  }
}

@include min-screen($break-large-devices){
  .page-loan {
    .h3 {
      font-size: 30px;
    }

    .calculator {
      width: 820px;

      position: relative;
      top: auto;
      left: auto;



      &__container {
        width: 85%;
      }

      &__title {
        font-size: 27px;
      }

      &__header {
        .calculator__container {
          width: 91%;
        }
      }

      &__panel {
        display: block;
        width: 70px;
        position: absolute;
        top: 99px;
        right: -70px;
        & small {
          font-size: 13px;
        }
        &-top {
          color: #fff;
          text-align: center;
          padding: 20px 0 10px;
          font-size: 34px;
          font-weight: 500;
          background: $major-color;
        }
        &-bottom {
          height: 160px;
          background: #f5eed4;
          position: relative;
          &::after {
            content: '';
            display: block;
            background: url('../images/png/lightning.png') no-repeat;
            width: 15px;
            height: 25px;
            position: absolute;
            bottom: 10px;
            left: 30px;
          }
        }
        &-text {
          transform: translateX(-50%) rotate(-90deg);
          position: absolute;
          top: 45px;
          left: 50%;


        }
      }
    }
  }
}
@include min-screen($break-extra-large-devices){
  .page-loan {
    .calculator {
      left: auto;
      margin-left: 300px;
      width: 760px;
      &__passport {
        display: block;
        position: absolute;
        top: 40px;
        right: 102%;
      }
      &__container {
          width: 92%;
      }
      &__header .calculator__container {
        width: 97%;
      }
    }
  }
}


.loan-features {
  padding-top: 30px;
  &__inner {
    font-size: 0;
  }
  &__item {
  text-align: center;
  }
  &__pic {
    width: 160px;
    height: 160px;
    background: #fff;
    border: 10px solid #f4f5f4;
    border-radius: 50%;
    box-shadow: 5px 5px 10px rgba(0,0,0,.2);
    position: relative;
    display: inline-block;
    margin-bottom: 50px;

   &::after {
     content: '';
     width: 5px;
     height: 5px;
     border-radius: 50%;
     background: $major-color;
     display: inline-block;
     position: absolute;
     bottom: -40px;
     left: 50%;

   }

    &_1 {
      background: #fff url('../images/content-loan/feature1.jpg') 50% 50% no-repeat;
    }
    &_2 {
      background: #fff url('../images/content-loan/feature2.jpg') 50% 50% no-repeat;
    }
    &_3 {
      background: #fff url('../images/content-loan/feature3.jpg') 50% 50% no-repeat;
    }
    &_4 {
      background: #fff url('../images/content-loan/feature4.jpg') 50% 50% no-repeat;
    }
  }

  &__text {
    font-size: 17px;
    font-weight: $medium;
    margin-bottom: 50px;
  }
}

@include min-screen($break-medium-devices){
  .loan-features {
    padding-top: 50px;

    &__item {
    display: inline-block;
    width: 50%;
    }
    &__text {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }
}

@include min-screen($break-large-devices){
  .loan-features {

    &__item {
    width: 25%;
    vertical-align: top;
    }
    &__text {
      margin-bottom: 0;
    }
  }
}

.content-request-loan {

  padding-bottom: 40px;
  .content-request-info {
    &__caption {
      font-size: 15px;
    }
  }
}

@include min-screen($break-medium-devices){
  .content-request-loan {
     text-align: right;
    padding-bottom: 80px;
    .content-request-info {

     &__item:nth-child(odd) {
        text-align: left;
      }
    }
  }
}

@include min-screen($break-large-devices){
  .content-request-loan {
    .content-request-info {
      &__text {
        margin-top: 0;
      }
      &__item:not(:last-child)::after {
        content: '';
        width: 96px;
        height: 4px;
        display: block;
        background: url('../images/png/divider.png');
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }


      &__item:nth-child(odd) {
        text-align: left;

        .content-request-info__text {
          float: right;
        }
        .content-request-info__preview {
          float: left;
        }
      }
      &__text {
      display: block;
      float: left;
      width: 47%;
      text-align: left;
      }
    }
  }
}

@include min-screen($break-extra-large-devices){
  .content-request-loan {
    .content-request-info {
      &__text {
        width: 53%;
      }
      &__arrow {
        left: 59%;
      }
      &__arrow_left {
        left: 43%;
      }


    }
  }
}

.payment-loan {
  background: #f0f3f0;
  padding-top: 65px;
  padding-bottom: 20px;
  border-top: 1px solid #dcdedc;
  border-bottom: 1px solid #dcdedc;
  position: relative;
  &__logo {
    display: none;
  }
  &__title {
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-size: 30px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 20px;
  }
  &__col {
    margin-bottom: 25px;
    font-size: 0;
  }
  &__caption {
    font-size: 15px;
  }
  &__paylogo {
    border: 1px solid #dcdedc;
  }
  &__pic {
    background: #fff;
    text-align: center;
    height: 135px;
    padding-top: 20px;
    &:not(:first-child) {
      border-top: 1px solid #dcdedc;
    }
    & img {
      max-width: 220px;
    }
  }
}

@include min-screen($break-medium-devices){
  .payment-loan {
    padding-top: 65px;
    padding-bottom: 20px;

    &__logo {
      display: block;
      position: absolute;
      top: -93px;
      left: 50%;
      margin-left: -93px;
    }
     &__title {
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
      }
    &__subtitle {
      font-size: 48px;
      font-weight: 200;
      line-height: 30px;
      margin-bottom: 50px;
    }
     &__caption {
      font-size: 17px;
      line-height: 22px;
      padding-bottom: 20px;
    }
    &__pic {
      display: inline-block;
      width: 50%;

      &:nth-child(2) {
        border-top: none;
      }

      &:nth-child(odd) {
        border-right: 1px solid #dcdedc;
      }
    }
  }
}

@include min-screen($break-large-devices){
  .payment-loan {
    &__col {
      width: calc(100% / 2 - 35px);
      float: left;
      &:last-child {
        margin-left: 35px;
      }
    }
  }
}

.credit-story {
  padding-top: 45px;
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__col {
    margin-bottom: 30px;
  }
  &__title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  &__caption {
    font-size: 15px;
    line-height: 21px;
  }
  &__img {
    width: 100%;
    height: auto;
    max-width: 493px;
  }
}

@include min-screen($break-medium-devices){
  .credit-story {
  padding-top: 65px;
    &__col {
    }
    &__title {
      width: 70%;
      margin-bottom: 30px;
      &_big {
        font-weight: 200;
        font-size: 42px;
      }
    }
    &__img {
    width: auto;
    }
    &__caption {
      font-size: 17px;
      line-height: 22px;
      padding-bottom: 20px;
    }
  }
}

@include min-screen($break-large-devices){
  .credit-story {
    &__inner {
      flex-direction: row;
    }
  }
}