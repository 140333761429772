 .payment {
  padding-bottom: 30px;

  &__title {
    margin-bottom: 30px;
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }
}


.payment-form {

  flex-basis: 100%;
  margin-bottom: 30px;

  &__title {
    font-size: 18px;
  }
  .form__label {
    border-right: 1px solid #c7c1be;
    padding-right: 10px;
  }

  & .ui-checkboxes--vertical .ui-checkboxes__controll {
    font-size: 13px;
    line-height: 1.5;
    padding-top: 0;
  }

  & .ui-checkboxes__icon {
    top: 1px;
  }
}

.payment-info {
  flex-basis: 100%;

  &__title {
    font-family: "Open Sans";
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__content {
    background: #ffffff;
    padding: 10px 15px 20px;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 20px;
  }

  &__table {
  }

  &__row {
    display: block;
  }

  &__name, &__value {
    display: inline-block;
    padding: 10px 0;
  }

  &__name {
    width: 33%;
  }

  &__img {
    margin-right: 40px;
    vertical-align: middle;
  }

  &__img:last-child {
    margin-right: 0;
  }

  &__button {
    width: 245px;
  }
}

.payment-add-info {
  margin-top: 20px;
  width: 100%;
}

@include min-screen($break-medium-devices){

  .payment {

    &__title {
      margin-bottom: 50px;
    }
  }

  .payment-form, .payment-info {
    width: 63%;
  }

  .payment-add-info {
    width: 63%;
  }

  .payment-info {
    &__name {
    width: 33%;
    }
  }
}

@include min-screen($break-large-devices){

  .payment {

    &__inner {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }

    &__title {
      width: 100%;
    }
  }

  .payment-form {
    flex-basis: 46%;
    width: 50%;
  }

  .payment-info {
    flex-basis: 48%;
    width: 50%;
  }

  .payment-add-info {
    width: 100%;
  }
}


/*------------repay----------------*/
.repay {

  &__inner {
    margin-left: -15px;
    margin-right: -15px;

  }

  &__subtitle {
    font-size: 27px;
    font-family: "Open Sans";
    font-weight: 300;
    margin: 40px 0 20px 0;
  }

  &__col {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__info {
    padding: 20px;
    background: #e8f4e7;
    font-size: 13px;
    line-height: 1.6153;
    margin-top: 15px;

  }
}

.repay-panel {
  width: 100%;
  padding: 0 10px 0 10px;
  border: 1px solid #ececec;
  margin-top: 10px;
  height: 70px;
  position: relative;
  transition: .3s linear;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
  }

  &:after {
    content:'';
    height: 12px;
    width: 12px;
    display: block;
    border:2px solid $major-color;
    border-right-width: 0;
    border-top-width: 0;
    transform: translateY(-50%) rotate(-135deg);
    position: absolute;
    right: 20px;
    top: 50%;
  }

  &__icon {
    display: inline-block;
    height: 100%;
    width: 65px;
    text-align: center;
    margin-right: 15px;
    float: left;
    padding-top: 5px;
  }

  &__img {
    transform: translateY(-50%);
    margin-top: 50%;



  }

  &__text {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 18px;
    line-height: 70px;
  }
}

@include min-screen($break-medium-devices){
  .repay {
    padding-bottom: 30px;
    &__col {
      width: 48%;

      &:not(last-child) {
        float: left;
      }
    }

    &__subtitle {
      margin-top: 51px;
    }
  }
}

@include min-screen($break-large-devices){
  .repay {
    &__inner {
      max-width: 90%;
    }
  }
}

@include min-screen($break-extra-large-devices){
  .repay {

    &__col {
      width: calc(100% / 3);

      &:last-child {
        float: left;
      }
    }
  }
}

.payment-form {
  .form__field-inner {
    width: 100%;
  }

  .form__fieldset {
    &>div {
      margin-top: 5px;
    }
    .control-label {
      margin-bottom: 0px;
    }
  }
}