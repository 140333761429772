.button {
  display: inline-block;
  min-height: 1em;
  margin: 0;
  padding: .78571429em 1.5em .78571429em;

  cursor: pointer;
  outline: 0;
  border: none;
  vertical-align: baseline;

  background: none;

  @include font(17px, 1em, #fff, $bold, normal, $DINPro);
  text-align: center;
  text-decoration: none;

  text-transform: none;
  text-shadow: none;
  border-radius: .28571429rem;
  user-select: none;
  transition:
    opacity $transition,
    background-color $transition,
    color $transition,
    box-shadow $transition,
    border-color $transition,
    background $transition;
  will-change: '';
  -webkit-tap-highlight-color: transparent;


  &--hamburger {
    padding: .5em;
    position: relative;

    .button__icon {
      width: 26px;
      height: 19px;
    }

    &.js-is-active {
      &::after,
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 3px;
        height: 26px;

        margin-top: -13px;
        margin-left: -2px;

        background-color: #000;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }

      .button__icon {
        opacity: 0;
      }
    }
  }
  &--major {
    background-color: $button-major_backg;

    &:hover, &:active, &:focus {
      color: #fff;
    }
    &:hover {
      background-color: $button-major_backg-active;
    }
  }
  &--icon {
    .button__icon,
    .button__label {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &--basic {
    border: 1px solid #eee;
    color: $font-color;

    &:hover {
      background-color: #fff;
      border-color: $major-color;
      color: $major-color;
    }

    &.button--major {
      background-color: #fff;
      border-color: $major-color;
      color: $major-color;

      &:hover {
        color: #fff;
        background-color: $major-color;
      }
    }
  }
  &--green {
    background-color: #4cad41;

    &:hover {
      color: #fff;
      background-color: $major-color;
    }
  }

  &--no-radius {
    border-radius: 0;
  }
  &--small-radius {
    border-radius: 3px;
  }

  &--tiny {
    padding: 8px 16px;
    font-weight: $regular;
    font-size: 10px;
    line-height: 1.1;
    text-transform: uppercase;
  }
  &--small {
    height: 36px;
    padding: 8px 16px;
    font-weight: $regular;
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
  }
  &--medium {
    min-width: 150px;
    min-height: 57px;
    padding: 14px 20px;
    font-size: 18px;
    border-radius: 30px;

    &.button--small-radius {
      border-radius: 3px;
    }
  }
  &--fluid {
    width: 100%;
  }

  &--take-money {
    height: 57px;

    padding-top: 10px;
    padding-left: 17px;
    padding-right: 17px;
  }
}

.btn {
  display: inline-block;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  box-sizing: border-box;
  transition: background-color $transition, box-shadow $transition;
  border-radius: $button_radius;
  font-weight: 400;


  &:focus {
    outline: none;
  }

  &.is-wide {
    width: 100%;
  }

  &:disabled {
    cursor: default;
  }

  &:disabled:hover {
    box-shadow: 0 0 0 1px $button_backg_disabled_hover;
  }

  &--uppercase {
    text-transform: uppercase;
  }


  /* Основная кнопка major button */
  &-major {
    @extend .button;
    height: $button-major_height;
    padding: $button-major_padding;

    background-color: $button-major_backg;
    box-shadow: 0 0 0 1px $button-major_backg;

    @include font(nth($button-major_font, 1), nth($button-major_font, 2), nth($button-major_font, 3));


    &:hover,
    &:focus {
      background-color: $button-major_back-hover;
      box-shadow: 0 0 0 1px $button-major_back-hover;
      color: #fff;
    }

    &:active {
      background-color: $button-major_backg-active;
      box-shadow: 0 0 0 1px $button-major_backg-active;
      color: #fff;
    }

    &:disabled {
      background-color: $button-major_backg-disabled;
      box-shadow: 0 0 0 1px $button-major_backg-disabled;
      color: #fff;
    }

    &.is-loading {
      color: #fff;
      background-color: $button_backg-loading;
      box-shadow: 0 0 0 1px $button_backg-loading;
    }

    &--right {
      float: right;
    }
  }

  /* Второстепенная кнопка minor button */
  &-minor {
    @extend .button;
    height: $button-minor_height;
    padding: $button-minor_padding;

    background-color: $button-minor_backg;
    box-shadow: 0 0 0 1px #d7d7d7;

    @include font(nth($button-minor_font, 1), nth($button-minor_font, 2), nth($button-minor_font, 3));


    &:hover,
    &:focus {
      background-color: $button-minor_back-hover;
      color: inherit;
    }

    &:active {
      background-color: $button-minor_backg-active;
      color: inherit;
    }

    &:disabled {
      color: #fff;
      background-color: $button-minor_backg-disabled;
      box-shadow: 0 0 0 1px $button-minor_backg-disabled;
    }

    &.is-loading {
      color: #fff;
      background-color: $button_backg-loading;
      box-shadow: 0 0 0 1px $button_backg-loading;
    }
  }


  /* Кнопки групповые */
  &-group {
    font-size: 0;

    .btn-minor {
      height: $button-minor-group_height;
      text-align: center;
      padding: 0;
      background-color: $button-minor-group_backg;
      @include font(nth($button-minor-group_font, 1), nth($button-minor-group_font, 2), nth($button-minor-group_font, 3));
      border-radius: 0;
      outline: none;
      font-weight: 400;
      width: 120px;

      &:hover {
        box-shadow: 0 0 0 1px #9c9c9c;
        position: relative;
      }

      &:first-child {
        border-top-left-radius: $button_radius;
        border-bottom-left-radius: $button_radius;
        margin-left: 0;
      }

      &:last-child {
        border-top-right-radius: $button_radius;
        border-bottom-right-radius: $button_radius;
      }

      &:focus,
      &:active,
      &.is-active,
      &.is-active:hover {
        background-color: $button-minor-group_backg-active;
        box-shadow: 0 0 0 1px #d7d7d7;
      }
    }

    .disabled {
      opacity: 0.5;
    }
  }
}
