.range-slider {
  .irs-line-mid,
  .irs-line-left,
  .irs-line-right,
  .irs-bar,
  .irs-bar-edge,
  .irs-slider {
    background-image: none;
  }

  .irs-line {
    & {
      top: 12px;
      height: 6px;
    }
    &-mid,
    &-left,
    &-right {
      height: 100%;
    }
    &-mid,
    &-left,
    &-right {
      background-color: #4cad41;
    }
    &-left {
      width: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &-right {
      width: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &-mid {
      left: 0;
      right: 0;
      width: 100%;
      border-radius: 3px;

    }
  }
  .irs-slider {
    top: 0;

    width: 29px;
    height: 29px;

    background-image: url('#{$image}/png/content-common-calc-bar.png');
    background-repeat: no-repeat;
    background-position: 0 0;

    border-radius: 50%;
    box-shadow: 5px 5px 10px #999;
  }
  .irs-bar {
    left: 0 !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    &,
    &-edge {
      top: 12px;
      height: 6px;
      background-color: #2184be;
    }
    &-edge {
      width: 0;
    }
  }
}

.owl-carousel {
  padding-bottom: 23px;

  .owl-dots {
    margin-top: 10px;
    text-align: center;
  }
  .owl-dot {
    display: inline-block;

    width: 10px;
    height: 10px;

    margin-left: 4px;
    margin-right: 4px;

    background-color: #d3d3d3;
    border-radius: 50%;

    transition: background-color $transition;

    &.active {
      background-color: $major-color;
    }
  }
}

.tooltip {
  width: 200px;
  height: 200px;
  border: 1px solid $major-color;
  position: absolute;
  background: #fff;
  display: none;
  transition: .3s;
  position: absolute;
  left: -93px;
  top: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 5;


  &:before, &:after {
    content: '';
    border: 10px solid transparent;
    width: 0px;
    height: 0px;
    border-bottom-color: #fff;
    background-color: transparent;
    position: absolute;
    top: -20px; left: 50%;
    transform: translateX(-50%);

  }

  &:before {
    border-bottom-color: $major-color;
    top: -21px;
  }

  img {
    display: block;
    max-width: 130px;
    max-height: 130px;
    margin: 20px auto 15px;

  }
}
.tooltip-btn {
   margin-left: 2px;
   display: inline-block;
   width: 14px;
   height: 14px;
   background: url('../images/svg/tooltip.svg') no-repeat;
   transition: .3s;
   position: relative;
   vertical-align: middle;

   &:hover .tooltip {
     display: block;
   }
 }


.tt-menu,
.tt-dropdown-menu {
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}
.tt-suggestion {
  padding: 0px 20px;
  font-size: 18px;
  line-height: 24px;

  p {
    margin: 0;
  }

  &:hover,
  &.tt-cursor,
  &.tt-is-under-cursor {
    color: #fff;
    background-color: #0097cf;
    cursor: pointer;
  }
}

.form-group .twitter-typeahead {
  display: block !important;
  padding: 0 !important;
}