

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.fl2 {
  display: flex;
  align-items: center;

  &.fl2-top {
    align-items: flex-start;
  }
  &.fl2-start {
    justify-content: flex-start;
  }
  &.fl2-end {
    justify-content: flex-end;
  }
  &.fl2-between {
    justify-content: space-between;
  }
  &.fl2-space-10 {
    > div:not(:first-child){
      margin-left: 10px;
    }
  }
}
.fl-grow-1 {
  flex-grow:1;
}


.w100 {
  width:100%;
}

#gridAuth {
  margin-top:80px;
  margin-bottom:80px;
  form {
    text-align: left;
  }

  >.grid-auth-centered {
    max-width: 285px;
    min-width: 285px;
    margin: 0 auto;
  }
}


#rootLoader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #ffffff;
  display: flex;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  z-index: 1000;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: #f4a836;
      position: relative;
      -webkit-animation: 1.2s grow ease-in-out infinite;
      animation: 1.2s grow ease-in-out infinite;
    }
    > div:nth-child(2){
      -webkit-animation: 1.2s grow ease-in-out infinite 0.15555s;
      animation: 1.2s grow ease-in-out infinite 0.15555s;
      margin: 0 20px;
    }
    > div:nth-child(3){
      -webkit-animation: 1.2s grow ease-in-out infinite 0.3s;
      animation: 1.2s grow ease-in-out infinite 0.3s;
    }
  }
}

.root-component-container-list {
  >div {
    > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.contract-accept-main {
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
  margin-top:20px;

  .sms-confirm-main {
    display: flex;
    flex-wrap:wrap;
    align-items: flex-end;
    justify-content: space-between;
    > a {
      margin-left:5px;
    }

    input[type='text'] {
      width:100px;
    }
  }
}

.main-loan-ready {
  .sign-body-list {
    margin:20px 0px;
    position:relative;
    >div:not(:last-child) {
      margin-bottom: 9px;
    }
    &:after {
      content: "";
    }
  }

  .main-sign-sms-list-codes {
    position:relative;
    padding-left:35px;
    .in-sms-valid-code {
      position:relative;
      background:white;
      z-index:2;
      top:0;
      bottom:0;
      left:0;
      right:0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > .form {
        margin-bottom:20px;
      }
      > a:last-child {
        margin-top:25px;
      }
    }
    .timer-left-registration {
      margin-bottom:10px;
    }
  }

  .carret-down-up-link {
    color:black;
    &.subheader {
      color:#404040;
    }
  }
}


@-webkit-keyframes grow {
  0%, 40%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes grow {
  0%, 40%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


.continue-auth-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  video {
    max-width:100%;
  }
  canvas {
    max-width:100%;
  }

  .imgTitle{
    &.centered {
      max-width: 280px;
      outline: 1px solid #e2e2e2;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .ui.buttons {
      margin-top:30px;
    }
  }
}


.photo-continue-auth-steps {
  padding-right:12px;
  min-width: 285px;
  max-width: 285px;

  .step {
    cursor:pointer !important;
    position:relative;
    flex-wrap:nowrap !important;

    >.content {
      > i.icon {
        position: absolute;
        left: 2px;
        top: 50%;
        margin-top: -8px;
        font-size: 20px;
        /*margin-left: -11px;*/
      }
    }
    /*&.photo-status-type-0 {
      position:relative;
      >:after{
        content: "";
        display: block;
        position:absolute;
        width:5px;
        left:0;
        top:0;
        bottom:0;
        background-color:rgb(232, 232, 232) !important;
      }
    }
    &.photo-status-type-1 {
      position:relative;
      >:after{
        content: "";
        display: block;
        position:absolute;
        width:5px;
        left:0;
        top:0;
        bottom:0;
        background-color:#db2828 !important;
      }

    }
    &.photo-status-type-2 {
      position:relative;
      >:after{
        content: "";
        display: block;
        position:absolute;
        width:5px;
        left:0;
        top:0;
        bottom:0;
        background-color:#21ba45 !important;
      }
    }*/
  }
}
.last-step-end-text {
  font-size: 18px;
  line-height: 1;
}


.fixed-full-capture-screen {
  position: fixed;
  background: rgba(254,254,233,0.74);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-width:90%;
    max-height: 90vh;
  }
  canvas {
    max-width:90%;
    width: 60%;
  }
  .vd-main {
    text-align:center;

    img {
      max-width:90%;
      max-height: 90vh;
    }
  }
  .capture-actions {
    text-align:center;
  }
}

.main-chat-block-container {
  background: #fffff3;
  padding: 10px;
  border: 1px solid #c1c157;

  .main-chat-block-messages {
    overflow-y: auto;
    max-height: 445px;
    padding-bottom: 10px;
    padding-right: 10px;
    background: #efefd9;
    padding: 5px;
    padding-bottom: 13px;
    position:relative;

    .chat-item-message {
      display: flex;
      align-items: center;

      .chat-item-icon {
        width: 50px;
        min-width: 50px;
        height: 50px;
        background: green;
        color: white;
        line-height: 50px;
        border-radius: 50%;
        text-align: center;
        margin-right: 10px;
        align-self:flex-start;
      }

      .chat-item-message-text {
        padding: 5px 15px;
        @include border-radius(9px);
        background: #ecffec;

        .photo-in-message {
          &.errImg {
            img {
              border: 2px solid #f57070;
            }
          }
          &.small {
            max-width:200px;
          }
        }

        img {
          max-width:100%;
        }

        >p:last-child{
          margin-bottom:0;
        }

        .chat-capture-canvas {
          margin-top: 5px;

          video {
            max-width:100%;
            width: 100%;
          }
          canvas {
            max-width:100%;
            width:100%;
          }
        }
        .ui.message {
          >p {
            margin-bottom:0px !important;
          }
        }
      }
      .chat-item-message-text:not([type="text"]) {
        padding: 0px;
        background: transparent;
      }
    }
    .chat-item-message:not(:last-child) {
      margin-bottom: 9px;
    }

    .chat-item-message[is-user="1"]{
      flex-direction: row-reverse;
      .chat-item-icon {
        margin-right: 0px;
        margin-left: 10px;
        background: orange;
      }

      .chat-item-message-text[type="text"] {
        background: #ffeaba;
      }
    }

    .chat-item-message[is-user="1"] + .chat-item-message[is-user="1"] {
      .chat-item-icon {
        display: none;
      }
      .chat-item-message-text {
        margin-right: 60px;
      }
    }

    .chat-item-message[is-user="0"] + .chat-item-message[is-user="0"] {
      .chat-item-icon {
        display: none;
      }
      .chat-item-message-text {
        margin-left: 60px;
      }
    }
  }

  .main-chat-block-actions[exist="1"] {
    margin-top:10px;
  }

  .main-chat-block-actions {
    .chat-action-photo {
      .action-list {
        display: flex;
        width:100%;
        >a {
          flex-grow:1;
        }
      }

    }
    .chat-action-add-card {
      .action-list {
        display: flex;
        width:100%;
        >a {
          flex-grow:1;
        }
      }
    }
  }
}

.ui.message {
  .in-ui-message-content {
    p {
      margin-bottom:0px !important;
    }
  }
}
.user-block-credit-limit {
  > .credit-limit-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap:wrap;
    /*justify-content: flex-start;*/

    /*>div:not(:last-child) {
      margin-right: 20px;
    }*/
    .cl-round-block {
      display: flex;
      align-items: center;

      >div:first-child {
        font-size: 30px;
        padding: 20px;
        border: 2px solid #b3b3b3;
        border-radius: 50%;
        /*color: #b3b3b3;*/

        margin-right: 10px;
      }

      .clrb-tb {
        color:#949494;
      }
      .clrb-pb {
        font-weight: bold;
      }
      .clrb-lb {
        text-decoration: underline;
      }
    }
  }
}



//.main-history-request {
.request-history-item {
  padding: 5px 10px;
  border: 1px solid #d0d0d0;
  /*margin: 2px 0px;*/
  box-shadow: 0px 1px 4px -1px #b3b3b3;


  >div.rhi-data {
    display: flex;
    width:100%;
    align-items:center;
    justify-content: center;
    flex-wrap:wrap;

    &[onlybutton="1"] {
      justify-content: flex-start;
    }
    &[is-operations="1"] {
      margin-bottom: 10px;
    }

    >.request-content {
      flex-grow:1;
      padding-bottom: 5px;
      >.header {
        font-weight:normal !important;
        >.sub:first-child {
          margin-top:3px;
        }
      }
    }
    >.request-status {
      flex-grow:1;
    }
    >.request-actions {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      >.request-date {
        text-align: right;
        color:#7d7d7d;
      }
      >.request-action{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        flex-grow: 1;
        >a{
          margin-right:0px !important;
        }
      }
    }
  }
}

.list-operations-main {
  >div{
    padding-left:$list_operation_main_padding_left;
    padding-bottom: 20px;
    position:relative;
    &:after{
      content: "";
      position: absolute;
      width: $list_operation_width_vertical_line;
      background: $list_operation_main_color;
      left: $list_operation_vertical_left;
      top: 0;
      bottom: 0px;
    }
    >div {
      display: flex;
      flex-direction: column;
      position: relative;

      .operation-date {
        color:grey;
      }
      .operation-amount-type {
        font-weight:bold;
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: $list_operation_width_line;
        height: $list_operation_height_line;
        background: $list_operation_main_color;
        margin-top: ($list_operation_height_line / 2) *-1;
        left: ($list_operation_main_padding_left - $list_operation_vertical_left)*-1;
        top: 50%;
      }

      /*&:after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        background: $list_operation_main_color;
        @include border-radius(50%);
        left: -8px;
        top: 50%;
        margin-top: -20px;
        margin-left: -5px;
      }*/
    }
  }
  >div:first-child {
    /* color:green;*/
    &:after {
      top: -5px;
    }
  }
  >div:last-child {
    /*color:green;*/
    &:after {
      bottom: 50%;
      margin-bottom: 10px;
    }
  }

}
//}





.main-loan-content {
  display: flex;
  align-items: stretch;
  width:100%;
  > div {
    width: 50%;
    padding: 5px;
  }

  > div:first-child {
    border-right: 1px solid #dedede;
    padding-right:10px;
    display: flex;
  }

  > div:nth-child(2){
    padding-left:10px;
  }

  .loan-sliders-main {
    width: 100%;
    align-self: center;
    padding-right: 15px;

    .label-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight:bold;
    }

    .ui-slider-horizontal{
      .ui-slider-handle {
        width:30px;
        height:30px;
        top:-10px;
        cursor: pointer;
        @include border-radius(50%);

        &.ui-state-active {
          border: 1px solid #9FCF65;
          background: #0C933E;
        }
      }
    }

    .ui-slider {
      margin:20px 0px;
    }
  }

  .loan-list-values {
    > div {
      display: flex;
      width:100%;
      align-items:center;
      justify-content: space-between;
      max-width: 250px;
      margin-bottom: 5px;

      > div:nth-child(2){
        font-weight: bold;
      }
    }
  }

  .load-actions {
    margin-top:24px;
  }

  .sms-confirm-block {
    >div:first-child{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

  }
}

.extend-service-list-description > div {
  margin-top:20px;

  .eds-description {
    ul {
      margin-left: 10px;
      list-style: none;
      li:before {
        content: "— ";
      }
    }
  }
}

.extend-service-list {
  margin-top:20px;
}

.main-history-request {
  .extend-service-list {
    > div:not(:last-child) {
      margin-bottom:5px;
    }
  }
}


.service-list-links {
  > li {
    padding: 10px;
    &:before {
      content: "- ";
    }
    > a {
      text-decoration: underline;
      color:#444444;
    }
  }
}


.page-settings-main {
  .main-accordion {
    > div {

      border:1px solid #dcdcdc;

      .header-accordion {
        display:flex;
        cursor: pointer;
        justify-content: space-between;
        font-size:16px;
        padding:20px;
        background-color:#f9f9f9;
      }

      .content-accordion {
        padding:10px 20px;
      }
    }

    > div.accordion-close {
       .content-accordion {
        color:green;
        display:none;
      }
    }
  }
}


.main-loan-ready-content {
  display: flex;
  align-items: stretch;
  width:100%;
  > div {
    width: 50%;
    padding: 5px;
  }

  > div:first-child {
    border-right: 1px solid #dedede;
    padding-right:10px;
    display: flex;
  }

  > div:nth-child(2){
    padding-left:10px;
  }

  .loan-ready-list-params {
    > div {
      display: flex;
      width:100%;
      align-items:center;
      justify-content: space-between;
      /* max-width: 280px;*/
      margin-bottom: 5px;

      > div:nth-child(2){
        font-weight: bold;
      }
    }
  }

  .loan-ready-list-documents {
    > a {
      display:block;
      margin-bottom:5px;
    }
  }
}

.main-loan-ready-content[status="4"] {
  > div:first-child {
    border-right: 0;
    padding-right:0;
    width:50%;
    display: flex;
  }
}



.main-card-page {
  .payment-cards-list {
    .payment-card-item {
      margin-bottom:7px;
      > div {
        display: flex;
        align-items: center;
        justify-content:space-between;
        flex-wrap:wrap;

        .last-card-info {
          display: flex;
          align-items: center;
          flex-wrap:wrap;
          > div:not(:last-child) {
            margin-right:20px;
          }
          .card-item-status {
            width: 165px;
            text-align: right;
          }
        }
        .card-item-name {
          flex-grow:1;
          color:black;
        }

        .card-item-number {
          color:grey;
        }
      }
    }
  }
}


#mainConfirmModal {
  .grouped {
    &.fields {
      >label {
        display: block;
        margin-bottom:7px;
      }
    }
  }
}


.responsive-flex-2-side {
  display: flex;
  align-items: center;
  width:100%;
  > div {
    width: 50%;
  }
}


.main-page-limit-content {
  .graphic-plimit-main {
    margin-bottom: 20px;

    > label {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }
    > div {
      padding:10px;
    }

    .graphic-limit-self {
      position:relative;
      display:flex;
      .vertical-step {
        flex-direction:column;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
      }
      .graphic-self {
        display: flex;
        width: 100%;
        > div {
          width: 11%;
          height: 146px;
          background: #efefef;
          margin-right: 2px;
          position:relative;
          &:after {
            content: "";
            display: block;
            position:absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: #21ba45;
          }
        }
        @for $i from 1 through 5 {
          > div:nth-child(#{$i}){
            &:after {
              height:100% / $i;
            }
          }
        }

      }


    }

    &.other-g {
      .graphic-self {

        > div {

          &:after {
            background: #f9a234;
          }
        }
        @for $i from 1 through 5 {
          > div:nth-child(#{$i}){
            &:after {
              height:100% - ($i * 10);
            }
          }
        }
      }
    }
  }
}

.main-loan-current {
  .price-buttons {
    >div:not(:last-child) {
      margin-bottom: 10px;
    }
    >div {
      text-align:center;
    }
  }
}


.pre-create-request {
  #complete_sms{
    width: 170px;
  }
  #sendCodeMoreAgain {
    display: block;
    margin-top: 10px;
    font-size: 15px;
  }
  .agree-again-main {
    margin-top: 30px;
  }
  .main-pre-sms-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pre-create-request-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}


.pre-create-request-photo {
  > div {
    .main-pre-photo-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height:300px;
        > img {
          max-width: 200px;
        }
        .chat-action-photo {
          width: 100%;
          margin-top: 12px;

          .action-list {
            display: flex;
            align-items: center;
            justify-content: center;
            > a {
              flex-grow:1;
            }
          }
        }
    }
  }
}




.fl2-column {
  display: flex;
  flex-direction: column;
}

.w100 {
  width:100%;
}

.fl2-self-center {
  align-self: center;
}

.card-dropdown-main {
  margin-top:10px;
  > .ui.dropdown {
    margin-top: 5px;
  }
  .card-dropdown-item {
    display: flex;
    > i {
      margin-right:10px;
    }
    .card-item-number {
      font-size: 13px;
      color: grey;
    }
  }
}

.ui.form {
  .ldz-one {
    &.fields {
      display: flex;
      flex-direction: column !important;
      align-items: center;
      > .field:not(:last-child) {
        margin-bottom:15px;
      }
    }
  }
  .ldz-two {

    &.fields {
      display: flex;
      /*flex-direction: column !important;*/
      align-items: flex-start;
      > .field:not(:last-child) {
        margin-bottom:15px;
      }
    }
  }
}



.overflow-mobile-bg-menu {
  &.hide {
    display:none !important;
  }
}

.menu-button-fixed {
  display: none;
  margin-top: 18px;
  margin-left: 15px;
}


.main-chat-typing {
  display: flex;
  position: absolute;
 /* bottom:0;*/
  background: rgba(183, 183, 153, 0.56);
  left: 0;
  right: 0;
  padding: 2px 8px;
  color:#242949;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


@include max-screen(($break-medium-devices)-1px){
  .p-0-mobile {
    padding:0 !important;
  }
  #mainCabinet {
    .p-0-mobile {
      padding:0 !important;
    }

    >.container {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .continue-after-auth {
    .fl2box-medium {
      display: block !important;

    }
    .photo-continue-auth-steps {
      margin-bottom:30px;
    }


    .ui {
      &.big {
        &.buttons {
          flex-direction:column;
          .button {
            /*font-size: 1.0rem !important;*/
            margin: 5px;
          }
          > .or {
            display: none !important;
          }
        }
      }
    }
  }


  .cabinet-main-left-menu {
    position: absolute;
    top: -82px;
    left: -15px;
    z-index: 199;

    .card-profile-image {
      display: none !important;
    }

    .overflow-mobile-bg-menu {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.67);

      >.vertical.menu {
        max-width: 210px;
      }
    }

    .menu-button-fixed {
      display: block;
    }
    .top-credit-radar {
      display:none;
    }
  }

  .main-chat-block-container {
    padding:0px;
    .main-chat-block-messages {
      padding: 5px;
      padding-bottom: 13px;
      .chat-item-message {
        .chat-item-icon {
          width: 30px;
          min-width: 30px;
          height: 30px;
          line-height: 30px;
        }

        .chat-item-message-text {
          .ui.message {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > i {
              margin-right: 0px;
            }
          }
          img {
            max-width:100%;
          }
        }
      }
    }
  }

  #gridAuth {
    margin-top:10px;
    margin-bottom:10px;
  }

  .responsive-flex-2-side {
    display: block;
    align-items: center;
    width:100%;
    > div {
      width: 100%;
    }
  }

  .responsive-flex {
    display: block !important;
    width:100%;
    > div {
      width: 100%;
    }
    > a {
      display: block;
    }
    > a:not(:last-child) {
      margin-bottom: 6px;
    }
  }


  .main-loan-content {
    display: block;
    > div {
      width: 100%;
      padding: 5px;
      border-right:0px !important;
    }
  }

  .main-loan-ready-content {
    display: block;
    > div {
      width: 100% !important;
      padding: 5px;
      border-right:0px !important;
    }
    .price-buttons {
      .combinePriceButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        input {
          margin-bottom:10px;
          /*border-right-color: grey !important;*/
        }
      }
      .ui.action.input:not([class*="left action"])>input {
        border-right-color: #dddddd !important;
        text-align:center !important;
      }
      .ui.action.input:not([class*="left action"])>input:focus {
        border-right-color: #85b7d9 !important;
      }
    }
  }

  .h-mobile-hide {
    padding-left:0px !important;
    padding-right:0px !important;
  }

  .photo-continue-auth-steps {
    min-width: auto;
  }


  //.main-history-request {
  .request-history-item {
    >div.rhi-sub-data{
      margin-top:10px;
    }

    .request-actions {
      .request-action[request-status="2"]{
        display: none !important;
      }
      .request-action[request-status="3"]{
        display: none !important;
      }
    }
  }
  // }

  .credit-limit-main {
    >div{
      margin-bottom:15px;
    }
    > div:nth-child(3){
      text-align: center;
      display: block;
      margin: 0 auto;
    }
  }

  .main-card-page {
    .payment-cards-list {
      .payment-card-item {
        > div {
          display: block;
          .last-card-info {
            display: block;
            margin-top: 5px;
            > div:not(:last-child) {
              margin-right:0px;
            }
            .card-item-status {
              width: auto;
              text-align: left;
              margin:5px 0px;
            }
          }
        }
      }
    }
  }
}


.page-content__inner {
  margin-bottom:10px;
}

/**---------------------------------- REGISTRATION --------------------------------------*/

#ui-pod-ft-agreement {
  &.ui{
    &.modal{
      >.content {
        >ul {
          list-style-type: disc;
          text-align: justify;
          >li {
            margin-left: 20px;

          }
        }
      }
    }
  }
}


#app-register {
  position: relative;
  .main-steps-ui {
    position: relative;
    > div.ui-reg-self-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index:-1;
      opacity: 0;


      video {
        max-width:100%;
        max-height:100%;
        z-index:0;
        opacity: 1;
      }

      canvas {
        max-width:100%;
        max-height:100%;
        z-index:0;
        opacity: 1;
      }
    }
    > div.ui-tabs-pane {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background:#f0f4f5;
    }
    > div:last-child{
      /* opacity: 0.6;*/
      z-index:2;
    }

    .register-send-sms-form,
    .register-send-exist-document-form,
    .register-send-complete-form {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;

      > div:first-child {
        flex-grow:1;
        width: 100%;
        display: flex;
        display: block;
        width: 100%;
        align-items: center;
        justify-content: center;
        > div {

        }
      }
    }
    .register-send-sms-form .ui.form {
      margin-top: 30px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      .field {
        >label {
          text-align: left;
        }
      }

      #complete_sms {
        width: 170px;
      }
    }
    #sendCodeMoreAgain {
      display: block;
      margin-top: 10px;
      font-size: 15px;
    }
    .timer-left-registration {
      margin-top:20px;
    }

    .address-error {
      display: inline-block;
      color: #da520c;
      padding-left: 20px;
      margin-top: 3px;
    }

    .address-success {
      display: inline-block;
      color: #9cba64;
      padding-left: 20px;
      margin-top: 3px;
    }

    .register-extend-service-main {
      margin-top:20px;
      padding-left:25px;
      > a {
        color: #333333;
        font-size: 15px;
      }

      div.reg-content-label {
        font-size: 1rem;
        line-height: 24px;
      }
    }

  }
}

.cabinet-main-left-menu .ui.card > .content {
  border:0 !important;
}

@-moz-document url-prefix() {
  #app-register {
    font-family: Roboto !important;
    .ui {
      &.steps {
        .step {
          .title {
            font-family: Roboto !important;
          }
        }
      }
      &.button {
        font-family: Roboto !important;
      }
    }
  }
}

.hide {
  display: none !important;
}
.main-show-activate-video {
  position: fixed;
  z-index: 9999999;
  left:0;
  right:0;
  bottom: 0;
  height: 100vh;


  > div:first-child {
    background: rgba(0, 0, 0, 0.74);
    height: 60vh;
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 45px;
    padding-right: 100px;
    font-size: 70px;
  }

  > div:last-child {
    background: white;
    flex-direction: column;
    height: 40vh;
    display:flex;
    align-items:center;
    justify-content: center;
    padding: 10px;
    .buttons-after {
      margin-top:20px;
    }

    .text-with-icons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      img {
        margin: 0px 5px;
      }
    }
  }
}


@include max-screen(($break-medium-devices)-1px){
  #app-register {
    padding-left: 2px;
    padding-right: 2px;
  }
  .main-show-activate-video {
    > div:first-child {
      height: 0vh;
      display: none;
      font-size: 45px;
    }
    > div:last-child {
      height: 100vh;
      text-align:center;
      .buttons-after {
        > a {
          display:block;
        }
        > a:not(:last-child){
          margin-bottom: 10px;
        }
      }
      .text-with-icons {
        display: block;
      }
    }
  }
}


