﻿@import "paranoida-mediaqueries/media-queries";

@mixin align-center(){
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@mixin abs-ctr($horizontal: true, $vertical: true) {
    position: absolute;

    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

@mixin pscen($size, $horizontal: true, $vertical: true) {
    display: block;
    width: $size;
    height: $size;

    position: absolute;


    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;

        margin-top: -$size / 2;
        margin-left: -$size / 2;
    }

    @else if ($horizontal) {
        left: 50%;
        margin-left: -$size / 2;
    }

    @else if ($vertical) {
        top: 50%;
        margin-top: -$size / 2;
    }
}

@mixin psclear() {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
    margin: inherit;
}

@mixin font($fz: null, $lh: null, $fc: null, $fw: null, $fs: null,  $ff: null) {
  font-style:     $fs;
  font-weight:    $fw;
  font-size:      $fz;
  line-height:    $lh;
  font-family:    $ff;
  color:          $fc;
}

@mixin carret($size: 7px, $rotate: -45deg, $bg: #fff, $brd: #4c4c4c, $pt: 0, $pl: 0, $pr: null, $pb: null, $mt: null, $ml: null){
    display:        block;
    width:          $size;
    height:         $size;
    position:       absolute;
    top:            $pt;
    left:           $pl;
    right:          $pr;
    bottom:         $pb;
    transform:      rotate($rotate);
    background:     $bg;
    border-left:    1px solid $brd;
    border-bottom:  1px solid $brd;
    margin-top:     $mt;
    margin-left:    $ml;
    -webkit-backface-visibility: hidden;
}

@mixin block($w: true, $h: true) {
    @if ($w and $h) {
        width: $w;
        height: $h;
    } @else if ($w) {
        width: $w;
    } @else if ($h) {
        height: $h;
    }
}

@mixin link-def($mc: $link-color, $hc: $link-color, $bdc: $link-color, $sz: 100% 1px, $pos: 0 95% ) {
    color: $mc;

    &:hover,
    &:active,
    &:focus {
        color: $hc;
        background: linear-gradient(to left, $bdc, $bdc 100%);
        background-size: $sz;
        background-repeat: repeat-x;
        background-position: $pos;
    }
}

@mixin link-event($lc: $base-lac, $rc: $base-lac, $sz: 2px 1px, $pos: 0 95% ) {
    background: linear-gradient(to left, $base-lac, $base-lac 60%, transparent 60%);
    background-size: $sz;
    background-repeat: repeat-x;
    background-position: $pos;
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    margin-right: -8px;
    padding-right: 8px;
    word-break: break-all;


    &::before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
        transition: color $transition;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}

@mixin text-overflow($w: 20px) {
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  &::after {
    content: "";

    display: block;
    width: $w;
    height: 100%;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%);
  }
}

@mixin font-face(
    $font-family,
    $file-path,
    $weight: normal,
    $style: normal,
    $asset-pipeline: $asset-pipeline,
    $file-formats: eot woff ttf svg) {

    $font-url-prefix: font-url-prefixer($asset-pipeline);

    @font-face {
        font-family: $font-family;
        font-style: $style;
        font-weight: $weight;

        src: font-source-declaration(
                        $font-family,
                        $file-path,
                        $asset-pipeline,
                        $file-formats,
                        $font-url-prefix
        );
    }
}

@function font-url-prefixer($asset-pipeline) {
    @if $asset-pipeline == true {
        @return font-url;
    } @else {
        @return url;
    }
}

@function font-source-declaration(
    $font-family,
    $file-path,
    $asset-pipeline,
    $file-formats,
    $font-url) {

    $src: ();

    $formats-map: (
            eot:   "#{$file-path}.eot?#iefix" format("embedded-opentype"),
            woff2: "#{$file-path}.woff2" format("woff2"),
            woff:  "#{$file-path}.woff" format("woff"),
            ttf:   "#{$file-path}.ttf" format("truetype"),
            svg:   "#{$file-path}.svg##{$font-family}" format("svg")
    );

    @each $key, $values in $formats-map {
        @if contains($file-formats, $key) {
            $file-path: nth($values, 1);
            $font-format: nth($values, 2);

            @if $asset-pipeline == true {
                $src: append($src, font-url($file-path) $font-format, comma);
            } @else {
                $src: append($src, url($file-path) $font-format, comma);
            }
        }
    }

    @return $src;
}


@mixin placeholder {
    $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
}

@mixin link-w-border($color) {
  position: relative;
  color: $color;

  &::after {
    content: "";
    display: block;
    width: 100%;

    position: absolute;
    left: 0;
    bottom: -5px;

    border-bottom: 2px solid $color;
    opacity: .5;
  }

  &:hover {
    &::after {
      display: block;
    }
  }

  //.no-touchevents &::after {
  //  display: none;
  //}
  //.no-touchevents &:hover::after {
  //  display: block;
  //}
}


@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}