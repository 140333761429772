.table {
  width: 100%;
  margin-bottom: 51px;

  &__row {
     cursor: pointer;
    &.line {
      .table__col {
        height: 6px;
        padding: 0;
        border-top: 1px solid #f2f2f2;
        background-color: transparent;
        cursor: default;
      }
    }

    &.ts-row-fixed {
      position: fixed;
      visibility: hidden;
      z-index: 1020;
      background-color: #f2f2f2;
    }

    &.ts-row-section,
    &.ts-row-fixed {
       .table__col {
         background-color: #f2f2f2;
       }

      b {
        margin-right: 30px;
      }

      span {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    &:not(.line):not(.sticky-header):hover {
      .table__col {
        background-color: #ffefd4;
      }
    }

    &--personal-info {
      margin-bottom: 18px;
    }

  }

  &__title {
    padding-top: 15px;
    padding-bottom: 15px;


    text-transform: uppercase;
    text-align: left;

    .sort,
    a {
      color: #3d4343;
      text-decoration: none;

      &.asc {
        &::after {


          display: inline-block;

          content: "";

          position: relative;
          top: inherit;
          left: inherit;

          margin-left: 8px;
          vertical-align: middle;
        }
      }
      &.desc {
        &::after {


          display: inline-block;

          content: "";

          position: relative;
          top: inherit;
          left: inherit;

          margin-left: 8px;
          vertical-align: middle;
        }
      }
    }

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }
  }

  &__col,
  &__title {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__col {
    padding-top: 10px;
    padding-bottom: 10px;


    transition: background-color $transition;
    min-width: 100px;

    &:first-child {
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
    }

    &:last-child {
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }

  &--fitted {

    td {
      box-sizing: border-box;
    }

    .table__row:not(.line):not(.sticky-header):hover {
      .table__col {
        background-color: #ffefd4 !important;
        cursor: pointer;
      }
    }
  }


  .id {
    width: 50px;
  }

  .tag {
    margin-left: -10px;

    &.right-ind {
      margin-left: 3px;
    }
  }

  /* Поиск для таблицы */
  &-search {}
}

.table--adaptive {

  .thead {
    display: none;
  }

  .table__row {
    display: block;
    background-color: #fff;
    margin-top: 20px;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
    padding: 10px 0;
  }

  .table__row.disabled {
    color: $disabled;
  }

  .table__row:not(.line):not(.sticky-header):hover .table__col {
    background: none;
  }

  .table__col {
    display: block;
    text-align: right;
    font-size: 13px;
    border: 0;
    width: 100%;
  }

  .table__col:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    display: block;

  }
}

@include min-screen($break-medium-devices) {
  .table--adaptive {

    .thead {
      display: table-header-group;
      border-bottom: 1px solid #f0f0f0;
    }

    .table__row {
      display: table-row;
      box-shadow: none;

    }

    .table__col {
      display: table-cell;
      text-align: left;
      width: auto;
      font-size: 15px;

      &.date {
        font-size: 13px;
        color: $disabled;
      }
    }

    .table__col, .table__title {
      padding-left: 15px;
      padding-right: 15px;
    }

    .table__col:before {
      display: none;
    }

    .table__row:not(.line):not(.sticky-header):hover .table__col {
     background: #ffefd4;
    }
  }
}

@include min-screen($break-large-devices) {

  .table--adaptive {
    .table__col {

      &.date {
        font-size: 15px;
        color: inherit;
      }
    }

    .table__col, .table__title {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}