.card {
  &--news {
    margin-bottom: 48px;

    .card__image {
      display: block;
      width: 290px;
      height: 150px;
    }
    .card__caption {
      display: inline-block;
      vertical-align: top;

      margin-top: 4px;
      padding-left: 18px;

      text-decoration: none;
      color: #181819;

      position: relative;

      transition: color $transition;

      &:hover {
        color: $major-color;

        .card__caption-link {
          border-color: $major-color;;
        }
      }
      &::before {
        content: "";

        position: absolute;
        top: 8px;
        left: 4px;

        display: block;
        width: 5px;
        height: 5px;
        background-color: $major-color;

        border-radius: 50%;
      }
    }
    .card__caption-link {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      border-bottom: 1px dashed #000;

      transition: border-color $transition;
    }
    .card__pubdate {
      margin-top: 8px;
      padding-left: 4px;

      font-size: 11px;
      font-weight: 500;
      font-style: italic;
    }
  }
}
@include min-screen($break-medium-devices){
  .card {
    &--news {
      margin-bottom: 68px;

      .card__image {
        width: 328px;
        height: 150px;
      }
    }
  }
}
@include min-screen($break-large-devices){
  .card {
    &--news {
      .card__image {
        width: 300px;
        height: 200px;
      }
    }
  }
}
@include min-screen($break-extra-large-devices){
  .card {
    &--news {
      .card__image {
        width: 100%;
      }
    }
  }
}


@include min-screen($break-medium-devices) {
  .cards {
    &--news {
      margin-left: -15px;
      margin-right: -15px;
      font-size: 0;

      .card--news {
        display: inline-block;
        vertical-align: top;

        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
@include min-screen($break-large-devices) {
  .cards {
    &--news {
      .card--news {
        width: 33.3333%;
      }
    }
  }
}