.main--cabinet {
  //background: url('../images/main-bg.jpg');
}

.main-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;

  &__content {
    flex-basis: 100%;
    margin-top: -50px;
    position: relative;
    z-index: 15;
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    min-width: 280px;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    background: #fff;
    box-shadow: -5px 0 20px rgba(0,0,0,0.13);
    z-index: 10;
    padding-bottom: 50px;
    position: relative;

  }
}
@include min-screen($break-medium-devices) {
  .main-inner {

    &__content {
      flex-basis: 100%;
      margin-top: 0;
      background: #fff;
      margin-top: 0;
      box-shadow: none;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.13);

    }

    &__header {
      padding-bottom: 0;
    }
  }
}
@include min-screen($break-large-devices) {
 .main-wrap {
    padding-top: 0;
  }
}
@include min-screen($break-extra-large-devices) {
  .main-inner {
    flex-wrap: nowrap;

    &__content {
      flex-basis: 920px;
      box-shadow: 0 0 20px rgba(0,0,0,0.13);

    }

    &__header {
      flex-basis: 280px;
      box-shadow: -5px 0 20px rgba(0,0,0,0.13);



    }
  }
}


.user {
  display: flex;
  margin: 27px 20px 19px;
  position: relative;

  &__name {
    font-weight: $bold;
    margin-left: 15px;
    word-break: break-all;
  }

  &__firstname {
    font-size: 21px;
  }

  &__pic {
    width: 85px;
    height: 85px;

  }

  &__num {
    position: absolute;
    bottom: 0;
    left: 100px;
    font-size: 12px;
    font-weight: $regular;
    line-height: 1;
  }
}
.add-loan {
  width: 100%;
  min-width: 280px;
  text-align: center;
  padding: 22px;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;

  &__caption {
    color: #a9b7b7;
    font-size: 12px;
    line-height: 1;
    font-style: italic;
    letter-spacing: -.3px;
  }

  &__button {
    display: block;
    padding: 20px 25px 20px 25px;
    color: #fff;
    font-family: $DINPro, sans-serif;
    font-size: 16px;
    font-weight: $bold;
    border-radius: 10px;
    background-color: $button-major_backg;
    position: relative;
    transition: .3s;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 2px 3px 5px 0 rgba(0,0,0,.21);

    &:hover, &:active, &:focus {
      color: #fff;
      background-color: $major-color;
    }

    &--disabled {
      cursor: default;
      background-color: #d6d6d6;
      box-shadow: 0 0 0 1px #d6d6d6;
      color: #fff;

      &:hover, &:active, &:focus {
        background-color: #d6d6d6;
      }
    }
  }
}
@include min-screen($break-medium-devices) {
 .add-loan {
    width: auto;
  }
}

/* --------------------------секция займ------------------------- */
.loan {
  &__wrap {
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
    margin-top: 20px;
  }

  &__backlink {
    display: inline-block;

  }

  &__num{
    font-size: 24px;
    font-weight: $regular;
    margin: 15px 0 10px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__docs {
    flex-basis: 100%;
  }

  &__info {
    flex-basis: 100%;
  }

  & .panel {
    background: #f2f2f2;
    padding: 25px;
    border-radius: 5px;
    width: 260px;

    .panel__title {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .panel__file-list {
      margin-bottom: 15px;
    }
  }

  .docs-link {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    font-size: 14px;
    line-height: 30px;
  }

  .tag {
    letter-spacing: -0.7px;
  }
}
.info-list {
  &__item {
    font-size: 0;
    margin-top: 14px;


    &.is-large-indent {
      margin-top: 33px;
    }

    &.is-no-indent {
      margin-top: 0;
    }
  }

  &__column {
    display: inline-block;
    vertical-align: middle;

    $width: 45%;

    &.short {
      width: $width;
    }
    &.long {
      width: 55%;
      }

    &.is-left-margin {
      margin-left: 24px;
    }

    &.is-single {
      margin-left: $width;
    }

    &.valign-top {
      vertical-align: top;
    }
    &.valign-bottom {
      vertical-align: bottom;
    }
  }

  &__name, &__value {
    font-size: 14px;
  }

  &__button {
    margin: 30px 0 40px;
  }

}
@include min-screen($break-medium-devices) {
  .loan {
    &__wrap {
      padding: 0;
      box-shadow: none;
      margin-top: 0;
    }

    &__num {
      display: inline-block;
      margin-right: 20px;
      vertical-align: middle;
    }

    &__status {
      font-size: 15px;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__docs {
      flex-basis: 45%;
    }

    &__info {
      flex-basis: 45%;
    }

    & .panel {
      width: 300px;
    }

    .docs-link {
      width: 250px;
      font-size: 15px;
    }

    .tag {
    letter-spacing: normal;
    }
  }
}


/* ------------------------ секция анкета ---------------------------------*/
.anketa {
    font-size: 0;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
    margin-top: 20px;


  .h3 {
    font-size: 21px;
    font-weight: $regular;
    padding: 0;
    margin: 0;
  }

  &__block {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  &__block:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .info-list__item {
    margin-bottom: 25px;
  }

  .info-list__name {
    font-size: 14px;
  }

  .info-list__value {
    font-size: 17px;
  }
}
@include min-screen($break-medium-devices) {
  .anketa {
    padding: 30px;
    box-shadow: none;
    margin-top: 0;

    .info-list__item.md {
      padding-right: 20px;
      width: calc((100% / 2) - 20px);
      display: inline-block;
    }

    .info-list__item.sm {
      padding-right: 20px;
      width: calc((100% / 3) - 20px);
      display: inline-block;
    }
  }
}
@include min-screen($break-large-devices) {
  .anketa {

    .info-list__item.md {
      width: calc((100% / 3) - 20px);
      display: inline-block;
    }
  }
}


/* ----------------------------секция документы -----------------------------*/
.docs-table {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
  margin-top: 20px;
  .info-list {
    &__item {
    margin-bottom: 20px;
    }

    &__column {
    width: 50%;
    }

    &__column.short {
    width: 100%;
    margin-bottom: 10px;
    }

    &__name {
      width: 220px;
    }

    &__value .file {
    display: block;
    white-space: nowrap;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    }

    &__column.loan-date {
    display: none;
    }

   &__button {
    margin: 20px 0 10px;
    }
  }


}
@include min-screen($break-medium-devices) {
  .docs-table {
    padding: 30px;
    box-shadow: none;
    margin-top: 0;
      .info-list {
        &__name {
        width: 100%;
      }
    }
  }
}
@include min-screen($break-large-devices) {

  .docs-table {


    .info-list__item {
      border-radius: 5px;
      padding: 15px;
      transition: .3s;
      margin-bottom: 0;
      &:hover {
        background-color: #fff0d3;
      }
    }

    .info-list__column.short {
      width: 50%;
      margin-bottom: 0;
    }

    .info-list__column {
      width: calc((100% - 50%) / 3);
    }

    .info-list__column.loan-date {
      display: inline-block;
    }

  }

  .loan {
     & .panel {
      width: 400px;
    }

    .docs-link {
      width: 360px;
    }
  }
}

.button--repay-loan {
  margin-top: 51px;
}

.loan__payments {
  margin-top: 51px;
}