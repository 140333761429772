@keyframes zoom-in-out {
  0% {
    transform: translateX(-50%) scale3d(1,1,1);
  }
  50% {
    transform: translateX(-50%) scale3d(1.1,1.1,1.1);
  }
  100% {
     transform: translateX(-50%) scale3d(1,1,1);
   }
}