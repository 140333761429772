body .modal:not(.ui) {
  position: relative;

  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;

  background-color: #fff;
  border-radius: 6px;

  .modal__close.mfp-close {
    width: 32px;
    height: 32px;
    margin: 8px;

    background-image: url('#{$image}/svg/times-o.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .modal___header {
    padding-top: 29px;
    padding-bottom: 12px;

    text-align: center;
  }
  .modal__body,
  .modal__header,
  .modal__footer {
    padding: 16px 32px;
  }
  .modal__body {
    text-align: left;
  }
  .modal__footer {
    padding-top: 20px;
    padding-bottom: 0;

    border-top: 1px solid #ededed;
    text-align: left;
  }
  .modal__title {
    font-size: 24px;
    line-height: 1;
    text-align: left;
  }


  &--medium {
    width: 95%;
    border-radius: 5px;
    padding: 30px;
  }

  &__preloader {
    display: none;
    text-align: center;
  }

  &__countdown {
    margin-bottom: 15px;
    text-align: center;
  }

  &--whyLeave {
    .form__label {
      width: 75px;
    }
    .form__control {
      width: 310px;
    }

    .row {
      margin-bottom: 20px;
    }
  }

  &--pod-ft-agreement {
    width: 60%;

    ul {
      list-style-type: disc;
      text-align: justify;

      li {
        margin-left: 20px;
      }
    }
  }
}
@include min-screen($break-medium-devices) {
  body .modal:not(.ui) {
    width: 480px;

    &--pod-ft-agreement {
      width: 60%;
    }
  }
}


.contract {
  .modal__title {
    font-size: 24px;
    margin-bottom: 70px;
  }
  .modal__text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }
  .modal__form {
    text-align: left;
    margin: 0 auto;
    width: 70%;
    margin-bottom: 70px;
  }
  .modal__input {
    border-radius: 0;
    border: 1px solid #abaaaa;
    padding: 20px;
    background-color: #f7f7f7;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  .modal__link {
    /* display: inline-block; */
    color: rgb(61, 67, 67);
    line-height: 1.2;
    border-bottom: 1px solid;
    cursor: pointer;
  }
  .modal__buttons {
    .button {
      margin: 0 10px 10px;
    }
  }
}
@include min-screen($break-medium-devices) {
  .contract {
    width: 685px;
  }
}
