.contacts {
  margin-bottom: -27px;
  line-height: 1.5;

  &__title {
    margin: 0;

    font-size: 30px;
    line-height: 1.2;
    font-weight: $bold;
  }
  &__list {
    font-size: 24px;
    float: left;
  }
  &__item {
    padding-top: 13px;
    padding-bottom: 13px;

    &--phones {
      border-bottom: 1px solid #d0d0d0;
    }
    &--email {
      font-size: 13px;
      padding-left: 20px;

      background-image: url('#{$image}/png/letter.png');
      background-repeat: no-repeat;
      background-position: 0 50%;
    }
  }
  &__body {
    position: relative;
    margin-top: 25px;
  }
  &__map {
    width: 100%;
    height: 300px;
  }
}
@include min-screen($break-medium-devices){
  .contacts {
    position: relative;

    &__map-filter {
      position: absolute;
      top: 25px;
      left: 50%;
      z-index: $z-under;

      margin-left: -362px;
    }
    &__map {
      width: 100%;
      height: 490px;
    }
  }
}
@include min-screen($break-large-devices){
  .contacts {
    &__map-filter {
      margin-left: -475px;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .contacts {
    &__map-filter {
      margin-left: -575px;
    }
  }
}