.hint {
  font-family: $font-family;

  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 10;

    width: 22px;
    height: 22px;

    overflow: hidden;
    cursor: pointer;

    &::before,
    &::after {
      content: ' ';

      position: absolute;
      top: 50%;
      left: 0;

      width: 100%;
      height: 2px;

      margin-top: -1px;
      background: #868686;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  &__inner {
    padding: 19px 20px 19px 40px;
  }
  &__title {
    font-size: 16px;
    line-height: 16px;
    color: #4caf50;
  }
  &__header {
    padding-bottom: 8px;
  }
  &__footer {
    margin-top: 10px;
  }
  &__body {
    padding-top: 1px;
    padding-bottom: 1px;

    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #b5b7b9;
  }
  &__text {
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 0;

    strong {
      color: #51575b;
    }
  }


  &--static {
    display: none;
    position: fixed;
    z-index: $z-hint-static;
  }

  &--online {
    left: 50px;
    bottom: 50px;

    width: 279px;

    background-color: #fff;
    border: 1px solid #b9b9b9;


    .hint__button {
      font-family: $font-family;
      background-color: #6abe54;
      padding-left: 7px;
      padding-right: 7px;
    }
  }
}
@include min-screen($break-large-devices){
  .hint {
    &--static {
      display: block;
    }
  }
}