.reg-main {
  font-family: $Roboto;

  .h1 {
    margin-bottom: 35px;

    font-size: 24px;
    line-height: 1.2;
    font-weight: 200;
    font-family: inherit;
  }
  .h3 {
    padding-bottom: 12px;
    margin-bottom: 14px;

    font-size: 21px;
    font-weight: $bold;
    font-family: inherit;
  }

  .h3.reg-finish-text {
    font-size: 18px;

  }

  .h4 {
    margin-top: 30px;

    font-size: 18px;
    font-weight: bold;
    font-family: inherit;
  }

  .link {
    border-bottom: 1px solid;
    color: inherit;
  }

  .m-lg-right {
    margin-right: 25px;
  }

}

.reg-form {
  & .selectize-dropdown, .selectize-input, .selectize-input input {
    font-size: 14px;
  }
}
@include min-screen($break-medium-devices) {
  .reg-main {
   /* padding-bottom: 100px;*/

    .h1 {
      font-size: 36px;
    }

    .h3 {
      font-size: 24px;
    }

    .h3.reg-finish-text {
      font-size: 20px;
      margin: 130px 0 30px;
    }


    .h4 {
      font-size: 19px;

    }

    .m-lg-right {
      margin-right: 50px;
    }
  }
}
@include min-screen($break-large-devices) {
 .reg-main {
    padding-top: 24px;
    /*padding-bottom: 100px;*/

    .h1 {
      font-size: 48px;
    }
  }
}




/*-----------------progress bar -----------------------*/

.progressbar {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}
#progress {
  height: 40px;
  border-radius: 10px;
  background-color: #a3afb8;
  color: #fff;
  width: 100%;
  border: 0;
  overflow: hidden;
  position: relative;
  background: -moz-linear-gradient(right, #ffc53a, #2bc32e);
  background: -webkit-linear-gradient(right, #ffc53a, #2bc32e);
  background: linear-gradient(to left, #ffc53a, #2bc32e);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
#progress::-webkit-progress-bar {
  height: 40px;
  background-color: #a3afb8;
  background: -moz-linear-gradient(right, #ffc53a, #2bc32e);
  background: -webkit-linear-gradient(right, #ffc53a, #2bc32e);
  background: linear-gradient(to left, #ffc53a, #2bc32e);
}
#progress::-webkit-progress-value {
  height: 40px;
  background-color: #a3afb8;
}
#progress::-moz-progress-bar {
  height: 40px;
  background-color: #a3afb8;
}
//#progress::-moz-progress-value {
//  height: 40px;
//  background-color: #527dc6;
//}
.progress-message {
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  color: #fff;
}

@include min-screen($break-medium-devices) {
  .progress-message {
    left: 35%;
    transform: none;
  }
}

@include min-screen($break-large-devices) {
  .progressbar {
    width: 70%;
  }
}


.active:not(.ui.modal) {
  color: #eb6734;

  text-decoration: none;
  cursor: default;
}

.wizard {
  overflow: visible;
}



.wizard > .content {
  overflow: visible;
  margin: 0;
  min-height: auto;
}

.wizard > .content > .body {
  position: static;
  width: 100%;
  padding: 0 2px;

}

.wizard > .steps > ul {
  line-height: 3;
  overflow: auto;
  display: table;
  width: 70%;
  margin: 0 auto;
}

.wizard > .steps > ul > li {
  width: auto;
  display: table-cell;
  float: none;
}

.wizard > .content > .body input.error {
  border: 1px solid #f12309;
  color: #8a1f11;
  background-color: #ffffff;
}

.wizard > .content > .body label.error {
  margin-left: 0;
  color: #f12309;
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
  padding: 0;
}

.wizard > .steps .number {
  display: none;
}

.wizard > .steps .current a {
  background: none;
  color: #077492;
}

.wizard > .steps .current a:hover, .wizard > .steps .current a:active {
  color: #077492;
  background: none;
}

.wizard > .steps .disabled a {
  background: none;
  color: inherit;
}

.wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
  color: inherit;
  background: none;
}

.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
  color: #9d9c9c;
  background: none;
}

.wizard > .content {
  background: none;
}

.wizard fieldset {
  border: none;
  padding: 0 2px;
}

.wizard > .actions {
  margin-top: 30px;

}

.wizard > .actions a {
 background: #1ea8e0;
 transition: background-color $transition;
 height: 45px;
 text-align: center;
 line-height: 45px;
 padding: 0;
 text-transform: uppercase;
}

.wizard > .actions a:hover {
  background-color: #2184be;
  height: 45px;
  text-align: center;
  line-height: 45px;
  padding: 0;
  text-transform: uppercase;
}

.wizard > .actions a:active {
  background-color: #2184be;
}

.wizard > .actions a.btn-minor {
  background: #fff;
  color: inherit;
}

.wizard > .actions a.btn-minor:hover, .wizard > .actions a.btn-minor:active {
  background-color: $button-minor_back-hover;
}

.wizard > .steps {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;

}

.wizard > .actions a[href="#previous"] {
  background: #fff;
  color: inherit;
  border: 1px solid #d6d6d6;
}



.wizard > .actions .disabled a[href="#previous"] {
  display: none;
}

.wizard > .actions > ul {
  width: 100%;
}

.wizard > .actions > ul > li {
  margin: 0;
  width: 47%;
  float: none;
  display: inline-block;
}

.wizard > .actions > ul > li:first-child {
  margin-right: 4%;
}

.wizard > .actions a.disabled {
  //background-1color: #9b9b9b;
  opacity: 0.5;
}


/*----------form error tooltip--------------*/

input.error {
  position: relative;
  margin-left: 0;
}

label.error {
  width: auto;
  font-size: 13px;
  line-height: 1.5;
  display: none;
  color: #fb9e8a;
}

.ui-checkboxes__controll label.error {
  bottom: 100%;
}

.ui-checkboxes__controll input.error {
  position: absolute;
}


@include min-screen($break-small-devices) {
  .wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active{
    width: 140px;
  }

  .wizard > .actions > ul > li {
    width: auto;
  }

  .wizard > .actions > ul > li:first-child {
  margin-right: 10px;
  }
}

.wizard > .content > .body > .dop-contacts-phone label {
  display: none;
}

.sms-code > .actions {
  display: none;
}




.form-group {
    position: relative;
    &__inner {
      position: relative;
    }
  }



.num-short {
    display: none;
  }

.num-long {
  font-weight: 700;
  margin-bottom: 10px;
}

.photo-wrap {
  border: 1px dashed #dadada;
  text-align: center;
 }




@include min-screen($break-medium-devices) {


.dop-contacts {

  input[type="text"]::-webkit-input-placeholder, input[type="tel"]::-webkit-input-placeholder {
    color: transparent;
  }

  input[type="text"]:-moz-placeholder, input[type="tel"]:-moz-placeholder { /* Firefox 18- */
    color: transparent;
  }

  input[type="text"]::-moz-placeholder, input[type="tel"]::-moz-placeholder  { /* Firefox 19+ */
    color: transparent;
  }

  input[type="text"]:-ms-input-placeholder, input[type="tel"]:-ms-input-placeholder { /* IE 10+ */
    color: transparent;
  }

  input[type="text"]::-ms-input-placeholder, input[type="tel"]::-ms-input-placeholder { /* Edge */
    color: transparent;
  }


  .dop-contacts-phone label {
    display: inline-block;
  }

  .dop-contacts-phone .selectize-input input {
    opacity: 0;
  }

  .form-group {
    position: relative;
  }

  .num-short {
    display: block;
    color: #b1b1b1;
    position: absolute;
    left: -10px; bottom: 10px;
 }

 .num-long {
  display: none;
 }

 .photo-wrap {
  border: 1px dashed #dadada;
 }


}

.wizard.more-info > .content > .body {
  padding: 3.5%;
}

.wizard > .content > .body > .dop-contacts-phone label {
  display: inline-block;
}

}

.more-info {
  .steps {
    display: none;
  }
}



.more-info {
  .client-photo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &__col {
      flex-basis: 100%;
      text-align: center;

      &:first-child {
        margin-bottom: 10px;
      }
    }
    &__wrap {
      border: 1px dashed #dadada;
      text-align: center;
      width: 210px;
      height: 210px;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__add {
      background: #e8e8e8 url("../images/svg/camera.svg") no-repeat;
      background-size: 55px 45px;
      background-position: 50% 30%;

    }

    &__btn {
      position: absolute;
      left: 50%;
      bottom: 25px;
      z-index: 10;
      transform: translateX(-50%);
      text-transform: uppercase;
      width: 166px;
      padding: 0;
      text-align: center;
    }

    & span {
      position: absolute;
      left: 50%;
      bottom: 20px;
      z-index: 10;
      transform: translateX(-50%);
    }

    &__template {
      width: 100px;
      margin: 20px 0 10px;
    }
  }
}

@include min-screen($break-medium-devices) {
  .more-info {
    .client-photo {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &__col {
        flex-basis: 320px;
        &:first-child {
        margin-bottom: 0;
        margin-right: 20px;
        }
      }

      &__wrap {
      width: 320px;
      height: 320px;
      }

      &__template {
      width: 170px;
      margin: 30px 0 30px;
      }

      &__add {
      background-size: 80px 65px;
      background-position: 50% 40%;
    }

    }
  }
}


/*----------------------------------*/

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  cursor: default;
  position: relative;
  width: 100%;
  height: 37px;
  border: 1px solid #dadada;
  resize: none;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #3d4343;
  border-radius: 5px;
  box-sizing: border-box;
}

.inputfile + label span {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
  height: 100%;
  line-height: 37px;
  padding: 0 0 0 150px;
  box-sizing: border-box;
  cursor: default;
}

.inputfile + label strong {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  width: 140px;
  height: 100%;
  color: #fff;
  background: #1ea8e0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 37px;
  cursor: pointer;
}


.reg-form .ui-select.single .selectize-input {
  height: 37px;
  padding: 0;
  padding-left: 10px;
  line-height: 37px;
}