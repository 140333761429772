.content-steps {
  background-color: #f0f3f0;

  &__list {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  &__item {
    width: 290px;
    height: 95px;

    @extend .align-center;

    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;

    &--first {
      background-image: url('#{$image}/steps/01-xs.png');

      .content-steps__caption {
        padding-top: 18px;
      }
    }
    &--second {
      margin-top: -22px;
      background-image: url('#{$image}/steps/02-xs.png');
    }
    &--third {
      margin-top: -11px;
      background-image: url('#{$image}/steps/03-xs.png');
    }
  }
  &__caption {
    padding: 31px 18px 18px 97px;
    @include font(13px, 1.4, null, $medium, null, $DINPro);
  }
}
@include min-screen($break-medium-devices){
  .content-steps {
    &__list {
      padding-top: 12px;
      padding-bottom: 12px;
      overflow: hidden;
    }
    &__item {
      height: 91px;
      float: left;
      background-size: 100% 100%;

      &--first {
        width: 250px;
        background-image: url('#{$image}/steps/01-sm.png');

        .content-steps__caption {
          padding-top: 28px;
          padding-left: 64px;
        }
      }
      &--second {
        width: 266px;
        margin-top: 0;
        margin-left: -32px;
        background-image: url('#{$image}/steps/02-sm.png');
      }
      &--third {
        width: 265px;
        margin-top: 0;
        margin-left: -32px;
        background-image: url('#{$image}/steps/03-sm.png');
      }
    }
    &__caption {
      padding-top: 28px;
    }
  }
}
@include min-screen($break-large-devices){
  .content-steps {
    &__item {
      height: 125px;

      &--first {
        width: 302px;
        background-image: url('#{$image}/steps/01-md.png');

        .content-steps__caption {
          padding-top: 40px;
          padding-left: 78px;
        }
      }
      &--second {
        width: 368px;
        margin-left: -44px;
        background-image: url('#{$image}/steps/02-md.png');
      }
      &--third {
        width: 356px;
        margin-top: 0;
        margin-left: -47px;
        background-image: url('#{$image}/steps/03-md.png');
      }
    }
    &__caption {
      padding-top: 40px;
      padding-left: 142px;
      font-size: 15px;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .content-steps {
    &__item {
      &--first {
        width: 375px;
        background-image: url('#{$image}/steps/01-lg.png');

        .content-steps__caption {
          padding-left: 130px;
        }
      }
      &--second {
        width: 424px;
        margin-left: -44px;
        background-image: url('#{$image}/steps/02-lg.png');
      }
      &--third {
        width: 424px;
        margin-left: -43px;
        background-image: url('#{$image}/steps/03-lg.png');
      }
    }
    &__caption {
      padding-left: 194px;
      font-size: 17px;
    }
  }
}