.ui {
  &.hide {
    display: none !important;
  }
  input {
    @include border-radius(0px !important);
  }
  &.button {
    @include border-radius(0px !important);
  }
  .segment {
    @include border-radius(0px !important);
  }
  .search {
    .message.empty {
      font-size:13px !important;
    }
  }
  &.horizontal{
    &.normal {
      &.divider {
        font-weight: normal !important;
      }
    }
  }
  &.menu {
    @include border-radius(0px !important);
  }
  &.card {
    @include border-radius(0px !important);
  }
  &.w100 {
    width:100% !important;
  }
  &.m-b-0 {
    margin-bottom:0 !important;
  }
  &.m-t-0 {
    margin-top:0 !important;
  }
  &.message {
    @include border-radius(0px !important);
  }
  &.search{
    > .results {
      @include border-radius(0px !important);
      > .result {
        .title{
          font-weight: normal !important;
        }
      }
    }
  }
  &.attached{
    &.steps{
      @include border-radius(0px !important);
    }
  }

  &.modal {
    padding:0px !important;
    > .header {
      position: relative;
    }
  }
}

@include max-screen($break-small-devices){
  .hide-mobile {
    display:none !important;
  }

  .ui.massive.buttons .button,
  .ui.massive.buttons .or,
  .ui.massive.button {
    font-size: 1.28571429rem;
  }
}
