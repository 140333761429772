.pager {
  text-align: center;

  &__label {
    display: block;
    margin-bottom: 10px;
  }

  .pagination {
    list-style: none;
  }
  .pagination li {
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    margin-right: 3px;

    width: 24px;
    height: 24px;

    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;

    &.is-active {
      border-color: $major-color;

      a {
        cursor: default;
      }
    }
  }
  .pagination a {
    display: block;

    width: 100%;
    height: 100%;

    color: #000;
    line-height: 24px;
  }
}