.header:not(.ui):not(.sub)  {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $z-header;

  background-color: #fff;

  &__top {
    display: none;
    border-bottom: 1px solid #dedddd;
  }
  &__bottom {
    border-bottom: 3px solid $major-color;
  }

  &-main {
    padding-top: 13px;
    height: $header-height;

    .header-main__logo {
      float: left;
      width: 216px;
      margin-left: -7px;
    }
    .header-main__hamburger {
      margin-top: 8px;
      margin-left: -.5em;
      float: left;
    }
    .header-main__location {
      display: none;
    }
    .header-main__login {
      width: 43px;
      min-width: 0;
      height: 33px;
      min-height: 0;
      padding: 0;
      margin-top: 13px;
      float: right;

      .button__icon {
        margin-top: 4px;
        margin-bottom: 4px;
      }

      .button__label {
        display: none;
      }
    }
  }
}
@include min-screen($break-medium-devices){
  .header:not(.ui) {
    &-main {
      padding-top: 11px;
      height: $header-height-tablet;

      .header-main__logo {
        width: 290px;
        margin-left: -2px;
      }
      .header-main__hamburger {
        margin-top: 14px;
      }
      .header-main__login {
        width: auto;
        height: auto;
        min-height: 57px;
        padding: 11px 11px;
        margin-top: 12px;

        .button__icon {
          margin-top: 4px;
          margin-bottom: 4px;
        }
        .button__label {
          display: inline-block;
        }
      }
    }
  }
}
@include min-screen($break-large-devices){
  .header:not(.ui):not(.sub) {
    position: inherit;
    padding-top: 64px;

    &__top {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      background-color: #fff;

      &.js-is-fixed {
        position: fixed;
        z-index: $z-under;
        border-bottom-width: 3px;
        border-bottom-color: $major-color;

        .topbar__logo {
          visibility: visible;
          opacity: 1;
        }
        .topbar__nav {
          margin-left: 30px;
        }
        .callback__button {
          display: none;
        }
      }
    }

    &-main {
      padding-top: 4px;
      height: 90px;

      .header-main__logo {
        width: 290px;
        margin-top: -3px;
        margin-left: -20px;
      }
      .header-main__hamburger {
        display: none;
      }
      .header-main__location {
        display: block;
        float: right;
        margin-top: 26px;
        margin-right: 48px;
      }
    }
  }
}
@include min-screen($break-extra-large-devices){
  .header:not(.ui):not(.sub) {
    &-main {
      .header-main__logo {
        width: 348px;
        margin-top: -4px;
        margin-left: -23px;
      }
    }
  }
}


.topbar {
  $item-padding: 17px;

  position: relative;
  height: 63px;
  padding-top: 16px;

  &__logo {
    position: absolute;
    top: 8px;
    left: 0;

    display: block;
    width: 50px;
    height: 50px;

    opacity: 0;
    visibility: hidden;

    transition: opacity $transition;
    will-change: "opacity";
  }
  &__logo-img {
    height: 100%;
  }
  &__nav {
    margin-top: 7px;
    margin-left: -$item-padding;
    float: left;
    font-size: 0;

    transition: margin-left $transition;
    will-change: "margin-left";
  }
  &__item {
    position: relative;
    display: inline-block;
    padding: 0 $item-padding;
    line-height: 1.2;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;

      display: inline-block;
      width: 3px;
      height: 3px;
      margin-right: $item-padding;

      background-color: $font-color;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    &:first-child::after {
      display: none;
    }
  }
  &__link {
    @include font(15px, inherit, $font-color, $medium);

    &.is-active {
      color: $major-color;
    }
  }
  &__callback {
    float: right;
  }  
}
@include min-screen($break-extra-large-devices){
  .topbar {
    $item-padding: 35px;

    &__nav {
      margin-left: -$item-padding;
    }
    &__item {
      padding: 0 $item-padding;
    }
  }
}


.callback {
  &__tel {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    margin-right: 10px;
    padding-left: 28px;
    position: relative;
  }
  &__icon {
    position: absolute;
    top: 4px;
    left: 0;
  }
  &__num {
    @include font(22px, null, $major-color, $black, null, $DINPro);
  }
  &__caption {
    margin-top: -3px;
    @include font(11px, null, #181819, $regular, null, $DINPro);
  }
}


.location {
  position: relative;
  padding-left: 38px;
  @include font(13px, 1.5, $font-color, $regular, null, $DINPro);

  transition: color $transition;

  &__icon,
  &__label,
  &__inner {
    display: inline-block;
    vertical-align: middle;
  }
  &__icon,
  &__inner {
    margin-left: 4px;
    margin-right: 4px;
  }
}


.city-list {
  font-size: 13px;
  text-decoration: none;

  &__item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &__link {
    text-decoration: none;
    color: $font-color;
  }
}


.navigation-for-mobile {
  position: fixed;
  top: 87px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-header - 1;

  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  will-change: '';
  transition:
    visibility $transition,
    opacity $transition;

  &__item {
    border-top: 1px solid #d5d3d3;

    &:first-child {
      border-top: 0;
    }
  }
  &__link {
    display: block;

    padding-top: 15px;
    padding-bottom: 15px;

    font-size: 16px;
    font-weight: $medium;
    color: $font-color;

    &.is-active {
      color: $major-color;
    }
  }

  &.js-is-open {
    visibility: visible;
    opacity: 1;
  }
}
@include min-screen($break-medium-devices){
  .navigation-for-mobile {
    top: 107px;
  }
}
@include min-screen($break-large-devices){
  .navigation-for-mobile {
    display: none;
  }
}