.signup {
  max-width: 370px;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  position: relative;

  &__title {
    font-size: 30px;
    margin-bottom: 4px;
    font-family: $Roboto;
  }
  &__subtitle {
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 21px;
  }
  &__man,
  &__money {
    display: none;
  }
  &__button {
    height: 42px;
    padding-top: 10px;
    border-radius: 30px;
  }

  .ui-checkboxes {
    &--vertical {
      display: block;

      .ui-checkboxes__controll {
        font-size: 11px;
        line-height: 1.5;
        font-style: italic;
      }
    }
  }
  .ui-datepicker-text {
    font-size: 12px;
    margin-top: 21px;
  }
}
@include min-screen($break-medium-devices){
  .signup {
    padding-top: 48px;

    &__title {
      font-size: 30px;
    }
    .ui-datepicker-text {
      float: right;
      margin-top: 0;
    }
    .ui-checkboxes {
      float: left;
      margin-top: 3px;

      &.ui-checkboxes--vertical {
        float: none;
        margin-top: 0;
      }
    }


  }
}
@include min-screen($break-large-devices){
  .signup {
    padding-top: 0;

    &__man {
      position: absolute;
      right: 120%;
      bottom: 20px;
      z-index: 2;

      width: 178px;
      height: 437px;

      display: block;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .signup {
    &__man {
      right: 140%;
    }
    &__money {
      position: absolute;
      left: 110%;
      bottom: 20px;
      z-index: 2;

      width: 351px;
      height: 237px;

      display: block;
    }
  }
}