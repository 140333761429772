.socials {
  margin-left: -5px;
  font-size: 11px;

  &__item {
    display: inline-block;
    vertical-align: middle;

    margin-left: 5px;
    margin-right: 5px;

    &--label {
      display: block;
      margin-bottom: 5px;
    }
  }
  &__image {
    display: block;
  }
}
@include min-screen($break-medium-devices) {
  .socials {
    &__item {
      &--label {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
}