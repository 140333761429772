﻿.pull-left {
    float: left;
}
.pull-right {
    float: right;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right !important;
}
.text-center {
    text-align: center;
}
.text-centered {
  @include abs-ctr(true, true);
}
.text-justify {
    text-align: justify;
}
.text-nowrap {
    white-space: nowrap;
}
.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-capitalize {
    text-transform: capitalize;
}
.text-underline {
    text-decoration: underline;
}
.text-bold {
  font-weight: $bold;
}
.img-responsive {
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.img-contain {
    display: block;
    width: 100%;
    height: 100%;
}
.align-center {
    margin-left: auto;
    margin-right: auto;
}
.parent {
    position: relative;
}
.last {
    margin-bottom: 0 !important;
}
.pointer {
    cursor: pointer;
}
.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.text-muted {
  color: $muted-color;
}
.hidden {
  display: none !important;
}
.background-gray {
  background-color: #f4f4f4;
}
.overflow-y-hidden {
  overflow-y: hidden;
}


/* colors */
.color-major {
  color: $major-color;
}
.color-accept {
  color: $accept-color;
}