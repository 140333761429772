@charset "UTF-8";
/**/
/* Настройки чекбоксов */
/* Настройки радиобоксов */
/* Настройки календаря */
/* Размеры */
/* Настройки элементов формы */
/* Настройки селектов */
/* Теги */
/** Список операций */
.pull-left {
  float: left; }

.pull-right {
  float: right; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center; }

.text-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize; }

.text-underline {
  text-decoration: underline; }

.text-bold {
  font-weight: 700; }

.img-responsive, .content-payment__logo, .content-request-info__preview-image, .content-whatis__image {
  display: inline-block;
  max-width: 100%;
  height: auto; }

.img-contain {
  display: block;
  width: 100%;
  height: 100%; }

.align-center, .content-payment__logotype, .content-steps__item {
  margin-left: auto;
  margin-right: auto; }

.parent {
  position: relative; }

.last {
  margin-bottom: 0 !important; }

.pointer {
  cursor: pointer; }

.clearfix:after, .form__fieldset:after, .ui-checkboxes:after, .reg-form .ui-checkboxes:after,
.sign-loan-form .ui-checkboxes:after, .calculator__field-header:after, .calculator__row:after {
  content: "";
  display: table;
  clear: both; }

.text-muted {
  color: #7f8183; }

.hidden {
  display: none !important; }

.background-gray {
  background-color: #f4f4f4; }

.overflow-y-hidden {
  overflow-y: hidden; }

/* colors */
.color-major, .calculator__text-important, .calculator__faq-trigger {
  color: #2184be; }

.color-accept {
  color: #73b746; }

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

* {
  box-sizing: border-box; }

*:focus {
  outline: none; }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

dl,
menu,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

html,
body {
  width: 100%;
  height: 100%; }

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  smoothing: antialiased; }

html {
  font-size: 14px;
  line-height: 1.15;
  min-height: 100%; }

body {
  min-width: 320px;
  background-color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4285em;
  font-family: "Roboto", sans-serif;
  color: #3d4343; }

@media only screen and (max-width: 767px) {
  body.js-is-xs-scroll-hidden {
    overflow-y: hidden; } }

@media only screen and (max-width: 991px) {
  body.js-is-sm-scroll-hidden {
    overflow-y: hidden; } }

a,
.link,
.daterangepicker .ranges li {
  color: #468ee5;
  text-decoration: none;
  transition: color 300ms ease-in-out; }
  a:hover, a:active, a:focus,
  .link:hover,
  .daterangepicker .ranges li:hover,
  .link:active,
  .daterangepicker .ranges li:active,
  .link:focus,
  .daterangepicker .ranges li:focus {
    outline: none;
    color: #ff5722; }
  a-major,
  .link-major {
    color: #2184be; }
  a-dark,
  .link-dark {
    color: #3d4343; }
  a-underline,
  .link-underline {
    text-decoration: underline; }

.h1, .h2, .h3, .h4, .h5, .h6, .p, p {
  padding: 0;
  margin: 0; }

p, .p {
  padding-bottom: 10px; }

.h1 {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.1;
  font-family: "DIN Pro", sans-serif;
  color: #3d4343; }
  .h1.main-title {
    padding-bottom: 47px; }

.h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.1;
  font-family: "DIN Pro", sans-serif;
  color: #3d4343;
  text-align: center;
  padding-bottom: 6px; }
  .h2.text-left {
    text-align: left; }
  .h2.main-title {
    padding-bottom: 47px; }

@media only screen and (min-width: 768px) {
  .h2 {
    font-size: 42px;
    padding-bottom: 10px; } }

@media only screen and (min-width: 992px) {
  .h2 {
    font-size: 48px; } }

.h3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.1;
  font-family: "DIN Pro", sans-serif;
  color: #3d4343;
  padding-bottom: 10px; }
  .h3.text-center {
    text-align: center; }

@media only screen and (min-width: 768px) {
  .h3 {
    font-size: 36px; } }

.hr {
  margin: 40px 0;
  padding: 0;
  border: 0;
  height: 1px;
  background-color: #d4d2d2; }

@media only screen and (min-width: 768px) {
  .hr-blue {
    margin-top: -20px; }
  .article + .hr-line {
    margin-top: 0; }
  .hr-line + .widget-feedback {
    margin-top: 20px; } }

@media only screen and (min-width: 992px) {
  .hr-blue {
    margin-top: -30px; }
  .hr-line + .widget-feedback {
    margin-top: 40px; } }

.text-area .h1 {
  font-family: "Roboto", sans-serif; }

.text-area p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 23px; }

@font-face {
  font-family: "ASLRouble";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/aslrouble/rouble-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aslrouble/rouble-webfont.woff2") format("woff2"), url("../fonts/aslrouble/rouble-webfont.woff") format("woff"), url("../fonts/aslrouble/rouble-webfont.ttf") format("truetype"), url("../fonts/aslrouble/rouble-webfont.svg#ASLRouble") format("svg"); }

@font-face {
  font-family: 'DIN Pro';
  src: url("../fonts/dinpro/dinpro.eot");
  src: url("../fonts/dinpro/dinpro.eot?#iefix") format("embedded-opentype"), url("../fonts/dinpro/dinpro.woff") format("woff"), url("../fonts/dinpro/dinpro.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'DIN Pro';
  src: url("../fonts/dinproitalic/dinproitalic.eot");
  src: url("../fonts/dinproitalic/dinproitalic.eot?#iefix") format("embedded-opentype"), url("../fonts/dinproitalic/dinproitalic.woff") format("woff"), url("../fonts/dinproitalic/dinproitalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'DIN Pro';
  src: url("../fonts/dinprobold/dinprobold.eot");
  src: url("../fonts/dinprobold/dinprobold.eot?#iefix") format("embedded-opentype"), url("../fonts/dinprobold/dinprobold.woff") format("woff"), url("../fonts/dinprobold/dinprobold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'DIN Pro';
  src: url("../fonts/dinproblack/dinproblack.eot");
  src: url("../fonts/dinproblack/dinproblack.eot?#iefix") format("embedded-opentype"), url("../fonts/dinproblack/dinproblack.woff") format("woff"), url("../fonts/dinproblack/dinproblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'DIN Pro';
  src: url("../fonts/dinpromedium/dinpromedium.eot");
  src: url("../fonts/dinpromedium/dinpromedium.eot?#iefix") format("embedded-opentype"), url("../fonts/dinpromedium/dinpromedium.woff") format("woff"), url("../fonts/dinpromedium/dinpromedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans/opensans.eot");
  src: url("../fonts/opensans/opensans.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/opensans.woff") format("woff"), url("../fonts/opensans/opensans.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensanslight/opensanslight.eot");
  src: url("../fonts/opensanslight/opensanslight.eot?#iefix") format("embedded-opentype"), url("../fonts/opensanslight/opensanslight.woff") format("woff"), url("../fonts/opensanslight/opensanslight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotoregular/robotoregular.eot");
  src: url("../fonts/robotoregular/robotoregular.eot?#iefix") format("embedded-opentype"), url("../fonts/robotoregular/robotoregular.woff") format("woff"), url("../fonts/robotoregular/robotoregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotomedium/robotomedium.eot");
  src: url("../fonts/robotomedium/robotomedium.eot?#iefix") format("embedded-opentype"), url("../fonts/robotomedium/robotomedium.woff") format("woff"), url("../fonts/robotomedium/robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotobold/robotobold.eot");
  src: url("../fonts/robotobold/robotobold.eot?#iefix") format("embedded-opentype"), url("../fonts/robotobold/robotobold.woff") format("woff"), url("../fonts/robotobold/robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotoitalic/robotoitalic.eot");
  src: url("../fonts/robotoitalic/robotoitalic.eot?#iefix") format("embedded-opentype"), url("../fonts/robotoitalic/robotoitalic.woff") format("woff"), url("../fonts/robotoitalic/robotoitalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotomediumitalic/robotomediumitalic.eot");
  src: url("../fonts/robotomediumitalic/robotomediumitalic.eot?#iefix") format("embedded-opentype"), url("../fonts/robotomediumitalic/robotomediumitalic.woff") format("woff"), url("../fonts/robotomediumitalic/robotomediumitalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/robotolight/robotolight.eot");
  src: url("../fonts/robotolight/robotolight.eot?#iefix") format("embedded-opentype"), url("../fonts/robotolight/robotolight.woff") format("woff"), url("../fonts/robotolight/robotolight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

.header:not(.ui):not(.sub) {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background-color: #fff; }
  .header:not(.ui):not(.sub)__top {
    display: none;
    border-bottom: 1px solid #dedddd; }
  .header:not(.ui):not(.sub)__bottom {
    border-bottom: 3px solid #2184be; }
  .header:not(.ui):not(.sub)-main {
    padding-top: 13px;
    height: 84px; }
    .header:not(.ui):not(.sub)-main .header-main__logo {
      float: left;
      width: 216px;
      margin-left: -7px; }
    .header:not(.ui):not(.sub)-main .header-main__hamburger {
      margin-top: 8px;
      margin-left: -.5em;
      float: left; }
    .header:not(.ui):not(.sub)-main .header-main__location {
      display: none; }
    .header:not(.ui):not(.sub)-main .header-main__login {
      width: 43px;
      min-width: 0;
      height: 33px;
      min-height: 0;
      padding: 0;
      margin-top: 13px;
      float: right; }
      .header:not(.ui):not(.sub)-main .header-main__login .button__icon {
        margin-top: 4px;
        margin-bottom: 4px; }
      .header:not(.ui):not(.sub)-main .header-main__login .button__label {
        display: none; }

@media only screen and (min-width: 768px) {
  .header:not(.ui)-main {
    padding-top: 11px;
    height: 104px; }
    .header:not(.ui)-main .header-main__logo {
      width: 290px;
      margin-left: -2px; }
    .header:not(.ui)-main .header-main__hamburger {
      margin-top: 14px; }
    .header:not(.ui)-main .header-main__login {
      width: auto;
      height: auto;
      min-height: 57px;
      padding: 11px 11px;
      margin-top: 12px; }
      .header:not(.ui)-main .header-main__login .button__icon {
        margin-top: 4px;
        margin-bottom: 4px; }
      .header:not(.ui)-main .header-main__login .button__label {
        display: inline-block; } }

@media only screen and (min-width: 992px) {
  .header:not(.ui):not(.sub) {
    position: inherit;
    padding-top: 64px; }
    .header:not(.ui):not(.sub)__top {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff; }
      .header:not(.ui):not(.sub)__top.js-is-fixed {
        position: fixed;
        z-index: 5;
        border-bottom-width: 3px;
        border-bottom-color: #2184be; }
        .header:not(.ui):not(.sub)__top.js-is-fixed .topbar__logo {
          visibility: visible;
          opacity: 1; }
        .header:not(.ui):not(.sub)__top.js-is-fixed .topbar__nav {
          margin-left: 30px; }
        .header:not(.ui):not(.sub)__top.js-is-fixed .callback__button {
          display: none; }
    .header:not(.ui):not(.sub)-main {
      padding-top: 4px;
      height: 90px; }
      .header:not(.ui):not(.sub)-main .header-main__logo {
        width: 290px;
        margin-top: -3px;
        margin-left: -20px; }
      .header:not(.ui):not(.sub)-main .header-main__hamburger {
        display: none; }
      .header:not(.ui):not(.sub)-main .header-main__location {
        display: block;
        float: right;
        margin-top: 26px;
        margin-right: 48px; } }

@media only screen and (min-width: 1200px) {
  .header:not(.ui):not(.sub)-main .header-main__logo {
    width: 348px;
    margin-top: -4px;
    margin-left: -23px; } }

.topbar {
  position: relative;
  height: 63px;
  padding-top: 16px; }
  .topbar__logo {
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease-in-out;
    will-change: "opacity"; }
  .topbar__logo-img {
    height: 100%; }
  .topbar__nav {
    margin-top: 7px;
    margin-left: -17px;
    float: left;
    font-size: 0;
    transition: margin-left 300ms ease-in-out;
    will-change: "margin-left"; }
  .topbar__item {
    position: relative;
    display: inline-block;
    padding: 0 17px;
    line-height: 1.2; }
    .topbar__item::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 3px;
      height: 3px;
      margin-right: 17px;
      background-color: #3d4343;
      border-radius: 50%;
      transform: translateY(-50%); }
    .topbar__item:first-child::after {
      display: none; }
  .topbar__link {
    font-weight: 500;
    font-size: 15px;
    line-height: inherit;
    color: #3d4343; }
    .topbar__link.is-active {
      color: #2184be; }
  .topbar__callback {
    float: right; }

@media only screen and (min-width: 1200px) {
  .topbar__nav {
    margin-left: -35px; }
  .topbar__item {
    padding: 0 35px; } }

.callback__tel {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
  margin-right: 10px;
  padding-left: 28px;
  position: relative; }

.callback__icon {
  position: absolute;
  top: 4px;
  left: 0; }

.callback__num {
  font-weight: 900;
  font-size: 22px;
  font-family: "DIN Pro", sans-serif;
  color: #2184be; }

.callback__caption {
  margin-top: -3px;
  font-weight: 400;
  font-size: 11px;
  font-family: "DIN Pro", sans-serif;
  color: #181819; }

.location {
  position: relative;
  padding-left: 38px;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
  font-family: "DIN Pro", sans-serif;
  color: #3d4343;
  transition: color 300ms ease-in-out; }
  .location__icon, .location__label, .location__inner {
    display: inline-block;
    vertical-align: middle; }
  .location__icon, .location__inner {
    margin-left: 4px;
    margin-right: 4px; }

.city-list {
  font-size: 13px;
  text-decoration: none; }
  .city-list__item {
    padding-top: 10px;
    padding-bottom: 10px; }
  .city-list__link {
    text-decoration: none;
    color: #3d4343; }

.navigation-for-mobile {
  position: fixed;
  top: 87px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 19;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  will-change: '';
  transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out; }
  .navigation-for-mobile__item {
    border-top: 1px solid #d5d3d3; }
    .navigation-for-mobile__item:first-child {
      border-top: 0; }
  .navigation-for-mobile__link {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #3d4343; }
    .navigation-for-mobile__link.is-active {
      color: #2184be; }
  .navigation-for-mobile.js-is-open {
    visibility: visible;
    opacity: 1; }

@media only screen and (min-width: 768px) {
  .navigation-for-mobile {
    top: 107px; } }

@media only screen and (min-width: 992px) {
  .navigation-for-mobile {
    display: none; } }

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0; }
  .footer__top {
    padding-top: 28px;
    padding-bottom: 12px;
    background: #f7f6f7;
    background: linear-gradient(to bottom, #f0f0f1 0%, #fefefe 100%); }
  .footer__top-text {
    margin-top: 13px;
    font-size: 13px;
    line-height: 1.4;
    color: #000; }
  .footer__bottom {
    padding-top: 33px;
    padding-bottom: 20px;
    background: #e8f3e7;
    background: linear-gradient(to bottom, #e8f3e7 0%, #e8f4e8 100%); }
  .footer__bottom-text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-style: italic;
    font-size: 12px;
    line-height: 1.5; }
  .footer__menu {
    margin-top: 15px;
    margin-bottom: 15px; }
  .footer__menu-item {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px; }
  .footer__menu-link {
    position: relative;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.6;
    color: #404241; }
    .footer__menu-link::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      margin-left: -12px;
      display: inline-block;
      width: 3px;
      height: 3px;
      background-color: #3d4343;
      border-radius: 50%;
      transform: translateY(-50%); }
    .footer__menu-link.is-active {
      color: #2184be; }
      .footer__menu-link.is-active::before {
        background-color: #2184be; }

@media only screen and (min-width: 768px) {
  .footer__bottom {
    padding-top: 27px;
    padding-bottom: 20px; }
  .footer__bottom-text {
    margin-top: 0; }
  .footer__logo {
    margin-top: 7px; }
  .footer__menu {
    margin-top: 13px;
    margin-bottom: 13px; }
  .footer__menu-item {
    padding-left: 16px;
    padding-top: 7px;
    padding-bottom: 7px; }
  .footer__menu-link {
    font-size: 14px; }
    .footer__menu-link::before {
      margin-left: -16px; } }

@media only screen and (min-width: 992px) {
  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0; } }

.main {
  position: relative;
  min-height: 100%; }

.page-content {
  background-color: #F0F4F5; }

@media only screen and (min-width: 992px) {
  .page-content {
    padding-top: 0;
    padding-bottom: 0; } }

.button, .btn-major, .btn-minor {
  display: inline-block;
  min-height: 1em;
  margin: 0;
  padding: .78571429em 1.5em .78571429em;
  cursor: pointer;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: none;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 1em;
  font-family: "DIN Pro", sans-serif;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  text-shadow: none;
  border-radius: .28571429rem;
  user-select: none;
  transition: opacity 300ms ease-in-out, background-color 300ms ease-in-out, color 300ms ease-in-out, box-shadow 300ms ease-in-out, border-color 300ms ease-in-out, background 300ms ease-in-out;
  will-change: '';
  -webkit-tap-highlight-color: transparent; }
  .button--hamburger {
    padding: .5em;
    position: relative; }
    .button--hamburger .button__icon {
      width: 26px;
      height: 19px; }
    .button--hamburger.js-is-active::after, .button--hamburger.js-is-active::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 3px;
      height: 26px;
      margin-top: -13px;
      margin-left: -2px;
      background-color: #000; }
    .button--hamburger.js-is-active::before {
      transform: rotate(45deg); }
    .button--hamburger.js-is-active::after {
      transform: rotate(-45deg); }
    .button--hamburger.js-is-active .button__icon {
      opacity: 0; }
  .button--major {
    background-color: #1ea8e0; }
    .button--major:hover, .button--major:active, .button--major:focus {
      color: #fff; }
    .button--major:hover {
      background-color: #2184be; }
  .button--icon .button__icon,
  .button--icon .button__label {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px; }
  .button--basic {
    border: 1px solid #eee;
    color: #3d4343; }
    .button--basic:hover {
      background-color: #fff;
      border-color: #2184be;
      color: #2184be; }
    .button--basic.button--major {
      background-color: #fff;
      border-color: #2184be;
      color: #2184be; }
      .button--basic.button--major:hover {
        color: #fff;
        background-color: #2184be; }
  .button--green {
    background-color: #4cad41; }
    .button--green:hover {
      color: #fff;
      background-color: #2184be; }
  .button--no-radius {
    border-radius: 0; }
  .button--small-radius {
    border-radius: 3px; }
  .button--tiny {
    padding: 8px 16px;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.1;
    text-transform: uppercase; }
  .button--small {
    height: 36px;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #fff; }
  .button--medium {
    min-width: 150px;
    min-height: 57px;
    padding: 14px 20px;
    font-size: 18px;
    border-radius: 30px; }
    .button--medium.button--small-radius {
      border-radius: 3px; }
  .button--fluid {
    width: 100%; }
  .button--take-money {
    height: 57px;
    padding-top: 10px;
    padding-left: 17px;
    padding-right: 17px; }

.btn {
  display: inline-block;
  vertical-align: middle;
  border: 0;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  box-sizing: border-box;
  transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out;
  border-radius: 2px;
  font-weight: 400;
  /* Основная кнопка major button */
  /* Второстепенная кнопка minor button */
  /* Кнопки групповые */ }
  .btn:focus {
    outline: none; }
  .btn.is-wide {
    width: 100%; }
  .btn:disabled {
    cursor: default; }
  .btn:disabled:hover {
    box-shadow: 0 0 0 1px #f2f2f2; }
  .btn--uppercase {
    text-transform: uppercase; }
  .btn-major {
    height: 46px;
    padding: 4px 32px;
    background-color: #1ea8e0;
    box-shadow: 0 0 0 1px #1ea8e0;
    font-size: 15px;
    line-height: 38px;
    color: #fff; }
    .btn-major:hover, .btn-major:focus {
      background-color: #2184be;
      box-shadow: 0 0 0 1px #2184be;
      color: #fff; }
    .btn-major:active {
      background-color: #2184be;
      box-shadow: 0 0 0 1px #2184be;
      color: #fff; }
    .btn-major:disabled {
      background-color: #f2f2f2;
      box-shadow: 0 0 0 1px #f2f2f2;
      color: #fff; }
    .btn-major.is-loading {
      color: #fff;
      background-color: #fdd792;
      box-shadow: 0 0 0 1px #fdd792; }
    .btn-major--right {
      float: right; }
  .btn-minor {
    height: 46px;
    padding: 4px 32px;
    background-color: #fff;
    box-shadow: 0 0 0 1px #d7d7d7;
    font-size: 15px;
    line-height: 38px;
    color: #3d4343; }
    .btn-minor:hover, .btn-minor:focus {
      background-color: #f2f2f2;
      color: inherit; }
    .btn-minor:active {
      background-color: #d7d7d7;
      color: inherit; }
    .btn-minor:disabled {
      color: #fff;
      background-color: #f2f2f2;
      box-shadow: 0 0 0 1px #f2f2f2; }
    .btn-minor.is-loading {
      color: #fff;
      background-color: #fdd792;
      box-shadow: 0 0 0 1px #fdd792; }
  .btn-group {
    font-size: 0; }
    .btn-group .btn-minor {
      height: 40px;
      text-align: center;
      padding: 0;
      background-color: #fff;
      font-size: 14px;
      line-height: 40px;
      color: #3d4343;
      border-radius: 0;
      outline: none;
      font-weight: 400;
      width: 120px; }
      .btn-group .btn-minor:hover {
        box-shadow: 0 0 0 1px #9c9c9c;
        position: relative; }
      .btn-group .btn-minor:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        margin-left: 0; }
      .btn-group .btn-minor:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px; }
      .btn-group .btn-minor:focus, .btn-group .btn-minor:active, .btn-group .btn-minor.is-active, .btn-group .btn-minor.is-active:hover {
        background-color: #ffd488;
        box-shadow: 0 0 0 1px #d7d7d7; }
    .btn-group .disabled {
      opacity: 0.5; }

.range-slider .irs-line-mid,
.range-slider .irs-line-left,
.range-slider .irs-line-right,
.range-slider .irs-bar,
.range-slider .irs-bar-edge,
.range-slider .irs-slider {
  background-image: none; }

.range-slider .irs-line {
  top: 12px;
  height: 6px; }

.range-slider .irs-line-mid, .range-slider .irs-line-left, .range-slider .irs-line-right {
  height: 100%; }

.range-slider .irs-line-mid, .range-slider .irs-line-left, .range-slider .irs-line-right {
  background-color: #4cad41; }

.range-slider .irs-line-left {
  width: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.range-slider .irs-line-right {
  width: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.range-slider .irs-line-mid {
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 3px; }

.range-slider .irs-slider {
  top: 0;
  width: 29px;
  height: 29px;
  background-image: url("../images/png/content-common-calc-bar.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  border-radius: 50%;
  box-shadow: 5px 5px 10px #999; }

.range-slider .irs-bar {
  left: 0 !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }
  .range-slider .irs-bar, .range-slider .irs-bar-edge {
    top: 12px;
    height: 6px;
    background-color: #2184be; }
  .range-slider .irs-bar-edge {
    width: 0; }

.owl-carousel {
  padding-bottom: 23px; }
  .owl-carousel .owl-dots {
    margin-top: 10px;
    text-align: center; }
  .owl-carousel .owl-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: #d3d3d3;
    border-radius: 50%;
    transition: background-color 300ms ease-in-out; }
    .owl-carousel .owl-dot.active {
      background-color: #2184be; }

.tooltip {
  width: 200px;
  height: 200px;
  border: 1px solid #2184be;
  position: absolute;
  background: #fff;
  display: none;
  transition: .3s;
  position: absolute;
  left: -93px;
  top: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 5; }
  .tooltip:before, .tooltip:after {
    content: '';
    border: 10px solid transparent;
    width: 0px;
    height: 0px;
    border-bottom-color: #fff;
    background-color: transparent;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip:before {
    border-bottom-color: #2184be;
    top: -21px; }
  .tooltip img {
    display: block;
    max-width: 130px;
    max-height: 130px;
    margin: 20px auto 15px; }

.tooltip-btn {
  margin-left: 2px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url("../images/svg/tooltip.svg") no-repeat;
  transition: .3s;
  position: relative;
  vertical-align: middle; }
  .tooltip-btn:hover .tooltip {
    display: block; }

.tt-menu,
.tt-dropdown-menu {
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }

.tt-suggestion {
  padding: 0px 20px;
  font-size: 18px;
  line-height: 24px; }
  .tt-suggestion p {
    margin: 0; }
  .tt-suggestion:hover, .tt-suggestion.tt-cursor, .tt-suggestion.tt-is-under-cursor {
    color: #fff;
    background-color: #0097cf;
    cursor: pointer; }

.form-group .twitter-typeahead {
  display: block !important;
  padding: 0 !important; }

@keyframes zoom-in-out {
  0% {
    transform: translateX(-50%) scale3d(1, 1, 1); }
  50% {
    transform: translateX(-50%) scale3d(1.1, 1.1, 1.1); }
  100% {
    transform: translateX(-50%) scale3d(1, 1, 1); } }

body .modal:not(.ui) {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 6px; }
  body .modal:not(.ui) .modal__close.mfp-close {
    width: 32px;
    height: 32px;
    margin: 8px;
    background-image: url("../images/svg/times-o.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%; }
  body .modal:not(.ui) .modal___header {
    padding-top: 29px;
    padding-bottom: 12px;
    text-align: center; }
  body .modal:not(.ui) .modal__body,
  body .modal:not(.ui) .modal__header,
  body .modal:not(.ui) .modal__footer {
    padding: 16px 32px; }
  body .modal:not(.ui) .modal__body {
    text-align: left; }
  body .modal:not(.ui) .modal__footer {
    padding-top: 20px;
    padding-bottom: 0;
    border-top: 1px solid #ededed;
    text-align: left; }
  body .modal:not(.ui) .modal__title {
    font-size: 24px;
    line-height: 1;
    text-align: left; }
  body .modal:not(.ui)--medium {
    width: 95%;
    border-radius: 5px;
    padding: 30px; }
  body .modal:not(.ui)__preloader {
    display: none;
    text-align: center; }
  body .modal:not(.ui)__countdown {
    margin-bottom: 15px;
    text-align: center; }
  body .modal:not(.ui)--whyLeave .form__label {
    width: 75px; }
  body .modal:not(.ui)--whyLeave .form__control {
    width: 310px; }
  body .modal:not(.ui)--whyLeave .row {
    margin-bottom: 20px; }
  body .modal:not(.ui)--pod-ft-agreement {
    width: 60%; }
    body .modal:not(.ui)--pod-ft-agreement ul {
      list-style-type: disc;
      text-align: justify; }
      body .modal:not(.ui)--pod-ft-agreement ul li {
        margin-left: 20px; }

@media only screen and (min-width: 768px) {
  body .modal:not(.ui) {
    width: 480px; }
    body .modal:not(.ui)--pod-ft-agreement {
      width: 60%; } }

.contract .modal__title {
  font-size: 24px;
  margin-bottom: 70px; }

.contract .modal__text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px; }

.contract .modal__form {
  text-align: left;
  margin: 0 auto;
  width: 70%;
  margin-bottom: 70px; }

.contract .modal__input {
  border-radius: 0;
  border: 1px solid #abaaaa;
  padding: 20px;
  background-color: #f7f7f7;
  width: 100%;
  display: block;
  margin-bottom: 10px; }

.contract .modal__link {
  /* display: inline-block; */
  color: #3d4343;
  line-height: 1.2;
  border-bottom: 1px solid;
  cursor: pointer; }

.contract .modal__buttons .button, .contract .modal__buttons .btn-major, .contract .modal__buttons .btn-minor {
  margin: 0 10px 10px; }

@media only screen and (min-width: 768px) {
  .contract {
    width: 685px; } }

.form {
  font-family: "Roboto", sans-serif; }
  .form__field {
    position: relative;
    padding: 8px 13px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #b4b4b4;
    border-radius: 24px; }
  .form__field-inner {
    display: table; }
    .form__field-inner .form__label,
    .form__field-inner .form__control {
      display: table-cell;
      white-space: nowrap; }
  .form__label, .form__control {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    line-height: 1;
    color: #181819; }
  .form__label {
    border-right: 1px solid #c7c1be;
    padding-right: 10px; }
  .form__control {
    display: block;
    width: 100%;
    height: 20px;
    font-size: 13px;
    line-height: 1;
    border: 0;
    margin: 0;
    margin-left: 10px; }
  .form__error {
    width: 100%;
    font-size: 11px;
    color: #f10000;
    text-align: left; }
  .form__append {
    font-size: 11px;
    font-style: italic;
    color: #3d4343; }
  .form__fieldset {
    margin-top: 8px;
    margin-bottom: 8px;
    border-top: 1px solid transparent; }
  .form__actions {
    margin-top: 29px;
    text-align: center; }
    .form__actions .button, .form__actions .btn-major, .form__actions .btn-minor {
      width: 133px;
      margin-left: 5px;
      margin-right: 5px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-weight: 500; }

.form-control, .ui-select.single .selectize-input, .ui-select.multi .selectize-input {
  width: 100%;
  height: 37px;
  padding: 4px 10px;
  border: 1px solid #dadada;
  resize: none;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  color: #3d4343;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 300ms ease-in-out; }
  .form-control::-webkit-input-placeholder, .ui-select.single .selectize-input::-webkit-input-placeholder, .ui-select.multi .selectize-input::-webkit-input-placeholder {
    color: #dadada; }
  .form-control::-moz-placeholder, .ui-select.single .selectize-input::-moz-placeholder, .ui-select.multi .selectize-input::-moz-placeholder {
    color: #dadada; }
  .form-control:-moz-placeholder, .ui-select.single .selectize-input:-moz-placeholder, .ui-select.multi .selectize-input:-moz-placeholder {
    color: #dadada; }
  .form-control:-ms-input-placeholder, .ui-select.single .selectize-input:-ms-input-placeholder, .ui-select.multi .selectize-input:-ms-input-placeholder {
    color: #dadada; }
  .form-control:focus, .ui-select.single .selectize-input:focus, .ui-select.multi .selectize-input:focus {
    border-color: #9c9c9c;
    outline: none; }
  .form-control.counter, .ui-select.single .counter.selectize-input, .ui-select.multi .counter.selectize-input {
    padding-right: 60px; }
    .form-control.counter + .help-block, .ui-select.single .counter.selectize-input + .help-block, .ui-select.multi .counter.selectize-input + .help-block {
      position: absolute;
      top: 50%;
      right: 13px;
      margin-top: -10px;
      font-size: 13px;
      line-height: 1.5;
      color: #9c9c9c; }
  .form-control:disabled, .ui-select.single .selectize-input:disabled, .ui-select.multi .selectize-input:disabled {
    opacity: 0.5;
    cursor: default;
    background: #f5f5f5;
    color: #dadada; }
  .form-control::placeholder, .ui-select.single .selectize-input::placeholder, .ui-select.multi .selectize-input::placeholder {
    color: #b1b1b1; }

textarea.form-control, .ui-select.single textarea.selectize-input, .ui-select.multi textarea.selectize-input {
  height: 100px;
  padding: 10px 10px; }

.control-label {
  font-size: 14px;
  line-height: 1.7;
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block; }
  .control-label small {
    font-size: 12px; }

.ui-checkboxes {
  font-size: 12px; }
  .ui-checkboxes__controll {
    display: block;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 26px;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    line-height: 1; }
  .ui-checkboxes__input {
    opacity: 0;
    position: absolute;
    left: -9999px; }
    .ui-checkboxes__input:checked + .ui-checkboxes__icon {
      border-color: #2184be;
      background-color: #2184be; }
      .ui-checkboxes__input:checked + .ui-checkboxes__icon .ui-checkboxes__carret {
        display: block; }
  .ui-checkboxes__icon {
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #9c9c9c;
    background-color: #fff;
    border-radius: 50%;
    transition: background-color 300ms ease-in-out; }
  .ui-checkboxes__carret {
    display: none;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%); }
  .ui-checkboxes--vertical .ui-checkboxes__controll {
    margin-top: 10px;
    margin-bottom: 10px; }

.ui-calendar {
  position: relative; }
  .ui-calendar__controll {
    position: relative; }
  .ui-calendar.is-open .ui-calendar__dropdown {
    display: block; }

.daterangepicker {
  display: none;
  width: auto;
  padding: 5px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  box-sizing: border-box; }
  .daterangepicker_input {
    display: none; }
  .daterangepicker .ranges {
    display: block !important;
    width: 100%;
    clear: both;
    float: none;
    text-align: center; }
    .daterangepicker .ranges::before {
      content: "Последние";
      display: inline-block;
      vertical-align: middle;
      font-size: 13px;
      line-height: 1.5;
      margin-right: 5px; }
    .daterangepicker .ranges ul {
      width: auto;
      display: inline-block;
      vertical-align: middle; }
    .daterangepicker .ranges li {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 13px;
      line-height: 1.5;
      background-color: transparent;
      border: 0;
      border-radius: 0; }
      .daterangepicker .ranges li::before {
        content: "/";
        margin-left: 3px;
        margin-right: 3px;
        color: #3d4343; }
      .daterangepicker .ranges li:first-child::before {
        display: none; }
  .daterangepicker .calendar {
    width: 280px;
    max-width: inherit;
    margin: 0;
    padding: 0; }
    .daterangepicker .calendar.left {
      margin-right: 17px !important; }
    .daterangepicker .calendar.right {
      margin-left: 17px !important; }
    .daterangepicker .calendar.single {
      margin: 0 !important; }
  .daterangepicker .calendar-table {
    padding: 6px 0 0 !important;
    border: 0; }
    .daterangepicker .calendar-table th,
    .daterangepicker .calendar-table td {
      height: 34px;
      text-align: center;
      font-size: 13px;
      line-height: 2.6;
      color: #333;
      border-radius: 3px; }
    .daterangepicker .calendar-table td {
      border-radius: 4px; }
      .daterangepicker .calendar-table td.weekend {
        color: #f76300; }
      .daterangepicker .calendar-table td.in-range {
        border-radius: 0;
        background-color: #ffe9c3; }
      .daterangepicker .calendar-table td.today {
        color: #3d4343;
        background-color: #f2f2f2; }
      .daterangepicker .calendar-table td.active {
        background-color: #ffd488;
        color: inherit; }
      .daterangepicker .calendar-table td.start-date {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-radius: 4px 0 0 4px; }
      .daterangepicker .calendar-table td.end-date {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-radius: 0 4px 4px 0; }
      .daterangepicker .calendar-table td.start-date.end-date {
        border-radius: 4px; }
      .daterangepicker .calendar-table td.off {
        color: #dadada; }
    .daterangepicker .calendar-table thead tr:first-child th {
      padding-bottom: 15px; }
    .daterangepicker .calendar-table thead tr th {
      padding-bottom: 14px;
      font-weight: 400; }
      .daterangepicker .calendar-table thead tr th:nth-child(6), .daterangepicker .calendar-table thead tr th:nth-child(7) {
        color: #f76300; }
    .daterangepicker .calendar-table th.month {
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
      font-weight: 400;
      font-size: 17px;
      line-height: 1.6;
      color: #3d4343; }
    .daterangepicker .calendar-table th.available:hover {
      background-color: transparent; }
  .daterangepicker .fa {
    display: block;
    width: 24px;
    height: 24px;
    border: 1px solid #9c9c9c;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 17px 11px;
    background-position: 50% 50%;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 300ms ease-in-out; }
    .daterangepicker .fa.fa-chevron-left {
      background-image: url("../images/svg/page-arrow_left.svg");
      background-position: 35% 50%; }
    .daterangepicker .fa.fa-chevron-right {
      float: right;
      background-image: url("../images/svg/page-arrow_right.svg"); }
    .daterangepicker .fa:hover {
      background-color: #f2f2f2; }
  .daterangepicker__day {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    vertical-align: middle;
    text-align: center;
    font-size: 13px;
    line-height: 2.6;
    color: #333;
    border-radius: 3px;
    transition: background-color 300ms ease-in-out; }
    .daterangepicker__day:hover {
      cursor: pointer;
      background-color: #ffe9c3; }
    .daterangepicker__day.active, .daterangepicker__day.active-range-end, .daterangepicker__day.active-range-start {
      background-color: #ffd488; }
  .daterangepicker.single .ranges {
    text-align: center; }
    .daterangepicker.single .ranges::before {
      display: none; }
  .daterangepicker.opensleft:before {
    right: 40px; }
  .daterangepicker.opensleft:after {
    right: 41px; }

.ui-datepicker-text__label {
  position: relative;
  padding-left: 25px;
  padding-bottom: 0;
  background-image: url("../images/png/datepicker.png");
  background-repeat: no-repeat; }

.ui-datepicker-text__input {
  width: 121px;
  height: 26px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #d1d1d1; }

.ui-datepicker-text__label, .ui-datepicker-text__input {
  display: inline-block;
  vertical-align: middle; }

.ui-select.single .option, .ui-select.multi .option {
  padding: 8px 11px;
  cursor: pointer;
  /* @include font(nth($select_font, 1), nth($select_font, 2), nth($select_font, 3)); */
  transition: background-color 300ms ease-in-out; }
  .ui-select.single .option:hover, .ui-select.multi .option:hover {
    background-color: #f2f2f2; }
  .ui-select.single .option:last-child, .ui-select.multi .option:last-child {
    border-radius: 0 0 5px 5px; }

.ui-select.single .selectize-input,
.ui-select.single .selectize-dropdown, .ui-select.multi .selectize-input,
.ui-select.multi .selectize-dropdown {
  background-color: #ffffff; }

.ui-select.single .selectize-input, .ui-select.multi .selectize-input {
  height: auto;
  padding: 9px 30px 9px 10px;
  background-image: none;
  box-shadow: none;
  transition: none; }
  .ui-select.single .selectize-input input[type="text"], .ui-select.multi .selectize-input input[type="text"] {
    /*  @include font(nth($select_font, 1), nth($select_font, 2), nth($select_font, 3)); */ }
    .ui-select.single .selectize-input input[type="text"]::-webkit-input-placeholder, .ui-select.multi .selectize-input input[type="text"]::-webkit-input-placeholder {
      color: #dadada; }
    .ui-select.single .selectize-input input[type="text"]::-moz-placeholder, .ui-select.multi .selectize-input input[type="text"]::-moz-placeholder {
      color: #dadada; }
    .ui-select.single .selectize-input input[type="text"]:-moz-placeholder, .ui-select.multi .selectize-input input[type="text"]:-moz-placeholder {
      color: #dadada; }
    .ui-select.single .selectize-input input[type="text"]:-ms-input-placeholder, .ui-select.multi .selectize-input input[type="text"]:-ms-input-placeholder {
      color: #dadada; }
  .ui-select.single .selectize-input.focus, .ui-select.multi .selectize-input.focus {
    border-color: #9c9c9c; }
  .ui-select.single .selectize-input.dropdown-active, .ui-select.multi .selectize-input.dropdown-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: #ededed; }
    .ui-select.single .selectize-input.dropdown-active:after, .ui-select.multi .selectize-input.dropdown-active:after {
      border: 0;
      transform: rotate(-180deg); }
  .ui-select.single .selectize-input.disabled, .ui-select.multi .selectize-input.disabled {
    background-color: #f5f5f5; }
    .ui-select.single .selectize-input.disabled::before, .ui-select.multi .selectize-input.disabled::before {
      background: transparent; }
    .ui-select.single .selectize-input.disabled::after, .ui-select.multi .selectize-input.disabled::after {
      opacity: 0.2; }
    .ui-select.single .selectize-input.disabled input[type="text"]::-webkit-input-placeholder,
    .ui-select.single .selectize-input.disabled input[type="text"]:disabled::-webkit-input-placeholder, .ui-select.multi .selectize-input.disabled input[type="text"]::-webkit-input-placeholder,
    .ui-select.multi .selectize-input.disabled input[type="text"]:disabled::-webkit-input-placeholder {
      color: #dadada; }
    .ui-select.single .selectize-input.disabled input[type="text"]::-moz-placeholder,
    .ui-select.single .selectize-input.disabled input[type="text"]:disabled::-moz-placeholder, .ui-select.multi .selectize-input.disabled input[type="text"]::-moz-placeholder,
    .ui-select.multi .selectize-input.disabled input[type="text"]:disabled::-moz-placeholder {
      color: #dadada; }
    .ui-select.single .selectize-input.disabled input[type="text"]:-moz-placeholder,
    .ui-select.single .selectize-input.disabled input[type="text"]:disabled:-moz-placeholder, .ui-select.multi .selectize-input.disabled input[type="text"]:-moz-placeholder,
    .ui-select.multi .selectize-input.disabled input[type="text"]:disabled:-moz-placeholder {
      color: #dadada; }
    .ui-select.single .selectize-input.disabled input[type="text"]:-ms-input-placeholder,
    .ui-select.single .selectize-input.disabled input[type="text"]:disabled:-ms-input-placeholder, .ui-select.multi .selectize-input.disabled input[type="text"]:-ms-input-placeholder,
    .ui-select.multi .selectize-input.disabled input[type="text"]:disabled:-ms-input-placeholder {
      color: #dadada; }

.ui-select.single .selectize-dropdown, .ui-select.multi .selectize-dropdown {
  width: 100%;
  border: 1px solid #9c9c9c;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  box-shadow: none; }
  .ui-select.single .selectize-dropdown [data-selectable] .highlight, .ui-select.multi .selectize-dropdown [data-selectable] .highlight {
    background-color: #d7d7d7; }

.ui-select.selectize-control {
  width: 100%;
  /* min-width: 240px; */
  line-height: 0; }

.ui-select.single .selectize-input .item {
  white-space: nowrap; }

.ui-select.single .selectize-input:after {
  display: block;
  width: 11px;
  height: 6px;
  margin-top: -3px;
  position: absolute;
  top: 50%;
  right: 13px;
  left: auto;
  z-index: 2;
  background-image: url("../images/svg/carret-down.svg");
  background-repeat: no-repeat;
  border: 0; }

.ui-select.single .selectize-input:before {
  content: "";
  display: block;
  width: 45px;
  height: 100%;
  margin-left: -45px;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 30%, white 100%); }

.ui-select__caption {
  display: block;
  font-size: 13px;
  color: #9b9b9b; }

.reg-form .ui-checkboxes,
.sign-loan-form .ui-checkboxes {
  display: inline-block;
  float: left; }
  .reg-form .ui-checkboxes__controll,
  .sign-loan-form .ui-checkboxes__controll {
    display: block;
    float: left;
    padding: 0 0 0 19px;
    position: relative;
    cursor: pointer;
    font-size: 14px; }
    .reg-form .ui-checkboxes__controll:hover .ui-checkboxes__icon,
    .sign-loan-form .ui-checkboxes__controll:hover .ui-checkboxes__icon {
      background-color: #ffe9c3; }
  .reg-form .ui-checkboxes__input,
  .sign-loan-form .ui-checkboxes__input {
    opacity: 0;
    position: absolute;
    left: -9999px; }
    .reg-form .ui-checkboxes__input:checked + .ui-checkboxes__icon,
    .sign-loan-form .ui-checkboxes__input:checked + .ui-checkboxes__icon {
      background-color: #ffe9c3;
      border-color: #9c9c9c; }
      .reg-form .ui-checkboxes__input:checked + .ui-checkboxes__icon .ui-checkboxes__carret,
      .sign-loan-form .ui-checkboxes__input:checked + .ui-checkboxes__icon .ui-checkboxes__carret {
        display: block; }
  .reg-form .ui-checkboxes__icon,
  .sign-loan-form .ui-checkboxes__icon {
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 1px;
    left: 0;
    border: 1px solid #9c9c9c;
    background-color: #fff;
    border-radius: 3px;
    transition: background-color 300ms ease-in-out; }
  .reg-form .ui-checkboxes__carret,
  .sign-loan-form .ui-checkboxes__carret {
    display: none;
    width: 14px;
    height: 13px;
    position: absolute;
    top: 4px;
    left: 10px;
    background-color: transparent;
    border-radius: 0; }
  .reg-form .ui-checkboxes__label,
  .sign-loan-form .ui-checkboxes__label {
    line-height: 1.5; }

.reg-form {
  padding-bottom: 30px; }
  .reg-form .is-md-indent {
    margin-top: 30px; }
  .reg-form .is-lg-indent {
    margin-top: 50px; }
  .reg-form .is-xs-indent {
    margin-top: 10px; }

/* Группировка элементов */
.form-group {
  position: relative;
  text-align: left; }
  .form-group .link, .form-group .daterangepicker .ranges li, .daterangepicker .ranges .form-group li, .form-group span {
    padding-top: 10px;
    display: inline-block; }
  .form-group .ui-checkboxes {
    padding-top: 11px; }
    .form-group .ui-checkboxes.disabled {
      opacity: 0.5;
      cursor: default;
      background-color: #fff; }
  .form-group .ui-checkboxes__controll {
    float: none;
    margin-top: 22px;
    line-height: 1.3; }
    .form-group .ui-checkboxes__controll:first-child {
      margin-top: 0; }
    .form-group .ui-checkboxes__controll:hover {
      cursor: default;
      background-color: #fff; }

@media only screen and (min-width: 480px) {
  .form-group--short {
    width: 80%; } }

.sign-loan-form .sign-input-wrap {
  margin-bottom: 17px; }

.sign-loan-form__params-toggle {
  border-bottom: 1px dashed; }

.sign-loan-form__parameters {
  display: none;
  margin-top: 17px; }

.sign-loan-form .ui-checkboxes {
  float: none;
  margin: 4px 0; }

.calculator {
  color: #181819;
  font-family: "DIN Pro", sans-serif;
  overflow: hidden;
  background-color: #fff; }
  .calculator__header {
    position: relative;
    padding: 18px 0 13px;
    background-color: #fff; }
  .calculator__title {
    font-weight: 400;
    font-size: 23px;
    color: inherit; }
  .calculator__subtitle {
    font-size: 18px;
    margin-top: 9px;
    margin-bottom: 5px; }
  .calculator__cashtime {
    margin-top: 0;
    margin-bottom: 0; }
  .calculator__text-important {
    margin-top: 8px;
    text-align: right;
    font-style: italic;
    font-size: 14px; }
  .calculator__title-hidden {
    position: absolute;
    z-index: -1; }
  .calculator__field {
    padding-top: 11px;
    padding-bottom: 4px;
    font-size: 13px;
    background: #f7f7f7;
    background: linear-gradient(to bottom, #ededed 0%, #ffffff 100%); }
  .calculator__field-header .calculator__field-title {
    float: left; }
  .calculator__field-header .calculator__field-pay-time {
    float: right; }
  .calculator__field-title {
    margin-bottom: 6px; }
  .calculator__pay-time-icon {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle; }
  .calculator__value {
    float: left;
    font-size: 11px;
    text-transform: uppercase; }
  .calculator__value-count {
    font-size: 24px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle; }
    .calculator__value-count small {
      font-size: 11px;
      font-weight: 500; }
    .calculator__value-count--old {
      color: #a6a6a6;
      text-decoration: line-through; }
      .calculator__value-count--old small {
        margin-left: 10px; }
  .calculator__ind {
    font-size: 18px;
    margin-left: 4px;
    margin-right: 10px; }
  .calculator__faq {
    position: relative;
    float: right;
    margin-top: 3px; }
    .calculator__faq:hover .calculator__faq-dropdown {
      opacity: 1;
      visibility: visible; }
  .calculator__faq-trigger {
    font-size: 11px;
    font-style: italic; }
  .calculator__faq-icon {
    margin-left: 5px; }
  .calculator__faq-icon, .calculator__faq-label {
    display: inline-block;
    vertical-align: middle; }
  .calculator__faq-dropdown {
    position: absolute;
    top: 22px;
    right: -16px;
    z-index: 10;
    width: 323px;
    background-color: #fff;
    border: 1px solid #F5B99F;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.21);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.21s linear, visibility 0.21s step-end; }
  .calculator__faq-dropdown-arrow {
    position: absolute;
    top: -3px;
    right: 99px;
    width: 7px;
    height: 7px;
    background: #FFF;
    border: 1px solid #F5B99F;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.21);
    transform: rotate(45deg); }
  .calculator__faq-dropdown-body {
    position: relative;
    font-family: "DIN Pro", sans-serif;
    padding: 15px 25px;
    background-color: #fff; }
    .calculator__faq-dropdown-body p {
      padding-bottom: 0;
      padding-top: 10px; }
  .calculator__faq-dropdown-carret {
    position: absolute;
    top: 15px;
    right: 10px; }
  .calculator__faq-dropdown-title {
    font-size: 24px;
    font-weight: 700;
    color: #2184be; }
  .calculator__faq-dropdown-caption {
    font-weight: 400;
    font-style: italic; }
  .calculator__footer {
    margin-top: 12px;
    padding-top: 14px;
    padding-bottom: 17px;
    background-color: #2184be; }
  .calculator__submit {
    height: 58px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.21); }
    .calculator__submit:hover {
      background-color: #4cad41; }

@media only screen and (min-width: 992px) {
  .calculator {
    position: absolute;
    top: 26px;
    left: 50%;
    margin-left: 60px;
    width: 405px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }
    .calculator__container {
      width: 100%; } }

@media only screen and (min-width: 1200px) {
  .calculator {
    left: 50%;
    margin-left: 160px; } }

.card--news {
  margin-bottom: 48px; }
  .card--news .card__image {
    display: block;
    width: 290px;
    height: 150px; }
  .card--news .card__caption {
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
    padding-left: 18px;
    text-decoration: none;
    color: #181819;
    position: relative;
    transition: color 300ms ease-in-out; }
    .card--news .card__caption:hover {
      color: #2184be; }
      .card--news .card__caption:hover .card__caption-link {
        border-color: #2184be; }
    .card--news .card__caption::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 4px;
      display: block;
      width: 5px;
      height: 5px;
      background-color: #2184be;
      border-radius: 50%; }
  .card--news .card__caption-link {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    border-bottom: 1px dashed #000;
    transition: border-color 300ms ease-in-out; }
  .card--news .card__pubdate {
    margin-top: 8px;
    padding-left: 4px;
    font-size: 11px;
    font-weight: 500;
    font-style: italic; }

@media only screen and (min-width: 768px) {
  .card--news {
    margin-bottom: 68px; }
    .card--news .card__image {
      width: 328px;
      height: 150px; } }

@media only screen and (min-width: 992px) {
  .card--news .card__image {
    width: 300px;
    height: 200px; } }

@media only screen and (min-width: 1200px) {
  .card--news .card__image {
    width: 100%; } }

@media only screen and (min-width: 768px) {
  .cards--news {
    margin-left: -15px;
    margin-right: -15px;
    font-size: 0; }
    .cards--news .card--news {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding-left: 15px;
      padding-right: 15px; } }

@media only screen and (min-width: 992px) {
  .cards--news .card--news {
    width: 33.3333%; } }

.hint {
  font-family: "Roboto", sans-serif; }
  .hint__close {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 10;
    width: 22px;
    height: 22px;
    overflow: hidden;
    cursor: pointer; }
    .hint__close::before, .hint__close::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: -1px;
      background: #868686; }
    .hint__close::before {
      transform: rotate(45deg); }
    .hint__close::after {
      transform: rotate(-45deg); }
  .hint__inner {
    padding: 19px 20px 19px 40px; }
  .hint__title {
    font-size: 16px;
    line-height: 16px;
    color: #4caf50; }
  .hint__header {
    padding-bottom: 8px; }
  .hint__footer {
    margin-top: 10px; }
  .hint__body {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #b5b7b9; }
  .hint__text {
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 0; }
    .hint__text strong {
      color: #51575b; }
  .hint--static {
    display: none;
    position: fixed;
    z-index: 999; }
  .hint--online {
    left: 50px;
    bottom: 50px;
    width: 279px;
    background-color: #fff;
    border: 1px solid #b9b9b9; }
    .hint--online .hint__button {
      font-family: "Roboto", sans-serif;
      background-color: #6abe54;
      padding-left: 7px;
      padding-right: 7px; }

@media only screen and (min-width: 992px) {
  .hint--static {
    display: block; } }

.map-filter__header {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #eee; }

.map-filter__body {
  font-size: 13px; }

.map-filter__title {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 32px;
  font-weight: 500;
  background-image: url("../images/png/header-bottom-address-city.png");
  background-repeat: no-repeat;
  background-position: 2px 50%; }

.map-filter__item {
  padding: 18px 15px;
  border-top: 1px solid #d0d0d0; }
  .map-filter__item b {
    font-size: 14px; }
  .map-filter__item:first-child {
    border-top: 0; }

.map-filter__adress {
  padding-left: 20px;
  background-image: url("../images/png/house.png");
  background-repeat: no-repeat;
  background-position: 0 1px; }

.map-filter__worktime {
  margin-top: 8px;
  padding-left: 20px;
  background-image: url("../images/png/clock.png");
  background-repeat: no-repeat;
  background-position: 0 0; }

@media only screen and (min-width: 768px) {
  .map-filter {
    width: 375px;
    max-height: 440px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 6px; }
    .map-filter__header {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #eee; }
    .map-filter__body {
      font-size: 13px; }
    .map-filter__title {
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 32px;
      font-weight: 500;
      background-image: url("../images/png/header-bottom-address-city.png");
      background-repeat: no-repeat;
      background-position: 2px 50%; }
    .map-filter__item {
      padding: 18px 15px;
      border-top: 1px solid #d0d0d0; }
      .map-filter__item b {
        font-size: 14px; }
      .map-filter__item:first-child {
        border-top: 0; }
    .map-filter__adress {
      padding-left: 20px;
      background-image: url("../images/png/house.png");
      background-repeat: no-repeat;
      background-position: 0 1px; }
    .map-filter__worktime {
      margin-top: 8px;
      padding-left: 20px;
      background-image: url("../images/png/clock.png");
      background-repeat: no-repeat;
      background-position: 0 0; } }

body .modal:not(.ui) {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 6px; }
  body .modal:not(.ui) .modal__close.mfp-close {
    width: 32px;
    height: 32px;
    margin: 8px;
    background-image: url("../images/svg/times-o.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%; }
  body .modal:not(.ui) .modal___header {
    padding-top: 29px;
    padding-bottom: 12px;
    text-align: center; }
  body .modal:not(.ui) .modal__body,
  body .modal:not(.ui) .modal__header,
  body .modal:not(.ui) .modal__footer {
    padding: 16px 32px; }
  body .modal:not(.ui) .modal__body {
    text-align: left; }
  body .modal:not(.ui) .modal__footer {
    padding-top: 20px;
    padding-bottom: 0;
    border-top: 1px solid #ededed;
    text-align: left; }
  body .modal:not(.ui) .modal__title {
    font-size: 24px;
    line-height: 1;
    text-align: left; }
  body .modal:not(.ui)--medium {
    width: 95%;
    border-radius: 5px;
    padding: 30px; }
  body .modal:not(.ui)__preloader {
    display: none;
    text-align: center; }
  body .modal:not(.ui)__countdown {
    margin-bottom: 15px;
    text-align: center; }
  body .modal:not(.ui)--whyLeave .form__label {
    width: 75px; }
  body .modal:not(.ui)--whyLeave .form__control {
    width: 310px; }
  body .modal:not(.ui)--whyLeave .row {
    margin-bottom: 20px; }
  body .modal:not(.ui)--pod-ft-agreement {
    width: 60%; }
    body .modal:not(.ui)--pod-ft-agreement ul {
      list-style-type: disc;
      text-align: justify; }
      body .modal:not(.ui)--pod-ft-agreement ul li {
        margin-left: 20px; }

@media only screen and (min-width: 768px) {
  body .modal:not(.ui) {
    width: 480px; }
    body .modal:not(.ui)--pod-ft-agreement {
      width: 60%; } }

.contract .modal__title {
  font-size: 24px;
  margin-bottom: 70px; }

.contract .modal__text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px; }

.contract .modal__form {
  text-align: left;
  margin: 0 auto;
  width: 70%;
  margin-bottom: 70px; }

.contract .modal__input {
  border-radius: 0;
  border: 1px solid #abaaaa;
  padding: 20px;
  background-color: #f7f7f7;
  width: 100%;
  display: block;
  margin-bottom: 10px; }

.contract .modal__link {
  /* display: inline-block; */
  color: #3d4343;
  line-height: 1.2;
  border-bottom: 1px solid;
  cursor: pointer; }

.contract .modal__buttons .button, .contract .modal__buttons .btn-major, .contract .modal__buttons .btn-minor {
  margin: 0 10px 10px; }

@media only screen and (min-width: 768px) {
  .contract {
    width: 685px; } }

.pager {
  text-align: center; }
  .pager__label {
    display: block;
    margin-bottom: 10px; }
  .pager .pagination {
    list-style: none; }
  .pager .pagination li {
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    margin-right: 3px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: transparent; }
    .pager .pagination li.is-active {
      border-color: #2184be; }
      .pager .pagination li.is-active a {
        cursor: default; }
  .pager .pagination a {
    display: block;
    width: 100%;
    height: 100%;
    color: #000;
    line-height: 24px; }

.content-payment {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center; }
  .content-payment__icon {
    width: 50px; }
  .content-payment__logo {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .content-payment__logotype {
    width: 228px;
    height: 131px;
    background-image: url("../images/paysystems/content-payment-paySystems-item-bg.jpg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px; }
  .content-payment__body {
    margin-top: 20px; }
  .content-payment__point {
    width: 5px;
    height: 5px;
    margin: 23px auto 12px;
    background-color: #2184be;
    border-radius: 50%; }
  .content-payment__caption {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 700;
    font-family: "DIN Pro", sans-serif; }
  .content-payment__footer {
    margin-top: 30px; }
  .content-payment__button {
    padding: 10px 10px 10px 48px; }
    .content-payment__button .button__label {
      margin-right: 10px; }

@media only screen and (min-width: 768px) {
  .content-payment {
    margin-top: 57px;
    margin-bottom: 57px; }
    .content-payment__icon {
      width: 66px; }
    .content-payment__body {
      margin-top: 20px; }
    .content-payment__carousel {
      font-size: 0; }
    .content-payment__item {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      margin-bottom: 10px; }
    .content-payment__point {
      margin-top: 26px; } }

@media only screen and (min-width: 992px) {
  .content-payment {
    margin-top: 70px;
    margin-bottom: 70px; }
    .content-payment__icon {
      width: auto; }
    .content-payment__item {
      width: 25%; } }

@media only screen and (min-width: 1200px) {
  .content-payment__logotype {
    width: 280px;
    height: 141px; } }

.content-request {
  margin-top: 90px;
  margin-bottom: 40px;
  text-align: center; }
  .content-request__icon {
    width: 40px; }
  .content-request__body {
    margin-top: 125px;
    background: #f4f3f4;
    background: linear-gradient(to bottom, #f4f3f4 0%, #fff 100%); }
  .content-request .money-request {
    margin-top: -95px; }

@media only screen and (min-width: 768px) {
  .content-request {
    margin-top: 70px; }
    .content-request__icon {
      width: 50px;
      margin-bottom: 5px; }
    .content-request__body {
      margin-top: 160px; }
    .content-request .money-request {
      margin-top: -120px; } }

@media only screen and (min-width: 992px) {
  .content-request {
    margin-top: 95px; }
    .content-request__icon {
      width: 55px;
      margin-bottom: 1px; }
    .content-request__footer {
      margin-top: 30px; }
    .content-request .money-request {
      margin-top: -126px; } }

@media only screen and (min-width: 1200px) {
  .content-request {
    margin-top: -11px;
    overflow: hidden; }
    .content-request__icon {
      width: 55px;
      margin-bottom: 1px; }
    .content-request__footer {
      margin-top: 30px; }
    .content-request .money-request {
      margin-top: -126px; } }

.content-request-info {
  margin-top: 94px;
  text-align: left;
  font-family: "DIN Pro", sans-serif; }
  .content-request-info__item {
    margin-top: 38px;
    overflow: hidden; }
  .content-request-info__arrow {
    display: none; }
  .content-request-info__preview {
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.3); }
  .content-request-info__preview-image {
    vertical-align: top; }
  .content-request-info__text {
    margin-top: 20px;
    font-size: 15px;
    line-height: 1.4; }
  .content-request-info__caption {
    padding-bottom: 20px; }
  .content-request-info__user {
    display: none; }

@media only screen and (min-width: 768px) {
  .content-request-info {
    margin-top: 72px; }
    .content-request-info__item {
      margin-top: 28px; }
      .content-request-info__item:nth-child(odd) {
        text-align: right; }
    .content-request-info__text {
      display: inline-block;
      width: 60%;
      margin-top: 20px;
      font-size: 17px; }
    .content-request-info__title {
      padding-bottom: 22px; }
    .content-request-info__caption {
      padding-bottom: 20px; } }

@media only screen and (min-width: 992px) {
  .content-request-info {
    width: 902px;
    margin-top: 76px;
    margin-left: auto;
    margin-right: auto; }
    .content-request-info__item {
      position: relative;
      margin-top: 61px;
      padding-bottom: 10px;
      overflow: hidden; }
      .content-request-info__item:nth-child(odd) {
        text-align: left; }
        .content-request-info__item:nth-child(odd) .content-request-info__text {
          float: left; }
        .content-request-info__item:nth-child(odd) .content-request-info__preview {
          float: right; }
    .content-request-info__arrow {
      position: absolute;
      top: 8px;
      left: 50%;
      margin-left: -35px;
      display: block; }
    .content-request-info__text {
      display: block;
      float: right;
      width: 47%;
      margin-top: -7px; }
    .content-request-info__caption {
      padding-bottom: 20px; } }

@media only screen and (min-width: 1200px) {
  .content-request-info {
    position: relative; }
    .content-request-info__user {
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: 450px;
      display: block; } }

.money-request {
  padding: 39px 14px;
  font-weight: 400;
  font-family: "DIN Pro", sans-serif;
  text-align: left;
  background: url("../images/backgrounds/pattern.png");
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  border-radius: 40px; }
  .money-request__inner {
    padding: 31px 20px;
    background: url("../images/requests/content-request-calc.jpg");
    background-size: cover;
    border-radius: 40px; }
  .money-request__title {
    font-size: 24px; }
  .money-request__slider {
    margin-top: 18px; }
  .money-request__slider-caption {
    margin-top: -5px; }
  .money-request__total {
    margin-top: 10px; }
  .money-request__calc {
    height: 57px;
    padding-top: 8px;
    text-align: center;
    font-size: 36px;
    line-height: 1;
    color: #fff;
    background-color: #45b649;
    border-radius: 30px; }
    .money-request__calc small {
      font-size: 11px; }
  .money-request__button {
    margin-top: 6px; }
  .money-request__submit {
    width: 100%; }
  .money-request--double .money-request__inner {
    padding-top: 29px;
    padding-bottom: 29px; }
  .money-request--double .money-request__slider {
    margin-top: 0;
    margin-bottom: 2px; }
  .money-request--double .money-request__slider-prepend {
    font-size: 24px;
    margin-bottom: 5px; }
    .money-request--double .money-request__slider-prepend small {
      font-size: 11px;
      margin-left: 8px; }
  .money-request--double .money-request__label {
    font-size: 15px;
    margin-bottom: 12px; }

@media only screen and (min-width: 768px) {
  .money-request {
    padding: 63px 20px; }
    .money-request__inner {
      padding-top: 41px;
      padding-bottom: 31px; }
    .money-request__title {
      float: left; }
    .money-request__slider {
      margin-left: 170px;
      margin-top: -3px; }
    .money-request__slider-caption {
      margin-top: -8px; }
    .money-request__total {
      margin-top: 23px;
      text-align: center; }
    .money-request__calc, .money-request__button {
      display: inline-block;
      vertical-align: top; }
    .money-request__calc {
      padding-left: 30px;
      padding-right: 65px; }
    .money-request__button {
      margin-top: 0;
      margin-left: -50px; }
    .money-request__submit {
      width: auto;
      padding-right: 30px;
      padding-left: 30px; }
    .money-request--double .money-request__holder {
      overflow: hidden; }
    .money-request--double .money-request__slider {
      width: 48%;
      float: left;
      margin-left: 0; }
      .money-request--double .money-request__slider:last-child {
        float: right; }
    .money-request--double .money-request__total {
      margin-top: 0; } }

@media only screen and (min-width: 992px) {
  .money-request__inner {
    padding: 32px 25px; }
  .money-request__title {
    margin-top: 15px; }
  .money-request__slider {
    width: 228px;
    margin-left: 15px;
    margin-top: 11px;
    float: left; }
  .money-request__slider-caption {
    margin-top: -6px;
    line-height: 1.2; }
  .money-request__total {
    margin-top: 0;
    text-align: right; }
  .money-request__submit {
    width: auto;
    padding-right: 30px;
    padding-left: 30px; }
  .money-request--double .money-request__inner {
    padding-top: 15px;
    padding-bottom: 20px;
    overflow: hidden; }
  .money-request--double .money-request__holder {
    width: 45%;
    float: left; }
  .money-request--double .money-request__slider {
    margin-bottom: 0;
    padding-bottom: 0; }
  .money-request--double .money-request__total {
    float: right; }
  .money-request--double .money-request__label {
    text-align: left; } }

@media only screen and (min-width: 1200px) {
  .money-request {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 16px;
    padding-right: 16px; }
    .money-request__inner {
      padding-left: 22px;
      padding-right: 22px; }
    .money-request__slider {
      width: 380px;
      margin-left: 20px; }
    .money-request__slider-caption {
      margin-top: 2px; }
    .money-request--double .money-request__holder {
      width: 52%; }
    .money-request--double .money-request__slider {
      margin-bottom: 0;
      padding-bottom: 0; }
    .money-request--double .money-request__total {
      float: right; }
    .money-request--double .money-request__label {
      text-align: left; } }

.socials {
  margin-left: -5px;
  font-size: 11px; }
  .socials__item {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px; }
    .socials__item--label {
      display: block;
      margin-bottom: 5px; }
  .socials__image {
    display: block; }

@media only screen and (min-width: 768px) {
  .socials__item--label {
    display: inline-block;
    margin-bottom: 0; } }

.content-steps {
  background-color: #f0f3f0; }
  .content-steps__list {
    padding-top: 22px;
    padding-bottom: 22px; }
  .content-steps__item {
    width: 290px;
    height: 95px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%; }
    .content-steps__item--first {
      background-image: url("../images/steps/01-xs.png"); }
      .content-steps__item--first .content-steps__caption {
        padding-top: 18px; }
    .content-steps__item--second {
      margin-top: -22px;
      background-image: url("../images/steps/02-xs.png"); }
    .content-steps__item--third {
      margin-top: -11px;
      background-image: url("../images/steps/03-xs.png"); }
  .content-steps__caption {
    padding: 31px 18px 18px 97px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.4;
    font-family: "DIN Pro", sans-serif; }

@media only screen and (min-width: 768px) {
  .content-steps__list {
    padding-top: 12px;
    padding-bottom: 12px;
    overflow: hidden; }
  .content-steps__item {
    height: 91px;
    float: left;
    background-size: 100% 100%; }
    .content-steps__item--first {
      width: 250px;
      background-image: url("../images/steps/01-sm.png"); }
      .content-steps__item--first .content-steps__caption {
        padding-top: 28px;
        padding-left: 64px; }
    .content-steps__item--second {
      width: 266px;
      margin-top: 0;
      margin-left: -32px;
      background-image: url("../images/steps/02-sm.png"); }
    .content-steps__item--third {
      width: 265px;
      margin-top: 0;
      margin-left: -32px;
      background-image: url("../images/steps/03-sm.png"); }
  .content-steps__caption {
    padding-top: 28px; } }

@media only screen and (min-width: 992px) {
  .content-steps__item {
    height: 125px; }
    .content-steps__item--first {
      width: 302px;
      background-image: url("../images/steps/01-md.png"); }
      .content-steps__item--first .content-steps__caption {
        padding-top: 40px;
        padding-left: 78px; }
    .content-steps__item--second {
      width: 368px;
      margin-left: -44px;
      background-image: url("../images/steps/02-md.png"); }
    .content-steps__item--third {
      width: 356px;
      margin-top: 0;
      margin-left: -47px;
      background-image: url("../images/steps/03-md.png"); }
  .content-steps__caption {
    padding-top: 40px;
    padding-left: 142px;
    font-size: 15px; } }

@media only screen and (min-width: 1200px) {
  .content-steps__item--first {
    width: 375px;
    background-image: url("../images/steps/01-lg.png"); }
    .content-steps__item--first .content-steps__caption {
      padding-left: 130px; }
  .content-steps__item--second {
    width: 424px;
    margin-left: -44px;
    background-image: url("../images/steps/02-lg.png"); }
  .content-steps__item--third {
    width: 424px;
    margin-left: -43px;
    background-image: url("../images/steps/03-lg.png"); }
  .content-steps__caption {
    padding-left: 194px;
    font-size: 17px; } }

.table {
  width: 100%;
  margin-bottom: 51px;
  /* Поиск для таблицы */ }
  .table__row {
    cursor: pointer; }
    .table__row.line .table__col {
      height: 6px;
      padding: 0;
      border-top: 1px solid #f2f2f2;
      background-color: transparent;
      cursor: default; }
    .table__row.ts-row-fixed {
      position: fixed;
      visibility: hidden;
      z-index: 1020;
      background-color: #f2f2f2; }
    .table__row.ts-row-section .table__col, .table__row.ts-row-fixed .table__col {
      background-color: #f2f2f2; }
    .table__row.ts-row-section b, .table__row.ts-row-fixed b {
      margin-right: 30px; }
    .table__row.ts-row-section span, .table__row.ts-row-fixed span {
      margin-left: 10px;
      margin-right: 10px; }
    .table__row:not(.line):not(.sticky-header):hover .table__col {
      background-color: #ffefd4; }
    .table__row--personal-info {
      margin-bottom: 18px; }
  .table__title {
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    text-align: left; }
    .table__title .sort,
    .table__title a {
      color: #3d4343;
      text-decoration: none; }
      .table__title .sort.asc::after,
      .table__title a.asc::after {
        display: inline-block;
        content: "";
        position: relative;
        top: inherit;
        left: inherit;
        margin-left: 8px;
        vertical-align: middle; }
      .table__title .sort.desc::after,
      .table__title a.desc::after {
        display: inline-block;
        content: "";
        position: relative;
        top: inherit;
        left: inherit;
        margin-left: 8px;
        vertical-align: middle; }
    .table__title.text-center {
      text-align: center; }
    .table__title.text-right {
      text-align: right; }
  .table__col, .table__title {
    padding-left: 20px;
    padding-right: 20px; }
  .table__col {
    padding-top: 10px;
    padding-bottom: 10px;
    transition: background-color 300ms ease-in-out;
    min-width: 100px; }
    .table__col:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px; }
    .table__col:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px; }
  .table--fitted td {
    box-sizing: border-box; }
  .table--fitted .table__row:not(.line):not(.sticky-header):hover .table__col {
    background-color: #ffefd4 !important;
    cursor: pointer; }
  .table .id {
    width: 50px; }
  .table .tag, .table .tag-as-list {
    margin-left: -10px; }
    .table .tag.right-ind, .table .right-ind.tag-as-list {
      margin-left: 3px; }

.table--adaptive .thead {
  display: none; }

.table--adaptive .table__row {
  display: block;
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
  padding: 10px 0; }

.table--adaptive .table__row.disabled {
  color: #b7b5b5; }

.table--adaptive .table__row:not(.line):not(.sticky-header):hover .table__col {
  background: none; }

.table--adaptive .table__col {
  display: block;
  text-align: right;
  font-size: 13px;
  border: 0;
  width: 100%; }

.table--adaptive .table__col:before {
  content: attr(data-label);
  float: left;
  font-weight: bold;
  display: block; }

@media only screen and (min-width: 768px) {
  .table--adaptive .thead {
    display: table-header-group;
    border-bottom: 1px solid #f0f0f0; }
  .table--adaptive .table__row {
    display: table-row;
    box-shadow: none; }
  .table--adaptive .table__col {
    display: table-cell;
    text-align: left;
    width: auto;
    font-size: 15px; }
    .table--adaptive .table__col.date {
      font-size: 13px;
      color: #b7b5b5; }
  .table--adaptive .table__col, .table--adaptive .table__title {
    padding-left: 15px;
    padding-right: 15px; }
  .table--adaptive .table__col:before {
    display: none; }
  .table--adaptive .table__row:not(.line):not(.sticky-header):hover .table__col {
    background: #ffefd4; } }

@media only screen and (min-width: 992px) {
  .table--adaptive .table__col.date {
    font-size: 15px;
    color: inherit; }
  .table--adaptive .table__col, .table--adaptive .table__title {
    padding-left: 20px;
    padding-right: 20px; } }

.tab-section {
  display: none;
  border-top: 1px solid #f0f0f0; }
  .tab-section--line {
    border: 0; }

.tab {
  display: none; }

.tab-label {
  display: inline-block;
  margin-right: -5px;
  padding: 15px 18px;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  border-right: 1px solid #f0f0f0;
  cursor: pointer;
  position: relative; }
  .tab-label--line {
    border: 0;
    padding: 0;
    font-size: 13px;
    margin-right: 10px;
    font-weight: 400; }

.tab:checked + .tab-label {
  color: #2184be; }

#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4,
#tab5:checked ~ #content5 {
  display: block; }

@media only screen and (min-width: 768px) {
  .tab-section {
    padding: 30px; }
    .tab-section--line {
      padding: 20px 0 0 0; }
  .tab-label {
    margin-right: -4px;
    padding: 25px 30px;
    font-size: 18px; }
    .tab-label--line {
      font-size: 14px;
      margin: 0 15px 0 0;
      padding: 0; } }

/* .tabs-wrap {
   overflow: hidden;
}

.tabs {
  width: auto;
}


.tabs {
  a {
    margin-right: 15px;
  }
}

a.btn:hover {
  color: #fff;
} */
.tag, .tag-as-list {
  display: inline-block;
  padding: 5px 10px;
  vertical-align: middle;
  font-size: 13px;
  line-height: 1;
  color: #fff;
  border-radius: 4px; }
  .tag.expects, .expects.tag-as-list {
    background-color: #f36f36; }
  .tag.new, .new.tag-as-list {
    background-color: #7cb446; }
  .tag.plan, .plan.tag-as-list {
    background-color: #fcaf26; }
  .tag.freeze, .freeze.tag-as-list {
    background-color: #00afff; }
  .tag.fault, .fault.tag-as-list {
    background-color: #d6d6d6; }
  .tag.reminder, .reminder.tag-as-list {
    background-color: #feee70; }
  .tag.reminder, .reminder.tag-as-list {
    color: #3d4343; }
  .tag.error, .error.tag-as-list {
    background-color: #ea5c26; }
  .tag.blacklist, .blacklist.tag-as-list {
    background-color: #000000; }
  .tag.visit, .visit.tag-as-list {
    background-color: #7365e0; }
  .tag.online-request, .online-request.tag-as-list {
    background-color: #2fc193; }
  .tag.pdf, .pdf.tag-as-list {
    background-color: #f91728; }
  .tag.xls, .xls.tag-as-list {
    background-color: #208e40; }
  .tag.docx, .docx.tag-as-list {
    background-color: #4175db; }
  .tag.rar, .rar.tag-as-list {
    background-color: #b067b6; }
  .tag.issued, .issued.tag-as-list {
    background-color: #5cb94e; }
  .tag.disabled, .disabled.tag-as-list {
    background-color: #d6d6d6; }

.tag-as-list::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 5px;
  margin-top: -2px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%; }

.content-teasers {
  margin-top: 42px;
  margin-bottom: 13px; }
  .owl-carousel .owl-item .content-teasers__image {
    width: auto;
    margin-left: auto;
    margin-right: auto; }
  .owl-carousel .owl-item .content-teasers__border {
    width: auto;
    display: none; }
  .content-teasers__point {
    width: 5px;
    height: 5px;
    margin: 25px auto 12px;
    background-color: #2184be;
    border-radius: 50%; }
  .content-teasers__caption {
    text-align: center;
    font-size: 17px;
    line-height: 1.3;
    font-weight: 500;
    font-family: "DIN Pro", sans-serif;
    padding: 0; }

@media only screen and (min-width: 768px) {
  .content-teasers__carousel {
    font-size: 0; }
  .content-teasers__item {
    display: inline-block;
    width: 50%;
    position: relative;
    padding-top: 212px;
    margin-bottom: 35px;
    vertical-align: top;
    text-align: center; }
  .content-teasers__image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .content-teasers__border {
    display: none; } }

@media only screen and (min-width: 1200px) {
  .content-teasers {
    margin-top: 80px; }
    .content-teasers__carousel {
      margin-left: -2%;
      margin-right: -2%; }
    .content-teasers__item {
      width: 21%;
      padding-top: 202px;
      margin-bottom: 35px;
      margin-left: 2%;
      margin-right: 2%; }
      .content-teasers__item:first-child .content-teasers__image {
        top: 20px; }
      .content-teasers__item:first-child .content-teasers__border {
        margin-left: -10px; }
      .content-teasers__item:last-child .content-teasers__image {
        top: 23px; }
    .content-teasers__image:hover {
      animation: zoom-in-out 0.36s ease-in-out; }
    .content-teasers__border {
      position: absolute;
      top: 90px;
      left: 100%;
      margin-left: -25px;
      display: block; } }

.range-slider .irs-line-mid,
.range-slider .irs-line-left,
.range-slider .irs-line-right,
.range-slider .irs-bar,
.range-slider .irs-bar-edge,
.range-slider .irs-slider {
  background-image: none; }

.range-slider .irs-line {
  top: 12px;
  height: 6px; }

.range-slider .irs-line-mid, .range-slider .irs-line-left, .range-slider .irs-line-right {
  height: 100%; }

.range-slider .irs-line-mid, .range-slider .irs-line-left, .range-slider .irs-line-right {
  background-color: #4cad41; }

.range-slider .irs-line-left {
  width: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.range-slider .irs-line-right {
  width: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.range-slider .irs-line-mid {
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 3px; }

.range-slider .irs-slider {
  top: 0;
  width: 29px;
  height: 29px;
  background-image: url("../images/png/content-common-calc-bar.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  border-radius: 50%;
  box-shadow: 5px 5px 10px #999; }

.range-slider .irs-bar {
  left: 0 !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }
  .range-slider .irs-bar, .range-slider .irs-bar-edge {
    top: 12px;
    height: 6px;
    background-color: #2184be; }
  .range-slider .irs-bar-edge {
    width: 0; }

.owl-carousel {
  padding-bottom: 23px; }
  .owl-carousel .owl-dots {
    margin-top: 10px;
    text-align: center; }
  .owl-carousel .owl-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: #d3d3d3;
    border-radius: 50%;
    transition: background-color 300ms ease-in-out; }
    .owl-carousel .owl-dot.active {
      background-color: #2184be; }

.tooltip {
  width: 200px;
  height: 200px;
  border: 1px solid #2184be;
  position: absolute;
  background: #fff;
  display: none;
  transition: .3s;
  position: absolute;
  left: -93px;
  top: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 5; }
  .tooltip:before, .tooltip:after {
    content: '';
    border: 10px solid transparent;
    width: 0px;
    height: 0px;
    border-bottom-color: #fff;
    background-color: transparent;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip:before {
    border-bottom-color: #2184be;
    top: -21px; }
  .tooltip img {
    display: block;
    max-width: 130px;
    max-height: 130px;
    margin: 20px auto 15px; }

.tooltip-btn {
  margin-left: 2px;
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url("../images/svg/tooltip.svg") no-repeat;
  transition: .3s;
  position: relative;
  vertical-align: middle; }
  .tooltip-btn:hover .tooltip {
    display: block; }

.tt-menu,
.tt-dropdown-menu {
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }

.tt-suggestion {
  padding: 0px 20px;
  font-size: 18px;
  line-height: 24px; }
  .tt-suggestion p {
    margin: 0; }
  .tt-suggestion:hover, .tt-suggestion.tt-cursor, .tt-suggestion.tt-is-under-cursor {
    color: #fff;
    background-color: #0097cf;
    cursor: pointer; }

.form-group .twitter-typeahead {
  display: block !important;
  padding: 0 !important; }

.ui.hide {
  display: none !important; }

.ui input {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.ui.button, .ui.btn-major, .ui.btn-minor {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.ui .segment {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.ui .search .message.empty {
  font-size: 13px !important; }

.ui.horizontal.normal.divider {
  font-weight: normal !important; }

.ui.menu {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.ui.card {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.ui.w100 {
  width: 100% !important; }

.ui.m-b-0 {
  margin-bottom: 0 !important; }

.ui.m-t-0 {
  margin-top: 0 !important; }

.ui.message {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.ui.search > .results {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }
  .ui.search > .results > .result .title {
    font-weight: normal !important; }

.ui.attached.steps {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important; }

.ui.modal {
  padding: 0px !important; }
  .ui.modal > .header {
    position: relative; }

@media only screen and (max-width: 480px) {
  .hide-mobile {
    display: none !important; }
  .ui.massive.buttons .button, .ui.massive.buttons .btn-major, .ui.massive.buttons .btn-minor,
  .ui.massive.buttons .or,
  .ui.massive.button,
  .ui.massive.btn-major,
  .ui.massive.btn-minor {
    font-size: 1.28571429rem; } }

.contacts {
  margin-bottom: -27px;
  line-height: 1.5; }
  .contacts__title {
    margin: 0;
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700; }
  .contacts__list {
    font-size: 24px;
    float: left; }
  .contacts__item {
    padding-top: 13px;
    padding-bottom: 13px; }
    .contacts__item--phones {
      border-bottom: 1px solid #d0d0d0; }
    .contacts__item--email {
      font-size: 13px;
      padding-left: 20px;
      background-image: url("../images/png/letter.png");
      background-repeat: no-repeat;
      background-position: 0 50%; }
  .contacts__body {
    position: relative;
    margin-top: 25px; }
  .contacts__map {
    width: 100%;
    height: 300px; }

@media only screen and (min-width: 768px) {
  .contacts {
    position: relative; }
    .contacts__map-filter {
      position: absolute;
      top: 25px;
      left: 50%;
      z-index: 5;
      margin-left: -362px; }
    .contacts__map {
      width: 100%;
      height: 490px; } }

@media only screen and (min-width: 992px) {
  .contacts__map-filter {
    margin-left: -475px; } }

@media only screen and (min-width: 1200px) {
  .contacts__map-filter {
    margin-left: -575px; } }

.content-banner__logo, .content-banner__parallax {
  display: none; }

@media only screen and (min-width: 992px) {
  .content-banner {
    position: relative; }
    .content-banner__logo, .content-banner__parallax {
      display: block; }
    .content-banner__parallax {
      height: 580px;
      position: relative;
      overflow: hidden; }
    .content-banner__area, .content-banner__city, .content-banner__case, .content-banner__family, .content-banner__usurer {
      position: absolute;
      right: 50%;
      background-repeat: no-repeat; }
    .content-banner__city, .content-banner__area {
      width: 2000px;
      margin-right: -1000px;
      background-position: center top; }
    .content-banner__area {
      top: 427px;
      height: 147px;
      background-image: url("../images/content-banner/content-banner-area.jpg"); }
    .content-banner__city {
      top: -3px;
      height: 435px;
      background-image: url("../images/content-banner/content-banner-city.jpg"); }
    .content-banner__family {
      top: 139px;
      width: 541px;
      height: 338px;
      margin-right: -278px;
      background-image: url("../images/content-banner/content-banner-family.png"); }
    .content-banner__usurer {
      top: 55px;
      width: 245px;
      height: 434px;
      margin-right: 257px;
      background-image: url("../images/content-banner/content-banner-usurer.png"); }
    .content-banner__case {
      top: 398px;
      width: 170px;
      height: 120px;
      margin-right: 203px;
      background-image: url("../images/content-banner/content-banner-case.png"); }
    .content-banner__logo {
      position: absolute;
      left: 50%;
      bottom: -75px;
      margin-left: -93px; } }

@media only screen and (min-width: 1200px) {
  .content-banner__family {
    margin-right: -178px; }
  .content-banner__usurer {
    margin-right: 357px; }
  .content-banner__case {
    margin-right: 303px; } }

.content-whatis {
  text-align: center;
  margin-top: -13px;
  font-family: "DIN Pro", sans-serif; }
  .content-whatis__icon {
    width: 55px;
    margin-bottom: -5px; }
  .content-whatis__title {
    line-height: 1.2;
    margin-bottom: 16px; }
  .content-whatis__subtitle {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5;
    font-style: italic;
    text-align: left; }
  .content-whatis__image {
    margin-top: 34px;
    margin-bottom: 12px;
    padding-bottom: 4px;
    border-bottom: 1px solid #d1d4d8; }
    .content-whatis__image--clear {
      padding-bottom: 0;
      border-bottom: 0; }
  .content-whatis__caption {
    text-align: left;
    font-size: 15px;
    line-height: 1.5; }
  .content-whatis__money-request {
    margin-top: 130px;
    padding-bottom: 50px;
    background: linear-gradient(to bottom, #f0f0f1 0%, #fefefe 100%); }
  .content-whatis .money-request {
    margin-top: -100px; }

@media only screen and (min-width: 768px) {
  .content-whatis {
    margin-top: -19px; }
    .content-whatis__icon {
      width: 65px;
      margin-bottom: 8px; }
    .content-whatis__title {
      line-height: 1.2;
      margin-bottom: 16px; }
    .content-whatis__subtitle {
      font-size: 16px;
      line-height: 1.4;
      text-align: center; }
    .content-whatis__holder {
      overflow: hidden;
      margin-top: 33px; }
    .content-whatis__image {
      width: 46%;
      float: left;
      margin-top: 10px;
      margin-left: 15px;
      margin-bottom: 0;
      padding-bottom: 4px; }
      .content-whatis__image--clear {
        padding-bottom: 0;
        border-bottom: 0; }
    .content-whatis__caption {
      width: 50%;
      float: left;
      padding-left: 30px; }
    .content-whatis__caption-text {
      padding-bottom: 20px; } }

@media only screen and (min-width: 992px) {
  .content-whatis {
    margin-top: -19px; }
    .content-whatis__title {
      line-height: 1.2;
      margin-bottom: 16px; }
    .content-whatis__subtitle {
      font-size: 18px; }
    .content-whatis__image {
      width: 40%;
      float: left;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0; }
      .content-whatis__image--clear {
        width: 50%; }
    .content-whatis__caption {
      width: 60%;
      float: left;
      padding-left: 30px; }
      .content-whatis__caption--clear {
        width: 50%; }
    .content-whatis__caption-text {
      font-size: 17px;
      padding-bottom: 20px; } }

@media only screen and (min-width: 1200px) {
  .content-whatis {
    margin-top: 0; }
    .content-whatis__holder {
      margin-left: 100px;
      margin-right: 100px; }
    .content-whatis__image {
      width: 40%; }
      .content-whatis__image--clear {
        width: 50%; }
    .content-whatis__caption {
      width: 60%; }
      .content-whatis__caption--clear {
        width: 50%; }
    .content-whatis__caption-text {
      font-size: 17px;
      padding-bottom: 20px; } }

.news-deatail {
  font-size: 15px; }
  .news-deatail__pubdate {
    font-size: 11px;
    font-weight: 500;
    font-style: italic;
    line-height: 1;
    margin-bottom: 19px; }
  .news-deatail__caption {
    margin-top: 19px;
    padding: 0; }
  .news-deatail__socials {
    margin-top: 30px; }

.payment {
  padding-bottom: 30px; }
  .payment__title {
    margin-bottom: 30px; }
  .payment__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto; }

.payment-form {
  flex-basis: 100%;
  margin-bottom: 30px; }
  .payment-form__title {
    font-size: 18px; }
  .payment-form .form__label {
    border-right: 1px solid #c7c1be;
    padding-right: 10px; }
  .payment-form .ui-checkboxes--vertical .ui-checkboxes__controll {
    font-size: 13px;
    line-height: 1.5;
    padding-top: 0; }
  .payment-form .ui-checkboxes__icon {
    top: 1px; }

.payment-info {
  flex-basis: 100%; }
  .payment-info__title {
    font-family: "Open Sans";
    font-size: 18px;
    margin-bottom: 10px; }
  .payment-info__content {
    background: #ffffff;
    padding: 10px 15px 20px;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 20px; }
  .payment-info__row {
    display: block; }
  .payment-info__name, .payment-info__value {
    display: inline-block;
    padding: 10px 0; }
  .payment-info__name {
    width: 33%; }
  .payment-info__img {
    margin-right: 40px;
    vertical-align: middle; }
  .payment-info__img:last-child {
    margin-right: 0; }
  .payment-info__button {
    width: 245px; }

.payment-add-info {
  margin-top: 20px;
  width: 100%; }

@media only screen and (min-width: 768px) {
  .payment__title {
    margin-bottom: 50px; }
  .payment-form, .payment-info {
    width: 63%; }
  .payment-add-info {
    width: 63%; }
  .payment-info__name {
    width: 33%; } }

@media only screen and (min-width: 992px) {
  .payment__inner {
    flex-direction: row;
    justify-content: space-between;
    align-items: start; }
  .payment__title {
    width: 100%; }
  .payment-form {
    flex-basis: 46%;
    width: 50%; }
  .payment-info {
    flex-basis: 48%;
    width: 50%; }
  .payment-add-info {
    width: 100%; } }

/*------------repay----------------*/
.repay__inner {
  margin-left: -15px;
  margin-right: -15px; }

.repay__subtitle {
  font-size: 27px;
  font-family: "Open Sans";
  font-weight: 300;
  margin: 40px 0 20px 0; }

.repay__col {
  padding-left: 15px;
  padding-right: 15px; }

.repay__info {
  padding: 20px;
  background: #e8f4e7;
  font-size: 13px;
  line-height: 1.6153;
  margin-top: 15px; }

.repay-panel {
  width: 100%;
  padding: 0 10px 0 10px;
  border: 1px solid #ececec;
  margin-top: 10px;
  height: 70px;
  position: relative;
  transition: .3s linear; }
  .repay-panel:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .repay-panel:after {
    content: '';
    height: 12px;
    width: 12px;
    display: block;
    border: 2px solid #2184be;
    border-right-width: 0;
    border-top-width: 0;
    transform: translateY(-50%) rotate(-135deg);
    position: absolute;
    right: 20px;
    top: 50%; }
  .repay-panel__icon {
    display: inline-block;
    height: 100%;
    width: 65px;
    text-align: center;
    margin-right: 15px;
    float: left;
    padding-top: 5px; }
  .repay-panel__img {
    transform: translateY(-50%);
    margin-top: 50%; }
  .repay-panel__text {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 18px;
    line-height: 70px; }

@media only screen and (min-width: 768px) {
  .repay {
    padding-bottom: 30px; }
    .repay__col {
      width: 48%; }
      .repay__col:not(last-child) {
        float: left; }
    .repay__subtitle {
      margin-top: 51px; } }

@media only screen and (min-width: 992px) {
  .repay__inner {
    max-width: 90%; } }

@media only screen and (min-width: 1200px) {
  .repay__col {
    width: calc(100% / 3); }
    .repay__col:last-child {
      float: left; } }

.payment-form .form__field-inner {
  width: 100%; }

.payment-form .form__fieldset > div {
  margin-top: 5px; }

.payment-form .form__fieldset .control-label {
  margin-bottom: 0px; }

.main-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px; }
  .main-inner__content {
    flex-basis: 100%;
    margin-top: -50px;
    position: relative;
    z-index: 15; }
  .main-inner__header {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    min-width: 280px;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    background: #fff;
    box-shadow: -5px 0 20px rgba(0, 0, 0, 0.13);
    z-index: 10;
    padding-bottom: 50px;
    position: relative; }

@media only screen and (min-width: 768px) {
  .main-inner__content {
    flex-basis: 100%;
    margin-top: 0;
    background: #fff;
    margin-top: 0;
    box-shadow: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.13); }
  .main-inner__header {
    padding-bottom: 0; } }

@media only screen and (min-width: 992px) {
  .main-wrap {
    padding-top: 0; } }

@media only screen and (min-width: 1200px) {
  .main-inner {
    flex-wrap: nowrap; }
    .main-inner__content {
      flex-basis: 920px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.13); }
    .main-inner__header {
      flex-basis: 280px;
      box-shadow: -5px 0 20px rgba(0, 0, 0, 0.13); } }

.user {
  display: flex;
  margin: 27px 20px 19px;
  position: relative; }
  .user__name {
    font-weight: 700;
    margin-left: 15px;
    word-break: break-all; }
  .user__firstname {
    font-size: 21px; }
  .user__pic {
    width: 85px;
    height: 85px; }
  .user__num {
    position: absolute;
    bottom: 0;
    left: 100px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1; }

.add-loan {
  width: 100%;
  min-width: 280px;
  text-align: center;
  padding: 22px;
  background: #ffffff;
  border-top: 1px solid #f0f0f0; }
  .add-loan__caption {
    color: #a9b7b7;
    font-size: 12px;
    line-height: 1;
    font-style: italic;
    letter-spacing: -.3px; }
  .add-loan__button {
    display: block;
    padding: 20px 25px 20px 25px;
    color: #fff;
    font-family: "DIN Pro", sans-serif, sans-serif;
    font-size: 16px;
    font-weight: 700;
    border-radius: 10px;
    background-color: #1ea8e0;
    position: relative;
    transition: .3s;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.21); }
    .add-loan__button:hover, .add-loan__button:active, .add-loan__button:focus {
      color: #fff;
      background-color: #2184be; }
    .add-loan__button--disabled {
      cursor: default;
      background-color: #d6d6d6;
      box-shadow: 0 0 0 1px #d6d6d6;
      color: #fff; }
      .add-loan__button--disabled:hover, .add-loan__button--disabled:active, .add-loan__button--disabled:focus {
        background-color: #d6d6d6; }

@media only screen and (min-width: 768px) {
  .add-loan {
    width: auto; } }

/* --------------------------секция займ------------------------- */
.loan__wrap {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
  margin-top: 20px; }

.loan__backlink {
  display: inline-block; }

.loan__num {
  font-size: 24px;
  font-weight: 400;
  margin: 15px 0 10px; }

.loan__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.loan__docs {
  flex-basis: 100%; }

.loan__info {
  flex-basis: 100%; }

.loan .panel {
  background: #f2f2f2;
  padding: 25px;
  border-radius: 5px;
  width: 260px; }
  .loan .panel .panel__title {
    font-size: 16px;
    margin-bottom: 15px; }
  .loan .panel .panel__file-list {
    margin-bottom: 15px; }

.loan .docs-link {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  font-size: 14px;
  line-height: 30px; }

.loan .tag, .loan .tag-as-list {
  letter-spacing: -0.7px; }

.info-list__item {
  font-size: 0;
  margin-top: 14px; }
  .info-list__item.is-large-indent {
    margin-top: 33px; }
  .info-list__item.is-no-indent {
    margin-top: 0; }

.info-list__column {
  display: inline-block;
  vertical-align: middle; }
  .info-list__column.short {
    width: 45%; }
  .info-list__column.long {
    width: 55%; }
  .info-list__column.is-left-margin {
    margin-left: 24px; }
  .info-list__column.is-single {
    margin-left: 45%; }
  .info-list__column.valign-top {
    vertical-align: top; }
  .info-list__column.valign-bottom {
    vertical-align: bottom; }

.info-list__name, .info-list__value {
  font-size: 14px; }

.info-list__button {
  margin: 30px 0 40px; }

@media only screen and (min-width: 768px) {
  .loan__wrap {
    padding: 0;
    box-shadow: none;
    margin-top: 0; }
  .loan__num {
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle; }
  .loan__status {
    font-size: 15px; }
  .loan__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .loan__docs {
    flex-basis: 45%; }
  .loan__info {
    flex-basis: 45%; }
  .loan .panel {
    width: 300px; }
  .loan .docs-link {
    width: 250px;
    font-size: 15px; }
  .loan .tag, .loan .tag-as-list {
    letter-spacing: normal; } }

/* ------------------------ секция анкета ---------------------------------*/
.anketa {
  font-size: 0;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
  margin-top: 20px; }
  .anketa .h3 {
    font-size: 21px;
    font-weight: 400;
    padding: 0;
    margin: 0; }
  .anketa__block {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 30px;
    padding-bottom: 10px; }
  .anketa__block:last-child {
    border-bottom: none;
    margin-bottom: 0; }
  .anketa .info-list__item {
    margin-bottom: 25px; }
  .anketa .info-list__name {
    font-size: 14px; }
  .anketa .info-list__value {
    font-size: 17px; }

@media only screen and (min-width: 768px) {
  .anketa {
    padding: 30px;
    box-shadow: none;
    margin-top: 0; }
    .anketa .info-list__item.md {
      padding-right: 20px;
      width: calc((100% / 2) - 20px);
      display: inline-block; }
    .anketa .info-list__item.sm {
      padding-right: 20px;
      width: calc((100% / 3) - 20px);
      display: inline-block; } }

@media only screen and (min-width: 992px) {
  .anketa .info-list__item.md {
    width: calc((100% / 3) - 20px);
    display: inline-block; } }

/* ----------------------------секция документы -----------------------------*/
.docs-table {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.13);
  margin-top: 20px; }
  .docs-table .info-list__item {
    margin-bottom: 20px; }
  .docs-table .info-list__column {
    width: 50%; }
  .docs-table .info-list__column.short {
    width: 100%;
    margin-bottom: 10px; }
  .docs-table .info-list__name {
    width: 220px; }
  .docs-table .info-list__value .file {
    display: block;
    white-space: nowrap;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .docs-table .info-list__column.loan-date {
    display: none; }
  .docs-table .info-list__button {
    margin: 20px 0 10px; }

@media only screen and (min-width: 768px) {
  .docs-table {
    padding: 30px;
    box-shadow: none;
    margin-top: 0; }
    .docs-table .info-list__name {
      width: 100%; } }

@media only screen and (min-width: 992px) {
  .docs-table .info-list__item {
    border-radius: 5px;
    padding: 15px;
    transition: .3s;
    margin-bottom: 0; }
    .docs-table .info-list__item:hover {
      background-color: #fff0d3; }
  .docs-table .info-list__column.short {
    width: 50%;
    margin-bottom: 0; }
  .docs-table .info-list__column {
    width: calc((100% - 50%) / 3); }
  .docs-table .info-list__column.loan-date {
    display: inline-block; }
  .loan .panel {
    width: 400px; }
  .loan .docs-link {
    width: 360px; } }

.button--repay-loan {
  margin-top: 51px; }

.loan__payments {
  margin-top: 51px; }

.reg-main {
  font-family: "Roboto", sans-serif; }
  .reg-main .h1 {
    margin-bottom: 35px;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 200;
    font-family: inherit; }
  .reg-main .h3 {
    padding-bottom: 12px;
    margin-bottom: 14px;
    font-size: 21px;
    font-weight: 700;
    font-family: inherit; }
  .reg-main .h3.reg-finish-text {
    font-size: 18px; }
  .reg-main .h4 {
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    font-family: inherit; }
  .reg-main .link, .reg-main .daterangepicker .ranges li, .daterangepicker .ranges .reg-main li {
    border-bottom: 1px solid;
    color: inherit; }
  .reg-main .m-lg-right {
    margin-right: 25px; }

.reg-form .selectize-dropdown, .reg-form .selectize-input, .reg-form .selectize-input input {
  font-size: 14px; }

@media only screen and (min-width: 768px) {
  .reg-main {
    /* padding-bottom: 100px;*/ }
    .reg-main .h1 {
      font-size: 36px; }
    .reg-main .h3 {
      font-size: 24px; }
    .reg-main .h3.reg-finish-text {
      font-size: 20px;
      margin: 130px 0 30px; }
    .reg-main .h4 {
      font-size: 19px; }
    .reg-main .m-lg-right {
      margin-right: 50px; } }

@media only screen and (min-width: 992px) {
  .reg-main {
    padding-top: 24px;
    /*padding-bottom: 100px;*/ }
    .reg-main .h1 {
      font-size: 48px; } }

/*-----------------progress bar -----------------------*/
.progressbar {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px; }

#progress {
  height: 40px;
  border-radius: 10px;
  background-color: #a3afb8;
  color: #fff;
  width: 100%;
  border: 0;
  overflow: hidden;
  position: relative;
  background: -moz-linear-gradient(right, #ffc53a, #2bc32e);
  background: -webkit-linear-gradient(right, #ffc53a, #2bc32e);
  background: linear-gradient(to left, #ffc53a, #2bc32e);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg); }

#progress::-webkit-progress-bar {
  height: 40px;
  background-color: #a3afb8;
  background: -moz-linear-gradient(right, #ffc53a, #2bc32e);
  background: -webkit-linear-gradient(right, #ffc53a, #2bc32e);
  background: linear-gradient(to left, #ffc53a, #2bc32e); }

#progress::-webkit-progress-value {
  height: 40px;
  background-color: #a3afb8; }

#progress::-moz-progress-bar {
  height: 40px;
  background-color: #a3afb8; }

.progress-message {
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #fff; }

@media only screen and (min-width: 768px) {
  .progress-message {
    left: 35%;
    transform: none; } }

@media only screen and (min-width: 992px) {
  .progressbar {
    width: 70%; } }

.active:not(.ui.modal) {
  color: #eb6734;
  text-decoration: none;
  cursor: default; }

.wizard {
  overflow: visible; }

.wizard > .content {
  overflow: visible;
  margin: 0;
  min-height: auto; }

.wizard > .content > .body {
  position: static;
  width: 100%;
  padding: 0 2px; }

.wizard > .steps > ul {
  line-height: 3;
  overflow: auto;
  display: table;
  width: 70%;
  margin: 0 auto; }

.wizard > .steps > ul > li {
  width: auto;
  display: table-cell;
  float: none; }

.wizard > .content > .body input.error {
  border: 1px solid #f12309;
  color: #8a1f11;
  background-color: #ffffff; }

.wizard > .content > .body label.error {
  margin-left: 0;
  color: #f12309; }

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
  padding: 0; }

.wizard > .steps .number {
  display: none; }

.wizard > .steps .current a {
  background: none;
  color: #077492; }

.wizard > .steps .current a:hover, .wizard > .steps .current a:active {
  color: #077492;
  background: none; }

.wizard > .steps .disabled a {
  background: none;
  color: inherit; }

.wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
  color: inherit;
  background: none; }

.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
  color: #9d9c9c;
  background: none; }

.wizard > .content {
  background: none; }

.wizard fieldset {
  border: none;
  padding: 0 2px; }

.wizard > .actions {
  margin-top: 30px; }

.wizard > .actions a {
  background: #1ea8e0;
  transition: background-color 300ms ease-in-out;
  height: 45px;
  text-align: center;
  line-height: 45px;
  padding: 0;
  text-transform: uppercase; }

.wizard > .actions a:hover {
  background-color: #2184be;
  height: 45px;
  text-align: center;
  line-height: 45px;
  padding: 0;
  text-transform: uppercase; }

.wizard > .actions a:active {
  background-color: #2184be; }

.wizard > .actions a.btn-minor {
  background: #fff;
  color: inherit; }

.wizard > .actions a.btn-minor:hover, .wizard > .actions a.btn-minor:active {
  background-color: #f2f2f2; }

.wizard > .steps {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  height: 30px; }

.wizard > .actions a[href="#previous"] {
  background: #fff;
  color: inherit;
  border: 1px solid #d6d6d6; }

.wizard > .actions .disabled a[href="#previous"] {
  display: none; }

.wizard > .actions > ul {
  width: 100%; }

.wizard > .actions > ul > li {
  margin: 0;
  width: 47%;
  float: none;
  display: inline-block; }

.wizard > .actions > ul > li:first-child {
  margin-right: 4%; }

.wizard > .actions a.disabled {
  opacity: 0.5; }

/*----------form error tooltip--------------*/
input.error {
  position: relative;
  margin-left: 0; }

label.error {
  width: auto;
  font-size: 13px;
  line-height: 1.5;
  display: none;
  color: #fb9e8a; }

.ui-checkboxes__controll label.error {
  bottom: 100%; }

.ui-checkboxes__controll input.error {
  position: absolute; }

@media only screen and (min-width: 480px) {
  .wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
    width: 140px; }
  .wizard > .actions > ul > li {
    width: auto; }
  .wizard > .actions > ul > li:first-child {
    margin-right: 10px; } }

.wizard > .content > .body > .dop-contacts-phone label {
  display: none; }

.sms-code > .actions {
  display: none; }

.form-group {
  position: relative; }
  .form-group__inner {
    position: relative; }

.num-short {
  display: none; }

.num-long {
  font-weight: 700;
  margin-bottom: 10px; }

.photo-wrap {
  border: 1px dashed #dadada;
  text-align: center; }

@media only screen and (min-width: 768px) {
  .dop-contacts input[type="text"]::-webkit-input-placeholder, .dop-contacts input[type="tel"]::-webkit-input-placeholder {
    color: transparent; }
  .dop-contacts input[type="text"]:-moz-placeholder, .dop-contacts input[type="tel"]:-moz-placeholder {
    /* Firefox 18- */
    color: transparent; }
  .dop-contacts input[type="text"]::-moz-placeholder, .dop-contacts input[type="tel"]::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent; }
  .dop-contacts input[type="text"]:-ms-input-placeholder, .dop-contacts input[type="tel"]:-ms-input-placeholder {
    /* IE 10+ */
    color: transparent; }
  .dop-contacts input[type="text"]::-ms-input-placeholder, .dop-contacts input[type="tel"]::-ms-input-placeholder {
    /* Edge */
    color: transparent; }
  .dop-contacts .dop-contacts-phone label {
    display: inline-block; }
  .dop-contacts .dop-contacts-phone .selectize-input input {
    opacity: 0; }
  .dop-contacts .form-group {
    position: relative; }
  .dop-contacts .num-short {
    display: block;
    color: #b1b1b1;
    position: absolute;
    left: -10px;
    bottom: 10px; }
  .dop-contacts .num-long {
    display: none; }
  .dop-contacts .photo-wrap {
    border: 1px dashed #dadada; }
  .wizard.more-info > .content > .body {
    padding: 3.5%; }
  .wizard > .content > .body > .dop-contacts-phone label {
    display: inline-block; } }

.more-info .steps {
  display: none; }

.more-info .client-photo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .more-info .client-photo__col {
    flex-basis: 100%;
    text-align: center; }
    .more-info .client-photo__col:first-child {
      margin-bottom: 10px; }
  .more-info .client-photo__wrap {
    border: 1px dashed #dadada;
    text-align: center;
    width: 210px;
    height: 210px;
    overflow: hidden;
    position: relative;
    margin: 0 auto; }
    .more-info .client-photo__wrap img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .more-info .client-photo__add {
    background: #e8e8e8 url("../images/svg/camera.svg") no-repeat;
    background-size: 55px 45px;
    background-position: 50% 30%; }
  .more-info .client-photo__btn {
    position: absolute;
    left: 50%;
    bottom: 25px;
    z-index: 10;
    transform: translateX(-50%);
    text-transform: uppercase;
    width: 166px;
    padding: 0;
    text-align: center; }
  .more-info .client-photo span {
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 10;
    transform: translateX(-50%); }
  .more-info .client-photo__template {
    width: 100px;
    margin: 20px 0 10px; }

@media only screen and (min-width: 768px) {
  .more-info .client-photo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .more-info .client-photo__col {
      flex-basis: 320px; }
      .more-info .client-photo__col:first-child {
        margin-bottom: 0;
        margin-right: 20px; }
    .more-info .client-photo__wrap {
      width: 320px;
      height: 320px; }
    .more-info .client-photo__template {
      width: 170px;
      margin: 30px 0 30px; }
    .more-info .client-photo__add {
      background-size: 80px 65px;
      background-position: 50% 40%; } }

/*----------------------------------*/
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.inputfile + label {
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  cursor: default;
  position: relative;
  width: 100%;
  height: 37px;
  border: 1px solid #dadada;
  resize: none;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #3d4343;
  border-radius: 5px;
  box-sizing: border-box; }

.inputfile + label span {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
  height: 100%;
  line-height: 37px;
  padding: 0 0 0 150px;
  box-sizing: border-box;
  cursor: default; }

.inputfile + label strong {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  width: 140px;
  height: 100%;
  color: #fff;
  background: #1ea8e0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 37px;
  cursor: pointer; }

.reg-form .ui-select.single .selectize-input {
  height: 37px;
  padding: 0;
  padding-left: 10px;
  line-height: 37px; }

.signin {
  max-width: 370px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .signin__title {
    font-size: 30px;
    margin-bottom: 30px;
    font-family: "Roboto", sans-serif; }
  .signin__man, .signin__money {
    display: none; }
  .signin__button-enter {
    width: 245px;
    background-image: url("../images/png/user.png");
    background-position: 70% 50%;
    background-repeat: no-repeat; }
  .signin .form__field-inner {
    width: 100%;
    margin-bottom: 10px; }

@media only screen and (min-width: 768px) {
  .signin {
    padding-top: 48px;
    padding-bottom: 48px; }
    .signin__title {
      font-size: 30px; } }

@media only screen and (min-width: 992px) {
  .signin {
    padding-top: 88px;
    padding-bottom: 68px; }
    .signin__man {
      position: absolute;
      right: 120%;
      bottom: 0;
      z-index: 2;
      width: 178px;
      height: 437px;
      display: block; }
    .signin--recovery {
      padding-bottom: 133px; } }

@media only screen and (min-width: 1200px) {
  .signin__man {
    right: 140%; }
  .signin__money {
    position: absolute;
    left: 110%;
    bottom: 0;
    z-index: 2;
    width: 351px;
    height: 237px;
    display: block; } }

.signup {
  max-width: 370px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .signup__title {
    font-size: 30px;
    margin-bottom: 4px;
    font-family: "Roboto", sans-serif; }
  .signup__subtitle {
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 21px; }
  .signup__man, .signup__money {
    display: none; }
  .signup__button {
    height: 42px;
    padding-top: 10px;
    border-radius: 30px; }
  .signup .ui-checkboxes--vertical {
    display: block; }
    .signup .ui-checkboxes--vertical .ui-checkboxes__controll {
      font-size: 11px;
      line-height: 1.5;
      font-style: italic; }
  .signup .ui-datepicker-text {
    font-size: 12px;
    margin-top: 21px; }

@media only screen and (min-width: 768px) {
  .signup {
    padding-top: 48px; }
    .signup__title {
      font-size: 30px; }
    .signup .ui-datepicker-text {
      float: right;
      margin-top: 0; }
    .signup .ui-checkboxes {
      float: left;
      margin-top: 3px; }
      .signup .ui-checkboxes.ui-checkboxes--vertical {
        float: none;
        margin-top: 0; } }

@media only screen and (min-width: 992px) {
  .signup {
    padding-top: 0; }
    .signup__man {
      position: absolute;
      right: 120%;
      bottom: 20px;
      z-index: 2;
      width: 178px;
      height: 437px;
      display: block; } }

@media only screen and (min-width: 1200px) {
  .signup__man {
    right: 140%; }
  .signup__money {
    position: absolute;
    left: 110%;
    bottom: 20px;
    z-index: 2;
    width: 351px;
    height: 237px;
    display: block; } }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.fl2 {
  display: flex;
  align-items: center; }
  .fl2.fl2-top {
    align-items: flex-start; }
  .fl2.fl2-start {
    justify-content: flex-start; }
  .fl2.fl2-end {
    justify-content: flex-end; }
  .fl2.fl2-between {
    justify-content: space-between; }
  .fl2.fl2-space-10 > div:not(:first-child) {
    margin-left: 10px; }

.fl-grow-1 {
  flex-grow: 1; }

.w100 {
  width: 100%; }

#gridAuth {
  margin-top: 80px;
  margin-bottom: 80px; }
  #gridAuth form {
    text-align: left; }
  #gridAuth > .grid-auth-centered {
    max-width: 285px;
    min-width: 285px;
    margin: 0 auto; }

#rootLoader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #ffffff;
  display: flex;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  z-index: 1000; }
  #rootLoader > div {
    display: flex;
    align-items: center;
    justify-content: center; }
    #rootLoader > div > div {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: #f4a836;
      position: relative;
      -webkit-animation: 1.2s grow ease-in-out infinite;
      animation: 1.2s grow ease-in-out infinite; }
    #rootLoader > div > div:nth-child(2) {
      -webkit-animation: 1.2s grow ease-in-out infinite 0.15555s;
      animation: 1.2s grow ease-in-out infinite 0.15555s;
      margin: 0 20px; }
    #rootLoader > div > div:nth-child(3) {
      -webkit-animation: 1.2s grow ease-in-out infinite 0.3s;
      animation: 1.2s grow ease-in-out infinite 0.3s; }

.root-component-container-list > div > div:not(:last-child) {
  margin-bottom: 20px; }

.contract-accept-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px; }
  .contract-accept-main .sms-confirm-main {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between; }
    .contract-accept-main .sms-confirm-main > a {
      margin-left: 5px; }
    .contract-accept-main .sms-confirm-main input[type='text'] {
      width: 100px; }

.main-loan-ready .sign-body-list {
  margin: 20px 0px;
  position: relative; }
  .main-loan-ready .sign-body-list > div:not(:last-child) {
    margin-bottom: 9px; }
  .main-loan-ready .sign-body-list:after {
    content: ""; }

.main-loan-ready .main-sign-sms-list-codes {
  position: relative;
  padding-left: 35px; }
  .main-loan-ready .main-sign-sms-list-codes .in-sms-valid-code {
    position: relative;
    background: white;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .main-loan-ready .main-sign-sms-list-codes .in-sms-valid-code > .form {
      margin-bottom: 20px; }
    .main-loan-ready .main-sign-sms-list-codes .in-sms-valid-code > a:last-child {
      margin-top: 25px; }
  .main-loan-ready .main-sign-sms-list-codes .timer-left-registration {
    margin-bottom: 10px; }

.main-loan-ready .carret-down-up-link {
  color: black; }
  .main-loan-ready .carret-down-up-link.subheader {
    color: #404040; }

@-webkit-keyframes grow {
  0%, 40%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes grow {
  0%, 40%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.continue-auth-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .continue-auth-photo video {
    max-width: 100%; }
  .continue-auth-photo canvas {
    max-width: 100%; }
  .continue-auth-photo .imgTitle.centered {
    max-width: 280px;
    outline: 1px solid #e2e2e2; }
  .continue-auth-photo > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .continue-auth-photo > div > .ui.buttons {
      margin-top: 30px; }

.photo-continue-auth-steps {
  padding-right: 12px;
  min-width: 285px;
  max-width: 285px; }
  .photo-continue-auth-steps .step {
    cursor: pointer !important;
    position: relative;
    flex-wrap: nowrap !important;
    /*&.photo-status-type-0 {
      position:relative;
      >:after{
        content: "";
        display: block;
        position:absolute;
        width:5px;
        left:0;
        top:0;
        bottom:0;
        background-color:rgb(232, 232, 232) !important;
      }
    }
    &.photo-status-type-1 {
      position:relative;
      >:after{
        content: "";
        display: block;
        position:absolute;
        width:5px;
        left:0;
        top:0;
        bottom:0;
        background-color:#db2828 !important;
      }

    }
    &.photo-status-type-2 {
      position:relative;
      >:after{
        content: "";
        display: block;
        position:absolute;
        width:5px;
        left:0;
        top:0;
        bottom:0;
        background-color:#21ba45 !important;
      }
    }*/ }
    .photo-continue-auth-steps .step > .content > i.icon {
      position: absolute;
      left: 2px;
      top: 50%;
      margin-top: -8px;
      font-size: 20px;
      /*margin-left: -11px;*/ }

.last-step-end-text {
  font-size: 18px;
  line-height: 1; }

.fixed-full-capture-screen {
  position: fixed;
  background: rgba(254, 254, 233, 0.74);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center; }
  .fixed-full-capture-screen video {
    max-width: 90%;
    max-height: 90vh; }
  .fixed-full-capture-screen canvas {
    max-width: 90%;
    width: 60%; }
  .fixed-full-capture-screen .vd-main {
    text-align: center; }
    .fixed-full-capture-screen .vd-main img {
      max-width: 90%;
      max-height: 90vh; }
  .fixed-full-capture-screen .capture-actions {
    text-align: center; }

.main-chat-block-container {
  background: #fffff3;
  padding: 10px;
  border: 1px solid #c1c157; }
  .main-chat-block-container .main-chat-block-messages {
    overflow-y: auto;
    max-height: 445px;
    padding-bottom: 10px;
    padding-right: 10px;
    background: #efefd9;
    padding: 5px;
    padding-bottom: 13px;
    position: relative; }
    .main-chat-block-container .main-chat-block-messages .chat-item-message {
      display: flex;
      align-items: center; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-icon {
        width: 50px;
        min-width: 50px;
        height: 50px;
        background: green;
        color: white;
        line-height: 50px;
        border-radius: 50%;
        text-align: center;
        margin-right: 10px;
        align-self: flex-start; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text {
        padding: 5px 15px;
        border-radius: 9px;
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        background: #ecffec; }
        .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .photo-in-message.errImg img {
          border: 2px solid #f57070; }
        .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .photo-in-message.small {
          max-width: 200px; }
        .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text img {
          max-width: 100%; }
        .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text > p:last-child {
          margin-bottom: 0; }
        .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .chat-capture-canvas {
          margin-top: 5px; }
          .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .chat-capture-canvas video {
            max-width: 100%;
            width: 100%; }
          .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .chat-capture-canvas canvas {
            max-width: 100%;
            width: 100%; }
        .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .ui.message > p {
          margin-bottom: 0px !important; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text:not([type="text"]) {
        padding: 0px;
        background: transparent; }
    .main-chat-block-container .main-chat-block-messages .chat-item-message:not(:last-child) {
      margin-bottom: 9px; }
    .main-chat-block-container .main-chat-block-messages .chat-item-message[is-user="1"] {
      flex-direction: row-reverse; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message[is-user="1"] .chat-item-icon {
        margin-right: 0px;
        margin-left: 10px;
        background: orange; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message[is-user="1"] .chat-item-message-text[type="text"] {
        background: #ffeaba; }
    .main-chat-block-container .main-chat-block-messages .chat-item-message[is-user="1"] + .chat-item-message[is-user="1"] .chat-item-icon {
      display: none; }
    .main-chat-block-container .main-chat-block-messages .chat-item-message[is-user="1"] + .chat-item-message[is-user="1"] .chat-item-message-text {
      margin-right: 60px; }
    .main-chat-block-container .main-chat-block-messages .chat-item-message[is-user="0"] + .chat-item-message[is-user="0"] .chat-item-icon {
      display: none; }
    .main-chat-block-container .main-chat-block-messages .chat-item-message[is-user="0"] + .chat-item-message[is-user="0"] .chat-item-message-text {
      margin-left: 60px; }
  .main-chat-block-container .main-chat-block-actions[exist="1"] {
    margin-top: 10px; }
  .main-chat-block-container .main-chat-block-actions .chat-action-photo .action-list {
    display: flex;
    width: 100%; }
    .main-chat-block-container .main-chat-block-actions .chat-action-photo .action-list > a {
      flex-grow: 1; }
  .main-chat-block-container .main-chat-block-actions .chat-action-add-card .action-list {
    display: flex;
    width: 100%; }
    .main-chat-block-container .main-chat-block-actions .chat-action-add-card .action-list > a {
      flex-grow: 1; }

.ui.message .in-ui-message-content p {
  margin-bottom: 0px !important; }

.user-block-credit-limit > .credit-limit-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /*justify-content: flex-start;*/
  /*>div:not(:last-child) {
      margin-right: 20px;
    }*/ }
  .user-block-credit-limit > .credit-limit-main .cl-round-block {
    display: flex;
    align-items: center; }
    .user-block-credit-limit > .credit-limit-main .cl-round-block > div:first-child {
      font-size: 30px;
      padding: 20px;
      border: 2px solid #b3b3b3;
      border-radius: 50%;
      /*color: #b3b3b3;*/
      margin-right: 10px; }
    .user-block-credit-limit > .credit-limit-main .cl-round-block .clrb-tb {
      color: #949494; }
    .user-block-credit-limit > .credit-limit-main .cl-round-block .clrb-pb {
      font-weight: bold; }
    .user-block-credit-limit > .credit-limit-main .cl-round-block .clrb-lb {
      text-decoration: underline; }

.request-history-item {
  padding: 5px 10px;
  border: 1px solid #d0d0d0;
  /*margin: 2px 0px;*/
  box-shadow: 0px 1px 4px -1px #b3b3b3; }
  .request-history-item > div.rhi-data {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .request-history-item > div.rhi-data[onlybutton="1"] {
      justify-content: flex-start; }
    .request-history-item > div.rhi-data[is-operations="1"] {
      margin-bottom: 10px; }
    .request-history-item > div.rhi-data > .request-content {
      flex-grow: 1;
      padding-bottom: 5px; }
      .request-history-item > div.rhi-data > .request-content > .header {
        font-weight: normal !important; }
        .request-history-item > div.rhi-data > .request-content > .header > .sub:first-child {
          margin-top: 3px; }
    .request-history-item > div.rhi-data > .request-status {
      flex-grow: 1; }
    .request-history-item > div.rhi-data > .request-actions {
      align-self: stretch;
      display: flex;
      flex-direction: column; }
      .request-history-item > div.rhi-data > .request-actions > .request-date {
        text-align: right;
        color: #7d7d7d; }
      .request-history-item > div.rhi-data > .request-actions > .request-action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        flex-grow: 1; }
        .request-history-item > div.rhi-data > .request-actions > .request-action > a {
          margin-right: 0px !important; }

.list-operations-main > div {
  padding-left: 60px;
  padding-bottom: 20px;
  position: relative; }
  .list-operations-main > div:after {
    content: "";
    position: absolute;
    width: 2px;
    background: #d4d4d4;
    left: 28px;
    top: 0;
    bottom: 0px; }
  .list-operations-main > div > div {
    display: flex;
    flex-direction: column;
    position: relative;
    /*&:after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        background: $list_operation_main_color;
        @include border-radius(50%);
        left: -8px;
        top: 50%;
        margin-top: -20px;
        margin-left: -5px;
      }*/ }
    .list-operations-main > div > div .operation-date {
      color: grey; }
    .list-operations-main > div > div .operation-amount-type {
      font-weight: bold; }
    .list-operations-main > div > div:before {
      content: "";
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background: #d4d4d4;
      margin-top: -1px;
      left: -32px;
      top: 50%; }

.list-operations-main > div:first-child {
  /* color:green;*/ }
  .list-operations-main > div:first-child:after {
    top: -5px; }

.list-operations-main > div:last-child {
  /*color:green;*/ }
  .list-operations-main > div:last-child:after {
    bottom: 50%;
    margin-bottom: 10px; }

.main-loan-content {
  display: flex;
  align-items: stretch;
  width: 100%; }
  .main-loan-content > div {
    width: 50%;
    padding: 5px; }
  .main-loan-content > div:first-child {
    border-right: 1px solid #dedede;
    padding-right: 10px;
    display: flex; }
  .main-loan-content > div:nth-child(2) {
    padding-left: 10px; }
  .main-loan-content .loan-sliders-main {
    width: 100%;
    align-self: center;
    padding-right: 15px; }
    .main-loan-content .loan-sliders-main .label-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold; }
    .main-loan-content .loan-sliders-main .ui-slider-horizontal .ui-slider-handle {
      width: 30px;
      height: 30px;
      top: -10px;
      cursor: pointer;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%; }
      .main-loan-content .loan-sliders-main .ui-slider-horizontal .ui-slider-handle.ui-state-active {
        border: 1px solid #9FCF65;
        background: #0C933E; }
    .main-loan-content .loan-sliders-main .ui-slider {
      margin: 20px 0px; }
  .main-loan-content .loan-list-values > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 250px;
    margin-bottom: 5px; }
    .main-loan-content .loan-list-values > div > div:nth-child(2) {
      font-weight: bold; }
  .main-loan-content .load-actions {
    margin-top: 24px; }
  .main-loan-content .sms-confirm-block > div:first-child {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }

.extend-service-list-description > div {
  margin-top: 20px; }
  .extend-service-list-description > div .eds-description ul {
    margin-left: 10px;
    list-style: none; }
    .extend-service-list-description > div .eds-description ul li:before {
      content: "— "; }

.extend-service-list {
  margin-top: 20px; }

.main-history-request .extend-service-list > div:not(:last-child) {
  margin-bottom: 5px; }

.service-list-links > li {
  padding: 10px; }
  .service-list-links > li:before {
    content: "- "; }
  .service-list-links > li > a {
    text-decoration: underline;
    color: #444444; }

.page-settings-main .main-accordion > div {
  border: 1px solid #dcdcdc; }
  .page-settings-main .main-accordion > div .header-accordion {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    font-size: 16px;
    padding: 20px;
    background-color: #f9f9f9; }
  .page-settings-main .main-accordion > div .content-accordion {
    padding: 10px 20px; }

.page-settings-main .main-accordion > div.accordion-close .content-accordion {
  color: green;
  display: none; }

.main-loan-ready-content {
  display: flex;
  align-items: stretch;
  width: 100%; }
  .main-loan-ready-content > div {
    width: 50%;
    padding: 5px; }
  .main-loan-ready-content > div:first-child {
    border-right: 1px solid #dedede;
    padding-right: 10px;
    display: flex; }
  .main-loan-ready-content > div:nth-child(2) {
    padding-left: 10px; }
  .main-loan-ready-content .loan-ready-list-params > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    /* max-width: 280px;*/
    margin-bottom: 5px; }
    .main-loan-ready-content .loan-ready-list-params > div > div:nth-child(2) {
      font-weight: bold; }
  .main-loan-ready-content .loan-ready-list-documents > a {
    display: block;
    margin-bottom: 5px; }

.main-loan-ready-content[status="4"] > div:first-child {
  border-right: 0;
  padding-right: 0;
  width: 50%;
  display: flex; }

.main-card-page .payment-cards-list .payment-card-item {
  margin-bottom: 7px; }
  .main-card-page .payment-cards-list .payment-card-item > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .main-card-page .payment-cards-list .payment-card-item > div .last-card-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .main-card-page .payment-cards-list .payment-card-item > div .last-card-info > div:not(:last-child) {
        margin-right: 20px; }
      .main-card-page .payment-cards-list .payment-card-item > div .last-card-info .card-item-status {
        width: 165px;
        text-align: right; }
    .main-card-page .payment-cards-list .payment-card-item > div .card-item-name {
      flex-grow: 1;
      color: black; }
    .main-card-page .payment-cards-list .payment-card-item > div .card-item-number {
      color: grey; }

#mainConfirmModal .grouped.fields > label {
  display: block;
  margin-bottom: 7px; }

.responsive-flex-2-side {
  display: flex;
  align-items: center;
  width: 100%; }
  .responsive-flex-2-side > div {
    width: 50%; }

.main-page-limit-content .graphic-plimit-main {
  margin-bottom: 20px; }
  .main-page-limit-content .graphic-plimit-main > label {
    display: block;
    font-weight: bold;
    font-size: 16px; }
  .main-page-limit-content .graphic-plimit-main > div {
    padding: 10px; }
  .main-page-limit-content .graphic-plimit-main .graphic-limit-self {
    position: relative;
    display: flex; }
    .main-page-limit-content .graphic-plimit-main .graphic-limit-self .vertical-step {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px; }
    .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self {
      display: flex;
      width: 100%; }
      .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self > div {
        width: 11%;
        height: 146px;
        background: #efefef;
        margin-right: 2px;
        position: relative; }
        .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self > div:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background: #21ba45; }
      .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self > div:nth-child(1):after {
        height: 100%; }
      .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self > div:nth-child(2):after {
        height: 50%; }
      .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self > div:nth-child(3):after {
        height: 33.33333%; }
      .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self > div:nth-child(4):after {
        height: 25%; }
      .main-page-limit-content .graphic-plimit-main .graphic-limit-self .graphic-self > div:nth-child(5):after {
        height: 20%; }
  .main-page-limit-content .graphic-plimit-main.other-g .graphic-self > div:after {
    background: #f9a234; }
  .main-page-limit-content .graphic-plimit-main.other-g .graphic-self > div:nth-child(1):after {
    height: 90%; }
  .main-page-limit-content .graphic-plimit-main.other-g .graphic-self > div:nth-child(2):after {
    height: 80%; }
  .main-page-limit-content .graphic-plimit-main.other-g .graphic-self > div:nth-child(3):after {
    height: 70%; }
  .main-page-limit-content .graphic-plimit-main.other-g .graphic-self > div:nth-child(4):after {
    height: 60%; }
  .main-page-limit-content .graphic-plimit-main.other-g .graphic-self > div:nth-child(5):after {
    height: 50%; }

.main-loan-current .price-buttons > div:not(:last-child) {
  margin-bottom: 10px; }

.main-loan-current .price-buttons > div {
  text-align: center; }

.pre-create-request #complete_sms {
  width: 170px; }

.pre-create-request #sendCodeMoreAgain {
  display: block;
  margin-top: 10px;
  font-size: 15px; }

.pre-create-request .agree-again-main {
  margin-top: 30px; }

.pre-create-request .main-pre-sms-block {
  display: flex;
  flex-direction: column;
  align-items: center; }

.pre-create-request .pre-create-request-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end; }

.pre-create-request-photo > div .main-pre-photo-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px; }
  .pre-create-request-photo > div .main-pre-photo-block > img {
    max-width: 200px; }
  .pre-create-request-photo > div .main-pre-photo-block .chat-action-photo {
    width: 100%;
    margin-top: 12px; }
    .pre-create-request-photo > div .main-pre-photo-block .chat-action-photo .action-list {
      display: flex;
      align-items: center;
      justify-content: center; }
      .pre-create-request-photo > div .main-pre-photo-block .chat-action-photo .action-list > a {
        flex-grow: 1; }

.fl2-column {
  display: flex;
  flex-direction: column; }

.w100 {
  width: 100%; }

.fl2-self-center {
  align-self: center; }

.card-dropdown-main {
  margin-top: 10px; }
  .card-dropdown-main > .ui.dropdown {
    margin-top: 5px; }
  .card-dropdown-main .card-dropdown-item {
    display: flex; }
    .card-dropdown-main .card-dropdown-item > i {
      margin-right: 10px; }
    .card-dropdown-main .card-dropdown-item .card-item-number {
      font-size: 13px;
      color: grey; }

.ui.form .ldz-one.fields {
  display: flex;
  flex-direction: column !important;
  align-items: center; }
  .ui.form .ldz-one.fields > .field:not(:last-child) {
    margin-bottom: 15px; }

.ui.form .ldz-two.fields {
  display: flex;
  /*flex-direction: column !important;*/
  align-items: flex-start; }
  .ui.form .ldz-two.fields > .field:not(:last-child) {
    margin-bottom: 15px; }

.overflow-mobile-bg-menu.hide {
  display: none !important; }

.menu-button-fixed {
  display: none;
  margin-top: 18px;
  margin-left: 15px; }

.main-chat-typing {
  display: flex;
  position: absolute;
  /* bottom:0;*/
  background: rgba(183, 183, 153, 0.56);
  left: 0;
  right: 0;
  padding: 2px 8px;
  color: #242949; }

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s; }

.fade-enter, .fade-leave-to {
  opacity: 0; }

@media only screen and (max-width: 767px) {
  .p-0-mobile {
    padding: 0 !important; }
  #mainCabinet .p-0-mobile {
    padding: 0 !important; }
  #mainCabinet > .container {
    padding-left: 10px !important;
    padding-right: 10px !important; }
  .continue-after-auth .fl2box-medium {
    display: block !important; }
  .continue-after-auth .photo-continue-auth-steps {
    margin-bottom: 30px; }
  .continue-after-auth .ui.big.buttons {
    flex-direction: column; }
    .continue-after-auth .ui.big.buttons .button, .continue-after-auth .ui.big.buttons .btn-major, .continue-after-auth .ui.big.buttons .btn-minor {
      /*font-size: 1.0rem !important;*/
      margin: 5px; }
    .continue-after-auth .ui.big.buttons > .or {
      display: none !important; }
  .cabinet-main-left-menu {
    position: absolute;
    top: -82px;
    left: -15px;
    z-index: 199; }
    .cabinet-main-left-menu .card-profile-image {
      display: none !important; }
    .cabinet-main-left-menu .overflow-mobile-bg-menu {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.67); }
      .cabinet-main-left-menu .overflow-mobile-bg-menu > .vertical.menu {
        max-width: 210px; }
    .cabinet-main-left-menu .menu-button-fixed {
      display: block; }
    .cabinet-main-left-menu .top-credit-radar {
      display: none; }
  .main-chat-block-container {
    padding: 0px; }
    .main-chat-block-container .main-chat-block-messages {
      padding: 5px;
      padding-bottom: 13px; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-icon {
        width: 30px;
        min-width: 30px;
        height: 30px;
        line-height: 30px; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .ui.message {
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text .ui.message > i {
          margin-right: 0px; }
      .main-chat-block-container .main-chat-block-messages .chat-item-message .chat-item-message-text img {
        max-width: 100%; }
  #gridAuth {
    margin-top: 10px;
    margin-bottom: 10px; }
  .responsive-flex-2-side {
    display: block;
    align-items: center;
    width: 100%; }
    .responsive-flex-2-side > div {
      width: 100%; }
  .responsive-flex {
    display: block !important;
    width: 100%; }
    .responsive-flex > div {
      width: 100%; }
    .responsive-flex > a {
      display: block; }
    .responsive-flex > a:not(:last-child) {
      margin-bottom: 6px; }
  .main-loan-content {
    display: block; }
    .main-loan-content > div {
      width: 100%;
      padding: 5px;
      border-right: 0px !important; }
  .main-loan-ready-content {
    display: block; }
    .main-loan-ready-content > div {
      width: 100% !important;
      padding: 5px;
      border-right: 0px !important; }
    .main-loan-ready-content .price-buttons .combinePriceButton {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .main-loan-ready-content .price-buttons .combinePriceButton input {
        margin-bottom: 10px;
        /*border-right-color: grey !important;*/ }
    .main-loan-ready-content .price-buttons .ui.action.input:not([class*="left action"]) > input {
      border-right-color: #dddddd !important;
      text-align: center !important; }
    .main-loan-ready-content .price-buttons .ui.action.input:not([class*="left action"]) > input:focus {
      border-right-color: #85b7d9 !important; }
  .h-mobile-hide {
    padding-left: 0px !important;
    padding-right: 0px !important; }
  .photo-continue-auth-steps {
    min-width: auto; }
  .request-history-item > div.rhi-sub-data {
    margin-top: 10px; }
  .request-history-item .request-actions .request-action[request-status="2"] {
    display: none !important; }
  .request-history-item .request-actions .request-action[request-status="3"] {
    display: none !important; }
  .credit-limit-main > div {
    margin-bottom: 15px; }
  .credit-limit-main > div:nth-child(3) {
    text-align: center;
    display: block;
    margin: 0 auto; }
  .main-card-page .payment-cards-list .payment-card-item > div {
    display: block; }
    .main-card-page .payment-cards-list .payment-card-item > div .last-card-info {
      display: block;
      margin-top: 5px; }
      .main-card-page .payment-cards-list .payment-card-item > div .last-card-info > div:not(:last-child) {
        margin-right: 0px; }
      .main-card-page .payment-cards-list .payment-card-item > div .last-card-info .card-item-status {
        width: auto;
        text-align: left;
        margin: 5px 0px; } }

.page-content__inner {
  margin-bottom: 10px; }

/**---------------------------------- REGISTRATION --------------------------------------*/
#ui-pod-ft-agreement.ui.modal > .content > ul {
  list-style-type: disc;
  text-align: justify; }
  #ui-pod-ft-agreement.ui.modal > .content > ul > li {
    margin-left: 20px; }

#app-register {
  position: relative; }
  #app-register .main-steps-ui {
    position: relative; }
    #app-register .main-steps-ui > div.ui-reg-self-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0; }
      #app-register .main-steps-ui > div.ui-reg-self-video video {
        max-width: 100%;
        max-height: 100%;
        z-index: 0;
        opacity: 1; }
      #app-register .main-steps-ui > div.ui-reg-self-video canvas {
        max-width: 100%;
        max-height: 100%;
        z-index: 0;
        opacity: 1; }
    #app-register .main-steps-ui > div.ui-tabs-pane {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #f0f4f5; }
    #app-register .main-steps-ui > div:last-child {
      /* opacity: 0.6;*/
      z-index: 2; }
    #app-register .main-steps-ui .register-send-sms-form,
    #app-register .main-steps-ui .register-send-exist-document-form,
    #app-register .main-steps-ui .register-send-complete-form {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center; }
      #app-register .main-steps-ui .register-send-sms-form > div:first-child,
      #app-register .main-steps-ui .register-send-exist-document-form > div:first-child,
      #app-register .main-steps-ui .register-send-complete-form > div:first-child {
        flex-grow: 1;
        width: 100%;
        display: flex;
        display: block;
        width: 100%;
        align-items: center;
        justify-content: center; }
    #app-register .main-steps-ui .register-send-sms-form .ui.form {
      margin-top: 30px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center; }
      #app-register .main-steps-ui .register-send-sms-form .ui.form .field > label {
        text-align: left; }
      #app-register .main-steps-ui .register-send-sms-form .ui.form #complete_sms {
        width: 170px; }
    #app-register .main-steps-ui #sendCodeMoreAgain {
      display: block;
      margin-top: 10px;
      font-size: 15px; }
    #app-register .main-steps-ui .timer-left-registration {
      margin-top: 20px; }
    #app-register .main-steps-ui .address-error {
      display: inline-block;
      color: #da520c;
      padding-left: 20px;
      margin-top: 3px; }
    #app-register .main-steps-ui .address-success {
      display: inline-block;
      color: #9cba64;
      padding-left: 20px;
      margin-top: 3px; }
    #app-register .main-steps-ui .register-extend-service-main {
      margin-top: 20px;
      padding-left: 25px; }
      #app-register .main-steps-ui .register-extend-service-main > a {
        color: #333333;
        font-size: 15px; }
      #app-register .main-steps-ui .register-extend-service-main div.reg-content-label {
        font-size: 1rem;
        line-height: 24px; }

.cabinet-main-left-menu .ui.card > .content {
  border: 0 !important; }

@-moz-document url-prefix() {
  #app-register {
    font-family: Roboto !important; }
    #app-register .ui.steps .step .title {
      font-family: Roboto !important; }
    #app-register .ui.button, #app-register .ui.btn-major, #app-register .ui.btn-minor {
      font-family: Roboto !important; } }

.hide {
  display: none !important; }

.main-show-activate-video {
  position: fixed;
  z-index: 9999999;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh; }
  .main-show-activate-video > div:first-child {
    background: rgba(0, 0, 0, 0.74);
    height: 60vh;
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 45px;
    padding-right: 100px;
    font-size: 70px; }
  .main-show-activate-video > div:last-child {
    background: white;
    flex-direction: column;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px; }
    .main-show-activate-video > div:last-child .buttons-after {
      margin-top: 20px; }
    .main-show-activate-video > div:last-child .text-with-icons {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .main-show-activate-video > div:last-child .text-with-icons img {
        margin: 0px 5px; }

@media only screen and (max-width: 767px) {
  #app-register {
    padding-left: 2px;
    padding-right: 2px; }
  .main-show-activate-video > div:first-child {
    height: 0vh;
    display: none;
    font-size: 45px; }
  .main-show-activate-video > div:last-child {
    height: 100vh;
    text-align: center; }
    .main-show-activate-video > div:last-child .buttons-after > a {
      display: block; }
    .main-show-activate-video > div:last-child .buttons-after > a:not(:last-child) {
      margin-bottom: 10px; }
    .main-show-activate-video > div:last-child .text-with-icons {
      display: block; } }

.welcome {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  text-align: center; }
  .welcome__title {
    font-size: 30px;
    font-family: "Roboto", sans-serif; }
  .welcome__subtitle {
    font-size: 21px;
    margin-top: 20px; }
  .welcome__man, .welcome__money {
    display: none; }
  .welcome__label {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 22px; }
  .welcome__dots {
    display: block;
    margin: 20px auto 10px; }
  .welcome__button-enter, .welcome__button-registr {
    width: 245px;
    background-repeat: no-repeat;
    background-position: 85% 50%; }
  .welcome__button-enter {
    background-image: url("../images/png/key.png");
    background-position: 90% 50%; }
  .welcome__button-registr {
    background-image: url("../images/png/user.png"); }

@media only screen and (min-width: 768px) {
  .welcome {
    padding-top: 48px;
    padding-bottom: 48px; }
    .welcome__title {
      font-size: 48px; }
    .welcome__subtitle {
      font-size: 30px; }
    .welcome__label {
      font-size: 17px;
      margin-top: 28px;
      margin-bottom: 28px; }
    .welcome__dots {
      margin-top: 27px; } }

@media only screen and (min-width: 992px) {
  .welcome {
    padding-top: 68px;
    padding-bottom: 68px; }
    .welcome__man {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 178px;
      height: 437px;
      display: block; } }

@media only screen and (min-width: 1200px) {
  .welcome__man {
    left: 88px; }
  .welcome__money {
    position: absolute;
    right: 25px;
    bottom: 0;
    z-index: 2;
    width: 351px;
    height: 237px;
    display: block; } }

.page-loan {
  font-family: "Roboto"; }
  .page-loan .h3 {
    font-family: "Roboto"; }
  .page-loan .calculator {
    font-family: "Roboto";
    overflow: visible;
    box-shadow: none;
    margin-left: -15px;
    margin-right: -15px; }
    .page-loan .calculator__faq-dropdown {
      right: -14px; }
    .page-loan .calculator__container {
      padding-right: 15px;
      padding-left: 15px; }
    .page-loan .calculator__panel {
      display: none; }
    .page-loan .calculator__passport {
      display: none; }
    .page-loan .calculator__title {
      font-weight: 500;
      font-size: 21px;
      line-height: 28px;
      color: inherit;
      margin-bottom: 15px; }
    .page-loan .calculator__field--md {
      display: none; }
    .page-loan .calculator__value-count {
      font-size: 21px; }
    .page-loan .calculator__value-text {
      text-transform: none;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px; }
    .page-loan .calculator__pay {
      font-size: 13px;
      padding-top: 20px;
      border-top: 1px dashed #a5a5a5;
      margin-top: 10px; }
      .page-loan .calculator__pay-title {
        margin-bottom: 10px; }
      .page-loan .calculator__pay-subtitle {
        font-style: italic; }
      .page-loan .calculator__pay-time {
        font-weight: 500;
        font-style: italic; }
    .page-loan .calculator__list {
      margin-bottom: 20px; }
    .page-loan .calculator__item {
      display: inline-block; }
      .page-loan .calculator__item img {
        width: 65px; }
    .page-loan .calculator__btnwrap {
      text-align: center; }
    .page-loan .calculator__conditions {
      position: relative;
      display: inline-block;
      margin-top: 20px; }
      .page-loan .calculator__conditions-tooltip {
        background: #fff;
        padding: 7px 15px;
        position: absolute;
        bottom: 110%;
        left: 0;
        font-size: 11px;
        font-style: italic;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        display: none; }
      .page-loan .calculator__conditions-item {
        display: inline-block;
        width: 55px;
        height: 55px;
        border: 1px solid rgba(238, 220, 149, 0.3);
        background: url("../images/png/calc_icons.png"); }
        .page-loan .calculator__conditions-item-tooltip {
          background: #fff;
          padding: 10px 15px;
          position: absolute;
          bottom: 110%;
          left: 0;
          font-size: 11px;
          font-style: italic;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          display: none;
          transition: 0.3s linear; }
        .page-loan .calculator__conditions-item:hover {
          border-color: #eedc95; }
        .page-loan .calculator__conditions-item:hover .calculator__conditions-tooltip {
          display: block; }
        .page-loan .calculator__conditions-item_pass {
          background-position: -2px 0; }
          .page-loan .calculator__conditions-item_pass:hover {
            background-position: -58px 0; }
        .page-loan .calculator__conditions-item_cash {
          background-position: -2px 167px; }
          .page-loan .calculator__conditions-item_cash:hover {
            background-position: -58px 167px; }
        .page-loan .calculator__conditions-item_people {
          background-position: -2px 111px; }
          .page-loan .calculator__conditions-item_people:hover {
            background-position: -58px 111px; }
        .page-loan .calculator__conditions-item_sec {
          background-position: -2px 55px; }
          .page-loan .calculator__conditions-item_sec:hover {
            background-position: -58px 55px; }

@media only screen and (min-width: 768px) {
  .page-loan .h3 {
    font-size: 30px; }
  .page-loan .calculator-wrap {
    padding-top: 10px;
    padding-bottom: 40px; }
  .page-loan .calculator {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
    .page-loan .calculator__inner {
      box-shadow: 0px -5px 30px rgba(0, 0, 0, 0.2);
      z-index: 2;
      position: relative; }
    .page-loan .calculator__container {
      width: 96%;
      margin-left: auto;
      margin-right: auto; }
    .page-loan .calculator__field--md {
      display: block; }
      .page-loan .calculator__field--md .calculator__container {
        padding-left: 5px;
        padding-right: 5px; }
    .page-loan .calculator__field--xs {
      display: none; }
    .page-loan .calculator__field {
      padding-top: 20px;
      padding-bottom: 10px; }
    .page-loan .calculator__field-title {
      float: left; }
    .page-loan .calculator__row {
      float: right;
      width: 80%; }
    .page-loan .calculator__col {
      height: 50px;
      float: left;
      padding: 10px; }
      .page-loan .calculator__col:not(:first-child) {
        border-left: 1px dashed #a5a5a5; }
    .page-loan .calculator__field-title {
      margin-right: 10px; }
    .page-loan .calculator__pay-title {
      margin-bottom: 0;
      display: inline-block;
      vertical-align: middle;
      margin-right: 25px; }
    .page-loan .calculator__pay-subtitle, .page-loan .calculator__pay-time {
      display: inline-block;
      margin-top: 15px; }
    .page-loan .calculator__list {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0; }
    .page-loan .calculator__item {
      display: inline-block;
      position: relative; }
      .page-loan .calculator__item img {
        width: auto; }
      .page-loan .calculator__item:not(:last-child) {
        padding-right: 50px; }
        .page-loan .calculator__item:not(:last-child)::after {
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #2184be;
          display: inline-block;
          position: absolute;
          bottom: 50%;
          right: 25px;
          transition: translate(-50%, -50%); }
    .page-loan .calculator__btnwrap {
      float: left; }
    .page-loan .calculator__conditions {
      float: right;
      margin-top: 0; }
    .page-loan .calculator__footer {
      padding-top: 45px;
      padding-bottom: 14px; } }

@media only screen and (min-width: 992px) {
  .page-loan .h3 {
    font-size: 30px; }
  .page-loan .calculator {
    width: 820px;
    position: relative;
    top: auto;
    left: auto; }
    .page-loan .calculator__container {
      width: 85%; }
    .page-loan .calculator__title {
      font-size: 27px; }
    .page-loan .calculator__header .calculator__container {
      width: 91%; }
    .page-loan .calculator__panel {
      display: block;
      width: 70px;
      position: absolute;
      top: 99px;
      right: -70px; }
      .page-loan .calculator__panel small {
        font-size: 13px; }
      .page-loan .calculator__panel-top {
        color: #fff;
        text-align: center;
        padding: 20px 0 10px;
        font-size: 34px;
        font-weight: 500;
        background: #2184be; }
      .page-loan .calculator__panel-bottom {
        height: 160px;
        background: #f5eed4;
        position: relative; }
        .page-loan .calculator__panel-bottom::after {
          content: '';
          display: block;
          background: url("../images/png/lightning.png") no-repeat;
          width: 15px;
          height: 25px;
          position: absolute;
          bottom: 10px;
          left: 30px; }
      .page-loan .calculator__panel-text {
        transform: translateX(-50%) rotate(-90deg);
        position: absolute;
        top: 45px;
        left: 50%; } }

@media only screen and (min-width: 1200px) {
  .page-loan .calculator {
    left: auto;
    margin-left: 300px;
    width: 760px; }
    .page-loan .calculator__passport {
      display: block;
      position: absolute;
      top: 40px;
      right: 102%; }
    .page-loan .calculator__container {
      width: 92%; }
    .page-loan .calculator__header .calculator__container {
      width: 97%; } }

.loan-features {
  padding-top: 30px; }
  .loan-features__inner {
    font-size: 0; }
  .loan-features__item {
    text-align: center; }
  .loan-features__pic {
    width: 160px;
    height: 160px;
    background: #fff;
    border: 10px solid #f4f5f4;
    border-radius: 50%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    display: inline-block;
    margin-bottom: 50px; }
    .loan-features__pic::after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #2184be;
      display: inline-block;
      position: absolute;
      bottom: -40px;
      left: 50%; }
    .loan-features__pic_1 {
      background: #fff url("../images/content-loan/feature1.jpg") 50% 50% no-repeat; }
    .loan-features__pic_2 {
      background: #fff url("../images/content-loan/feature2.jpg") 50% 50% no-repeat; }
    .loan-features__pic_3 {
      background: #fff url("../images/content-loan/feature3.jpg") 50% 50% no-repeat; }
    .loan-features__pic_4 {
      background: #fff url("../images/content-loan/feature4.jpg") 50% 50% no-repeat; }
  .loan-features__text {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 50px; }

@media only screen and (min-width: 768px) {
  .loan-features {
    padding-top: 50px; }
    .loan-features__item {
      display: inline-block;
      width: 50%; }
    .loan-features__text {
      margin-left: auto;
      margin-right: auto;
      width: 80%; } }

@media only screen and (min-width: 992px) {
  .loan-features__item {
    width: 25%;
    vertical-align: top; }
  .loan-features__text {
    margin-bottom: 0; } }

.content-request-loan {
  padding-bottom: 40px; }
  .content-request-loan .content-request-info__caption {
    font-size: 15px; }

@media only screen and (min-width: 768px) {
  .content-request-loan {
    text-align: right;
    padding-bottom: 80px; }
    .content-request-loan .content-request-info__item:nth-child(odd) {
      text-align: left; } }

@media only screen and (min-width: 992px) {
  .content-request-loan .content-request-info__text {
    margin-top: 0; }
  .content-request-loan .content-request-info__item:not(:last-child)::after {
    content: '';
    width: 96px;
    height: 4px;
    display: block;
    background: url("../images/png/divider.png");
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  .content-request-loan .content-request-info__item:nth-child(odd) {
    text-align: left; }
    .content-request-loan .content-request-info__item:nth-child(odd) .content-request-info__text {
      float: right; }
    .content-request-loan .content-request-info__item:nth-child(odd) .content-request-info__preview {
      float: left; }
  .content-request-loan .content-request-info__text {
    display: block;
    float: left;
    width: 47%;
    text-align: left; } }

@media only screen and (min-width: 1200px) {
  .content-request-loan .content-request-info__text {
    width: 53%; }
  .content-request-loan .content-request-info__arrow {
    left: 59%; }
  .content-request-loan .content-request-info__arrow_left {
    left: 43%; } }

.payment-loan {
  background: #f0f3f0;
  padding-top: 65px;
  padding-bottom: 20px;
  border-top: 1px solid #dcdedc;
  border-bottom: 1px solid #dcdedc;
  position: relative; }
  .payment-loan__logo {
    display: none; }
  .payment-loan__title {
    font-size: 21px;
    text-align: center;
    margin-bottom: 20px; }
  .payment-loan__subtitle {
    font-size: 30px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 20px; }
  .payment-loan__col {
    margin-bottom: 25px;
    font-size: 0; }
  .payment-loan__caption {
    font-size: 15px; }
  .payment-loan__paylogo {
    border: 1px solid #dcdedc; }
  .payment-loan__pic {
    background: #fff;
    text-align: center;
    height: 135px;
    padding-top: 20px; }
    .payment-loan__pic:not(:first-child) {
      border-top: 1px solid #dcdedc; }
    .payment-loan__pic img {
      max-width: 220px; }

@media only screen and (min-width: 768px) {
  .payment-loan {
    padding-top: 65px;
    padding-bottom: 20px; }
    .payment-loan__logo {
      display: block;
      position: absolute;
      top: -93px;
      left: 50%;
      margin-left: -93px; }
    .payment-loan__title {
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px; }
    .payment-loan__subtitle {
      font-size: 48px;
      font-weight: 200;
      line-height: 30px;
      margin-bottom: 50px; }
    .payment-loan__caption {
      font-size: 17px;
      line-height: 22px;
      padding-bottom: 20px; }
    .payment-loan__pic {
      display: inline-block;
      width: 50%; }
      .payment-loan__pic:nth-child(2) {
        border-top: none; }
      .payment-loan__pic:nth-child(odd) {
        border-right: 1px solid #dcdedc; } }

@media only screen and (min-width: 992px) {
  .payment-loan__col {
    width: calc(100% / 2 - 35px);
    float: left; }
    .payment-loan__col:last-child {
      margin-left: 35px; } }

.credit-story {
  padding-top: 45px; }
  .credit-story__inner {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .credit-story__col {
    margin-bottom: 30px; }
  .credit-story__title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px; }
  .credit-story__caption {
    font-size: 15px;
    line-height: 21px; }
  .credit-story__img {
    width: 100%;
    height: auto;
    max-width: 493px; }

@media only screen and (min-width: 768px) {
  .credit-story {
    padding-top: 65px; }
    .credit-story__title {
      width: 70%;
      margin-bottom: 30px; }
      .credit-story__title_big {
        font-weight: 200;
        font-size: 42px; }
    .credit-story__img {
      width: auto; }
    .credit-story__caption {
      font-size: 17px;
      line-height: 22px;
      padding-bottom: 20px; } }

@media only screen and (min-width: 992px) {
  .credit-story__inner {
    flex-direction: row; } }
