@if($push-footer){
  .main {
    position: relative;
    min-height: 100%;
  }
  @include min-screen($break-large-devices){
    .main {
      //padding-bottom: 350px;
    }
  }
  @include min-screen($break-extra-large-devices){
    .main {
      //padding-bottom: 331px;
    }
  }
}

.page-content {
  //padding-top: $header-height;
  //padding-bottom: 800px;
  background-color: #F0F4F5;
}

@include min-screen($break-small-devices){
  .page-content {
    //padding-top: $header-height-tablet;
    //padding-bottom: 670px;
  }
}
@include min-screen($break-medium-devices){
  .page-content {
    //padding-top: $header-height-tablet;
    //padding-bottom: 530px;
  }
}
@include min-screen($break-large-devices){
  .page-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}