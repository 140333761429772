.signin {
  max-width: 370px;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  position: relative;

  &__title {
    font-size: 30px;
    margin-bottom: 30px;
    font-family: $Roboto;
  }
  &__man,
  &__money {
    display: none;
  }
  &__button-enter {
    width: 245px;
    background-image: url('#{$image}/png/user.png');
    background-position: 70% 50%;
    background-repeat: no-repeat;
  }

  .form__field-inner {
    width: 100%;
    margin-bottom: 10px;
  }
}
@include min-screen($break-medium-devices){
  .signin {
    padding-top: 48px;
    padding-bottom: 48px;

    &__title {
      font-size: 30px;
    }
  }
}
@include min-screen($break-large-devices){
  .signin {
    padding-top: 88px;
    padding-bottom: 68px;

    &__man {
      position: absolute;
      right: 120%;
      bottom: 0;
      z-index: 2;

      width: 178px;
      height: 437px;

      display: block;
    }


    &--recovery {
      padding-bottom: 133px;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .signin {
    &__man {
      right: 140%;
    }
    &__money {
      position: absolute;
      left: 110%;
      bottom: 0;
      z-index: 2;

      width: 351px;
      height: 237px;

      display: block;
    }
  }
}