.map-filter {
  &__group {}
  &__header {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;

    background-color: #eee;
  }
  &__body {
    font-size: 13px;
  }
  &__title {
    position: relative;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 32px;

    font-weight: $medium;

    background-image: url('#{$image}/png/header-bottom-address-city.png');
    background-repeat: no-repeat;
    background-position: 2px 50%;
  }
  &__list {}
  &__item {
    padding: 18px 15px;
    border-top: 1px solid #d0d0d0;

    b {
      font-size: 14px;
    }

    &:first-child {
      border-top: 0;
    }
  }
  &__adress {
    padding-left: 20px;

    background-image: url('#{$image}/png/house.png');
    background-repeat: no-repeat;
    background-position: 0 1px;
  }
  &__worktime {
    margin-top: 8px;
    padding-left: 20px;

    background-image: url('#{$image}/png/clock.png');
    background-repeat: no-repeat;
    background-position: 0 0;
  }
}
@include min-screen($break-medium-devices){
  .map-filter {
    width: 375px;
    max-height: 440px;

    overflow-y: auto;
    background-color: #fff;
    border-radius: 6px;


    &__header {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;

      background-color: #eee;
    }
    &__body {
      font-size: 13px;
    }
    &__title {
      position: relative;

      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 32px;

      font-weight: $medium;

      background-image: url('#{$image}/png/header-bottom-address-city.png');
      background-repeat: no-repeat;
      background-position: 2px 50%;
    }
    &__list {}
    &__item {
      padding: 18px 15px;
      border-top: 1px solid #d0d0d0;

      b {
        font-size: 14px;
      }

      &:first-child {
        border-top: 0;
      }
    }
    &__adress {
      padding-left: 20px;

      background-image: url('#{$image}/png/house.png');
      background-repeat: no-repeat;
      background-position: 0 1px;
    }
    &__worktime {
      margin-top: 8px;
      padding-left: 20px;

      background-image: url('#{$image}/png/clock.png');
      background-repeat: no-repeat;
      background-position: 0 0;
    }
  }
}