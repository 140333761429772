.news-deatail {
  font-size: 15px;

  &__pubdate {
    font-size: 11px;
    font-weight: 500;
    font-style: italic;
    line-height: 1;
    margin-bottom: 19px;
  }
  &__caption {
    margin-top: 19px;
    padding: 0;
  }
  &__socials {
    margin-top: 30px;
  }
}