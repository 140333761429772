.content-payment {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;

  &__icon {
    width: 50px;
  }
  &__logo {
    position: relative;
    top: 50%;

    @extend .img-responsive;

    transform: translateY(-50%);
  }
  &__logotype {
    width: 228px;
    height: 131px;

    @extend .align-center;

    background-image: url('#{$image}/paysystems/content-payment-paySystems-item-bg.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 30px;
  }
  &__body {
    margin-top: 20px;
  }
  &__point {
    width: 5px;
    height: 5px;
    margin: 23px auto 12px;

    background-color: $major-color;
    border-radius: 50%;
  }
  &__caption {
    font-size: 14px;
    line-height: 1.3;
    font-weight: $bold;
    font-family: $DINPro;
  }
  &__footer {
    margin-top: 30px;
  }
  &__button {
    padding: 10px 10px 10px 48px;

    .button__label {
      margin-right: 10px;
    }
  }
}
@include min-screen($break-medium-devices){
  .content-payment {
    margin-top: 57px;
    margin-bottom: 57px;

    &__icon {
      width: 66px;
    }
    &__body {
      margin-top: 20px;
    }
    &__carousel {
      font-size: 0;
    }
    &__item {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      margin-bottom: 10px;
    }
    &__point {
      margin-top: 26px;
    }
  }
}
@include min-screen($break-large-devices){
  .content-payment {
    margin-top: 70px;
    margin-bottom: 70px;

    &__icon {
      width: auto;
    }
    &__item {
      width: 25%;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .content-payment {
    &__logotype {
      width: 280px;
      height: 141px;
    }
  }
}