@import "./normalize";

@if($push-footer){
  html,
  body {
    width: 100%;
    height: 100%;
  }
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  smoothing: antialiased;
}


html {
  @include font(14px, 1.15);
  min-height: 100%;
}

body {
  min-width: 320px;
  background-color: #fff;
  @include font($font-size, $line-height, $font-color, $font-weight, $font-style, $font-family);

  @if($dev){
    min-height: 1500px;
  }
}
@include max-screen($break-medium-devices - 1) {
  body.js-is-xs-scroll-hidden {
    overflow-y: hidden;
  }
}
@include max-screen($break-large-devices - 1) {
  body.js-is-sm-scroll-hidden {
    overflow-y: hidden;
  }
}

a,
.link {
  color: $link-color;
  text-decoration: none;

  transition: color $transition;


  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: $link-hover-color;
  }

  &-major {
    color: $major-color;
  }
  &-dark {
    color: $font-color;
  }
  &-underline {
    text-decoration: underline;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, .p, p {
  padding: 0;
  margin: 0;
}

p, .p {
  padding-bottom: 10px;
}

.h1 {
  @include font(30px, 1.1, $font-color, $regular, null, $DINPro);

  &.main-title {
    padding-bottom: 47px;
  }
}

.h2 {
  @include font(30px, 1.1, $font-color, $regular, null, $DINPro);
  text-align: center;
  padding-bottom: 6px;

  &.text-left {
    text-align: left;
  }

  &.main-title {
    padding-bottom: 47px;
  }
}
@include min-screen($break-medium-devices){
  .h2 {
    font-size: 42px;
    padding-bottom: 10px;
  }
}
@include min-screen($break-large-devices){
  .h2 {
    font-size: 48px;
  }
}

.h3 {
  @include font(30px, 1.1, $font-color, $regular, null, $DINPro);
  padding-bottom: 10px;

  &.text-center {
    text-align: center;
  }
}
@include min-screen($break-medium-devices){
  .h3 {
    font-size: 36px;
  }
}

.hr {
  margin: 40px 0;
  padding: 0;
  border: 0;

  height: 1px;
  background-color: #d4d2d2;
}
@include min-screen($break-medium-devices){
  .hr {
    &-blue {
      margin-top: -20px;
    }

    &-line {
      .article + & {
        margin-top: 0;
      }

      & + .widget-feedback {
        margin-top: 20px;
      }
    }
  }
}
@include min-screen($break-large-devices){
  .hr {
    &-blue {
      margin-top: -30px;
    }

    &-line {
      & + .widget-feedback {
        margin-top: 40px;
      }
    }
  }
}


.text-area {
  .h1 {
    font-family: $Roboto;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 23px;
  }
}
