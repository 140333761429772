.content-banner {
  &__logo,
  &__parallax {
    display: none;
  }
}
@include min-screen($break-large-devices){
  .content-banner {
    position: relative;

    &__logo,
    &__parallax {
      display: block;
    }

    &__parallax {
      height: 580px;
      position: relative;
      overflow: hidden;
    }
    &__area,
    &__city,
    &__case,
    &__family,
    &__usurer {
      position: absolute;
      right: 50%;
      background-repeat: no-repeat;
    }
    &__city,
    &__area {
      width: 2000px;
      margin-right: -1000px;
      background-position: center top;
    }
    &__area {
      top: 427px;
      height: 147px;
      background-image: url('#{$image}/content-banner/content-banner-area.jpg');
    }
    &__city {
      top: -3px;
      height: 435px;
      background-image: url('#{$image}/content-banner/content-banner-city.jpg');
    }
    &__family {
      top: 139px;

      width: 541px;
      height: 338px;
      margin-right: -278px;

      background-image: url('#{$image}/content-banner/content-banner-family.png');
    }
    &__usurer {
      top: 55px;

      width: 245px;
      height: 434px;
      margin-right: 257px;

      background-image: url('#{$image}/content-banner/content-banner-usurer.png');
    }
    &__case {
      top: 398px;

      width: 170px;
      height: 120px;
      margin-right: 203px;

      background-image: url('#{$image}/content-banner/content-banner-case.png');
    }

    &__logo {
      position: absolute;
      left: 50%;
      bottom: -75px;

      margin-left: -93px;
    }
  }
}
@include min-screen($break-extra-large-devices){
  .content-banner {
    &__family {
      margin-right: -178px;
    }
    &__usurer {
      margin-right: 357px;
    }
    &__case {
      margin-right: 303px;
    }
  }
}


.content-whatis {
  text-align: center;
  margin-top: -13px;
  font-family: $DINPro;

  &__icon {
    width: 55px;
    margin-bottom: -5px;
  }
  &__title {
    line-height: 1.2;
    margin-bottom: 16px;
  }
  &__subtitle {
    font-size: 15px;
    font-weight: $bold;
    line-height: 1.5;
    font-style: italic;
    text-align: left;
  }
  &__image {
    margin-top: 34px;
    margin-bottom: 12px;

    @extend .img-responsive;

    padding-bottom: 4px;
    border-bottom: 1px solid #d1d4d8;

    &--clear {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  &__caption {
    text-align: left;
    font-size: 15px;
    line-height: 1.5;
  }

  &__money-request {
    margin-top: 130px;
    padding-bottom: 50px;
    background: linear-gradient(to bottom, #f0f0f1 0%, #fefefe 100%);
  }
  .money-request {
    margin-top: -100px;
  }
}
@include min-screen($break-medium-devices) {
  .content-whatis {
    margin-top: -19px;

    &__icon {
      width: 65px;
      margin-bottom: 8px;
    }
    &__title {
      line-height: 1.2;
      margin-bottom: 16px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 1.4;
      text-align: center;
    }
    &__holder {
      overflow: hidden;
      margin-top: 33px;
    }
    &__image {
      width: 46%;
      float: left;
      margin-top: 10px;
      margin-left: 15px;
      margin-bottom: 0;
      padding-bottom: 4px;

      &--clear {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    &__caption {
      width: 50%;
      float: left;
      padding-left: 30px;
    }
    &__caption-text {
      padding-bottom: 20px;
    }
  }
}
@include min-screen($break-large-devices) {
  .content-whatis {
    margin-top: -19px;

    &__title {
      line-height: 1.2;
      margin-bottom: 16px;
    }
    &__subtitle {
      font-size: 18px;
    }
    &__image {
      width: 40%;
      float: left;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0;

      &--clear {
        width: 50%;
      }
    }
    &__caption {
      width: 60%;
      float: left;
      padding-left: 30px;

      &--clear {
        width: 50%;
      }
    }
    &__caption-text {
      font-size: 17px;
      padding-bottom: 20px;
    }
  }
}
@include min-screen($break-extra-large-devices) {
  .content-whatis {
    margin-top: 0;

    &__holder {
      margin-left: 100px;
      margin-right: 100px;
    }
    &__image {
      width: 40%;

      &--clear {
        width: 50%;
      }
    }
    &__caption {
      width: 60%;

      &--clear {
        width: 50%;
      }
    }
    &__caption-text {
      font-size: 17px;
      padding-bottom: 20px;
    }
  }
}