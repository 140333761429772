.content-teasers {
  margin-top: 42px;
  margin-bottom: 13px;

  &__image {
    .owl-carousel .owl-item & {
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__border {
    .owl-carousel .owl-item & {
      width: auto;
      display: none;
    }
  }
  &__point {
    width: 5px;
    height: 5px;
    margin: 25px auto 12px;

    background-color: $major-color;
    border-radius: 50%;
  }
  &__caption {
    text-align: center;
    font-size: 17px;
    line-height: 1.3;
    font-weight: $medium;
    font-family: $DINPro;

    padding: 0;
  }
}
@include min-screen($break-medium-devices) {
  .content-teasers {
    &__carousel {
      font-size: 0;
    }
    &__item {
      display: inline-block;
      width: 50%;

      position: relative;

      padding-top: 212px;
      margin-bottom: 35px;
      vertical-align: top;
      text-align: center;
    }
    &__image {
      position: absolute;
      top: 0;
      left: 50%;

      transform: translateX(-50%);
    }
    &__border {
      display: none;
    }
  }
}
@include min-screen($break-extra-large-devices) {
  .content-teasers {
    margin-top: 80px;

    &__carousel {
      margin-left: -2%;
      margin-right: -2%;
    }
    &__item {
      width: 21%;

      padding-top: 202px;
      margin-bottom: 35px;
      margin-left: 2%;
      margin-right: 2%;

      &:first-child {
        .content-teasers__image {
          top: 20px;
        }
        .content-teasers__border {
          margin-left: -10px;
        }
      }
      &:last-child {
        .content-teasers__image {
          top: 23px;
        }
      }
    }
    &__image {
      &:hover {
        animation: zoom-in-out 0.36s ease-in-out;
      }
    }
    &__border {
      position: absolute;
      top: 90px;
      left: 100%;
      margin-left: -25px;
      display: block;
    }
  }
}